import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";

import Button from "components/Button";
import Grid from "components/Grid";
import Modal from "components/Modal";
import useIsDesktop from "utils/hooks/useIsDesktop";
import { ACCEPT_TERM_AND_SERVICE } from "graphql/auth/mutation";

import styled from "styled-components";
import AppLogo from "components/AppLogo";
import useLogout from "utils/hooks/useLogout";
import { notifyError } from "utils/notify";
import ThTermsOfService from "./ThTermsOfService";
import ThPrivacyPolicy from "./ThPrivacyPolicy";

const DEEPLE_DARK = "#231f20";

export const ModalHeader = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  min-height: 57px;
  background: ${DEEPLE_DARK};
  color: ${({ theme }) => `${theme.COLORS.LightWhite}`};
  text-transform: uppercase;
  z-index: 1;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  padding-left: 18px;
  padding-right: 18px;
  box-sizing: border-box;

  & > div {
    align-self: center;
  }
`;

export const Wrapper = styled.div`
  display: block;
  position: relative;
  padding: 20px;
  margin-top: 57px;
  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    display: initial !important;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #bcbec1;
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

type PrivacyPolicyPropsType = {
  isOpen: boolean;
  onSubmit?: () => void;
};

const PrivacyPolicy: FC<PrivacyPolicyPropsType> = ({ isOpen, onSubmit }) => {
  const { t } = useTranslation();
  const isDesktop = useIsDesktop();
  const { logout } = useLogout();

  const [acceptTermAndService, { loading }] = useMutation(ACCEPT_TERM_AND_SERVICE);

  const onClickAccept = async () => {
    try {
      await acceptTermAndService();
      onSubmit?.();
    } catch (error) {
      notifyError(t("Please try again later"));
    }
  };

  const termOfService = <ThTermsOfService />;
  const privacyPolicy = <ThPrivacyPolicy />;

  return (
    <Modal
      isOpen={isOpen}
      onClose={(_event, type) => {
        if (type === "backdropClick") {
          return;
        }

        logout();
      }}
      closeIconColor="white"
      fullScreen={!isDesktop}
      maxWidth="lg"
    >
      <ModalHeader>
        <AppLogo height="25" className="d-flex" />
      </ModalHeader>

      <Wrapper className="px-2 overflow-auto">
        <Grid className="px-3" container alignContent="center" alignItems="center">
          {termOfService}

          {privacyPolicy}
        </Grid>
      </Wrapper>

      <div className="text-right p-4">
        <Button disabled={loading} onClick={onClickAccept}>
          {t("I_ACCEPT")}
        </Button>
      </div>
    </Modal>
  );
};

export default PrivacyPolicy;

import React, { FC } from "react";
import httpStatus from "http-status";
import useDevice from "utils/hooks/useDevice";
import { Device } from "types/Device";

import Button from "components/Button";

import { ErrorTextTitle, ErrorTextDescription, Wrapper, ErrorWrapper } from "./styled";

interface ErrorPagePropsType {
  errorCode?: number;
  buttonText?: string;
  handleClickButton?: () => void;
}

const ErrorPage: FC<ErrorPagePropsType> = (props) => {
  const device = useDevice();
  const isMobile = device === Device.MOBILE;
  const { errorCode, buttonText, handleClickButton } = props;
  let errorImage = "";
  let errorTitle = "";
  let errorDescription = "";
  const urlWebview = "https://storage.googleapis.com/leanbiz-core/static/webview/";

  switch (errorCode) {
    case httpStatus.NOT_FOUND:
      errorImage = `${urlWebview}404.svg`;
      errorTitle = "Page not found";
      errorDescription =
        "We can't seem to find the page you're looking for. Try going back to previous page or contact us for more information.";
      break;
    case httpStatus.INTERNAL_SERVER_ERROR:
      errorImage = `${urlWebview}500.svg`;
      errorTitle = "Internal server error";
      errorDescription = "Things are little unstable here. Please be patient or try again later.";
      break;
    case httpStatus.SERVICE_UNAVAILABLE:
      errorImage = `${urlWebview}503.svg`;
      errorTitle = "Service unavailable";
      errorDescription =
        "This site is getting a tune-up. Why don’t you come back in a little while, we’ll up and running soon! If you need help for urgent issues please email us at contact@leanbiz.ai";
      break;
    case httpStatus.REQUEST_TIMEOUT:
      errorImage = `${urlWebview}408.svg`;
      errorTitle = "Request timeout";
      errorDescription = "This request takes too long to process it is timed out by the server";
      break;
    case httpStatus.BAD_GATEWAY:
      errorImage = `${urlWebview}502.svg`;
      errorTitle = "Bad gateway";
      errorDescription =
        "Service temporarily overloaded. We are apologize for the inconvenience. Please be patient or try again later.";
      break;
    default:
      errorImage = `${urlWebview}500.svg`;
      errorTitle = "Internal server error";
      errorDescription = "Things are little unstable here. Please be patient or try again later.";
      break;
  }

  return (
    <Wrapper>
      <ErrorWrapper className="text-center">
        <img alt="error_image" src={errorImage} width="100%" className="pb-4" />
        <ErrorTextTitle variant={isMobile ? "title2" : "h3"} className="px-4 pb-4">
          {errorTitle}
        </ErrorTextTitle>
        <ErrorTextDescription variant={isMobile ? "body4" : "body2"} className="px-4">
          {errorDescription}
        </ErrorTextDescription>
        {buttonText && (
          <Button className="mt-4" onClick={handleClickButton}>
            {buttonText}
          </Button>
        )}
      </ErrorWrapper>
    </Wrapper>
  );
};

export default ErrorPage;

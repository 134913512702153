import times from "lodash/times";

const MOCK_DEFAULT_CUSTOMER_REPORT_DATA = {
  profile: "ชื่อ สกุล",
  platform: "LINE",
  chatLink: "https://deeple.ai/",
  email: "email@leanbiz.ai",
  totalSpend: 0,
  tags: { result: [], numberOfHiddenTags: 0 },
  address: "ที่อยู่ แขวงคลองตัน เขตคลองเตย กรุงเทพมหานคร 10110",
  firstName: "ชื่อ",
  lastName: "นามสกุล",
  firstSeen: "-",
  lastMessagedAt: "-",
  phoneNumber: "0987654321",
};

export const MOCK_CUSTOMER_REPORT_DATA = times(7, (index: number) => {
  return {
    ...MOCK_DEFAULT_CUSTOMER_REPORT_DATA,
    id: index,
  };
});

const project = {
  en: {
    Hi: "Hi",
    "Account settings": "User settings",
    "projectName is a required field": "projectName is a required field",
    "Project Name": "Project Name",
    "Create project": "Create store",
    Create: "Create",
    "projectList.noMoreProject": "No active store yet",
    "BroadcastBarWarning.title": "Your account has been suspended",
    "BroadcastBarWarning.description1": "Your account has been suspended according to overdue billing,",
    "BroadcastBarWarning.description2":
      "Please contact contact@leanbiz.ai or top-up your credit in wallet to reactivate your account",
    "BroadcastBarWarning.button": "Go to Wallet",
    "BroadcastBarWarning.PleaseContact": "Please contact",
    "BroadcastBarWarning.toCreateTheStore": "to create the store",

    "BroadcastBarWarning.blocked.owner.title": "Your account has been suspended",
    "BroadcastBarWarning.blocked.owner.description":
      "Your account has been suspended according to overdue billing. Please check and update your payment method to reactivate your account.",
    "BroadcastBarWarning.blocked.admin.title": "Your account has been suspended! Please contact the owner.",
    "BroadcastBarWarning.blocked.admin.description":
      "Your account has been suspended according to overdue billing. Please contact the owner and update the payment method to reactivate your account.",
    "BroadcastBarWarning.button.manageBilling": "Manage Billing",

    "BroadcastBarWarning.creditCardError.title": "There is a problem with your payment.",
    "BroadcastBarWarning.creditCardError.owner.description":
      "Your credit card was declined. To continue your package, please check and update your payment method.",
    "BroadcastBarWarning.creditCardError.admin.description":
      "To continue your package, please contact the owner and update the payment method.",

    "BroadcastBarWarning.walletError.title": "There is a problem with your payment.",
    "BroadcastBarWarning.walletError.owner.description":
      "Your LeanBiz credits remain insufficient. To continue your package, please add a credit card and update your payment method.",
    "BroadcastBarWarning.walletError.admin.description":
      "To continue your package, please contact the owner and update the payment method.",

    "BroadcastBarWarning.disabledChoosePackage.title": "You're not allowed to upgrade your package.",
    "BroadcastBarWarning.disabledChoosePackage.description":
      "Please contact our customer success team for updating to our latest package version.",
  },
  th: {
    Hi: "สวัสดี",
    "Account settings": "ตั้งค่าบัญชีผู้ใช้",
    "projectName is a required field": "กรุณาใส่ชื่อโปรเจค",
    "Project Name": "ชื่อโปรเจค",
    "Create project": "สร้างร้านค้า",
    Create: "สร้าง",
    "projectList.noMoreProject": "ยังไม่มีร้านค้า",
    "BroadcastBarWarning.title": "คุณมียอดเงินที่ค้างชำระ",
    "BroadcastBarWarning.description1": "ตอนนี้คุณมียอดเงินที่ค้างชำระ กรุณาเติมเงินเข้าระบบวอลเล็ต",
    "BroadcastBarWarning.description2": "หรือติดต่อ contact@leanbiz.ai เพื่อชำระค่าบริการและเปิดใช้งานระบบของคุณ",
    "BroadcastBarWarning.button": "ไปที่หน้าวอลเล็ท",
    "BroadcastBarWarning.PleaseContact": "กรุณาติดต่อไปที่",
    "BroadcastBarWarning.toCreateTheStore": "เพื่อสร้างร้าน",

    "BroadcastBarWarning.blocked.owner.title": "บัญชีของคุณถูกระงับการใช้งาน",
    "BroadcastBarWarning.blocked.owner.description":
      "บัญชีของคุณถูกระงับการใช้งานเนื่องจากมียอดค้างชำระ กรุณาตรวจสอบและอัปเดตวิธีการชำระเงินเพื่อชำระค่าบริการและเปิดใช้งานระบบของคุณอีกครั้ง",
    "BroadcastBarWarning.blocked.admin.title": "บัญชีของคุณถูกระงับการใช้งาน โปรดติดต่อเจ้าของบัญชี",
    "BroadcastBarWarning.blocked.admin.description":
      "บัญชีของคุณถูกระงับการใช้งานเนื่องจากมียอดค้างชำระ โปรดติดต่อเจ้าของบัญชีเพื่อชำระค่าบริการและเปิดใช้งานระบบของคุณอีกครั้ง",
    "BroadcastBarWarning.button.manageBilling": "จัดการการเรียกเก็บเงิน",

    "BroadcastBarWarning.creditCardError.title": "พบปัญหาการในการชำระเงินของคุณ",
    "BroadcastBarWarning.creditCardError.owner.description":
      "บัตรเครดิตทั้งหมดของคุณไม่สามารถทำการชำระเงินได้ หากต้องการใช้แพ็กเกจต่อไป กรุณาตรวจสอบและอัปเดตวิธีการชำระเงินที่มีอยู่",
    "BroadcastBarWarning.creditCardError.admin.description":
      "เพื่อความต่อเนื่องในการใช้งาน โปรดติดต่อเจ้าของบัญชีเพื่อชำระค่าบริการ",

    "BroadcastBarWarning.walletError.title": "พบปัญหาการในการชำระเงินของคุณ",
    "BroadcastBarWarning.walletError.owner.description":
      "คุณมีเครดิตคงเหลือไม่เพียงพอสำหรับการชำระเงิน กรุณาเพิ่มข้อมูลบัตรเครดิตเพื่อใช้งานได้อย่างต่อเนื่อง",
    "BroadcastBarWarning.walletError.admin.description":
      "เพื่อความต่อเนื่องในการใช้งาน โปรดติดต่อเจ้าของบัญชีเพื่อชำระค่าบริการ",

    "BroadcastBarWarning.disabledChoosePackage.title": "ไม่สามารถอัพเกรดแพ็คเกจด้วยตนเองได้!",
    "BroadcastBarWarning.disabledChoosePackage.description":
      "กรุณาติดต่อฝ่ายบริการลูกค้า เพื่อทำการเปลี่ยนเป็นแพ็คเกจแบบใหม่",
  },
};

export default project;

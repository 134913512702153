import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { SmallSquareButton } from "components/Button";
import Card from "components/Card";
import Grid from "components/Grid";
import { SvgIcon } from "components/Icon";
import { IcCheck, IcError, IcEdit, IcInfo } from "components/SvgIcons";
import TextField from "components/TextField";
import Typography from "components/Typography";
import COLORS from "constants/Colors";
import { shippopEmailRequiredSchema, shippopEmailSchema } from "../SenderInformation/validateSchema";

type ShippopEmailFormPropsSchema = {
  email: string;
};

type EmailFormPropsType = {
  email: string;
  isRequired: boolean;
  onChangeEmail: (newEmail: string) => void;
};

const EmailForm: React.FC<EmailFormPropsType> = (props) => {
  const { t } = useTranslation();

  const { email, isRequired, onChangeEmail } = props;

  const [isEditEmail, setIsEditEmail] = useState(!email);

  const { register, errors, handleSubmit } = useForm<ShippopEmailFormPropsSchema>({
    resolver: yupResolver(isRequired ? shippopEmailRequiredSchema : shippopEmailSchema),
  });

  const handleToggleEditEmail = () => {
    if (isEditEmail && !email) return;
    setIsEditEmail(!isEditEmail);
  };

  const handleSaveEmail = ({ email: newEmail }: ShippopEmailFormPropsSchema) => {
    onChangeEmail(newEmail);
    setIsEditEmail(false);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="body3" color="darkMed" className="pb-2">
          {t("shipping.shippop.email.title")}
          <span className="font-weight-light font-italic">- {isRequired ? "required" : "optional"} </span>
        </Typography>
      </Grid>
      <Grid item container alignItems="center" xs={12} className="flex-nowrap">
        <Grid item className="d-flex flex-grow-1">
          <TextField
            name="email"
            defaultValue={email || ""}
            placeholder="example@leanbiz.ai"
            fullWidth
            variant="outlined"
            disabled={!isEditEmail}
            validate={register}
            error={Boolean(errors?.email)}
            helperText={t(`${errors?.email?.message || ""}`)}
          />
        </Grid>
        <Grid item className="d-flex flex-grow-0 flex-row pl-3">
          {isEditEmail && (
            <Grid item className="pr-2">
              <SmallSquareButton onClick={handleSubmit(handleSaveEmail)}>
                <SvgIcon
                  component={IcCheck}
                  size="small"
                  htmlColor={COLORS.DarkMed}
                  className="m-0 p-0"
                  viewBox="0 0 32 32"
                />
              </SmallSquareButton>
            </Grid>
          )}
          <Grid>
            <SmallSquareButton onClick={handleToggleEditEmail} isDisabled={isEditEmail && !email}>
              <SvgIcon
                component={isEditEmail ? IcError : IcEdit}
                size="small"
                htmlColor={COLORS.DarkMed}
                className="m-0 p-0"
                viewBox={isEditEmail ? "0 0 32 32" : "-2 0 34 34"}
              />
            </SmallSquareButton>
          </Grid>
        </Grid>
      </Grid>
      {email && isRequired && (
        <Grid item xs={12} className="pt-3">
          <Card noShadow bgColor={COLORS.Light} noBorder>
            <Grid container className="flex-nowrap">
              <Grid item className="pl-2 pr-4 d-flex flex-grow-0">
                <SvgIcon component={IcInfo} fontSize="small" htmlColor={COLORS.DarkMed} className="m-0 p-0" />
              </Grid>
              <Grid item className="d-flex flex-grow-1">
                <Typography variant="body3">{t("shipping.shippop.email.remark", { email })}</Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      )}
      {!isRequired && (
        <Grid item xs={12} className="pt-3">
          <Card noShadow bgColor={COLORS.Light} noBorder>
            <Grid container className="flex-nowrap">
              <Grid item className="pl-2 pr-4 d-flex flex-grow-0">
                <SvgIcon component={IcInfo} fontSize="small" htmlColor={COLORS.DarkMed} className="m-0 p-0" />
              </Grid>
              <Grid item className="d-flex flex-grow-1">
                <Typography variant="body3">{t("shipping.shippop.credential.email.remark")}</Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      )}
    </Grid>
  );
};

export default EmailForm;

import { PromotionType, PromotionSettingsType } from "@leanbizai/shopping-cart-calculator";
import { BotFlowBehaviorType, Gender } from "types/AISetting";

export enum MEMBER_ROLE {
  OWNER = "owner",
  ADMIN = "admin",
}

export type Member = {
  id: string;
  userId: string;
  displayName: string;
  email: string;
  role: string;
  lineName: string;
  isEnableNotification: boolean;
  user: { displayName: string; avatar: string };
};

export enum OrderSettingType {
  ABANDON = "ABANDON",
  REMIND = "REMIND",
}

export enum WarningType {
  CREDIT_CARD_ERROR = "CREDIT_CARD_ERROR",
  WALLET_ERROR = "WALLET_ERROR",
  BLOCKED_PROJECT = "BLOCKED_PROJECT",
  DISABLED_CHOOSE_PACKAGE = "DISABLED_CHOOSE_PACKAGE",
}

export type GeneralSettingFormType = {
  botGender: Gender;
  botTimer: number;
  isBotTimerActive: boolean;
  isNotificationActive: boolean;
  isProcessingPostbackEnabled: boolean;
  searchProductsOrder: string;
  isOpenWebviewOnSelectProductEnabled: boolean;
  adminWorkingHours: AdminWorkingHours;
  isChatbotEnabled: boolean;
};

export type OrderSettingFormType = {
  isPaymentNotificationActive: boolean;
  isMultiCODOrderWithOutDepositEnabled: boolean;
  isEmailRequired: boolean;
  orderNumberPrefix: string;
  paymentNotiHr: number;
  paymentNotiMin: number;
  receiptNumberPrefix: string;
  reserveExpHr: number;
  reserveExpMin: number;
};

export type PrefixOrderConfigType = {
  orderNumber: string;
  receiptNumber: string;
  updatedAt: string;
};

export type ProjectConfigurationOrderTimerType = {
  type: string;
  isActive: boolean;
  value: number;
};

type ProjectConfigurationOrderType = {
  timer: ProjectConfigurationOrderTimerType[];
  isMultiCODOrderWithOutDepositEnabled: boolean;
  isEmailRequired: boolean;
};

export type ScheduleType = {
  isActive: boolean;
  start: string;
  end: string;
  timeInAdvance: number;
};

export type ThemeColorType = {
  primary: string;
  secondary: string;
};

export type ThemeConfigType = {
  color: ThemeColorType;
};

export type NotificationConfigType = {
  isActive: boolean;
};

export type DeliveryConfigType = { schedule: ScheduleType };

export type UpSellingPromotionConfiguration = {
  isActive: boolean;
  promotionType: PromotionType;
  settingsType: PromotionSettingsType;
};

export type PromotionConfigType = {
  upselling: UpSellingPromotionConfiguration[];
};

export type ProjectConfigurationType = {
  order: ProjectConfigurationOrderType;
  delivery: DeliveryConfigType;
  notification: NotificationConfigType;
  theme: ThemeConfigType;
  promotion: PromotionConfigType;
  botFlowBehavior: BotFlowBehaviorType;
};

type BotTimerType = {
  isOpen: boolean;
  timeToResume: number;
};

export type ProjectSettingType = {
  configuration: ProjectConfigurationType;
  botTimer: BotTimerType;
  botGender: Gender;
  searchProductsOrder: string;
  isChatbotEnabled: boolean;
  adminWorkingHours: AdminWorkingHours;
};

export type ProjectSettingQueryType = {
  getProject: ProjectSettingType;
};

export type GenerateOTPType = {
  OTP: string;
  projectId: string;
  userId: string;
};

export enum Day {
  MONDAY = "MONDAY",
  TUESDAY = "TUESDAY",
  WEDNESDAY = "WEDNESDAY",
  THURSDAY = "THURSDAY",
  FRIDAY = "FRIDAY",
  SATURDAY = "SATURDAY",
  SUNDAY = "SUNDAY",
}

export type WorkingHour = {
  day: Day;
  isActive: boolean;
  time: WorkingTime[];
};

export type WorkingTime = {
  start: string;
  end: string;
};

export type WorkingTimeWithId = WorkingTime & { id?: string };

export type AdminWorkingHours = {
  isActive: boolean;
  workingHours: WorkingHour[];
};

import React from "react";
import styled, { css } from "styled-components";
import MUIButton from "@material-ui/core/Button";

import { ButtonColors, ButtonSize } from "types/Button";
import { ThemeType } from "../Theme/type";

type ButtonColorType = ButtonColors;

type ButtonPropsType = {
  color?: ButtonColorType;
  theme: ThemeType;
  borderRadius?: string;
  selected?: boolean;
  size?: ButtonSize;
  fullWidth?: boolean;
  isSelected?: boolean;
  minHeight?: number;
  minWidth?: number;
  isNoneBorder?: boolean;
};

type SmallSquareButtonPropsType = {
  isSelected?: boolean;
  isDisabled?: boolean;
};

type FloatingButtonPropsType = {
  color?: ButtonColors.DARK_GRAY | ButtonColors.PRIMARY;
  theme: ThemeType;
};

type ButtonWithIconPropsType = { isActive?: boolean };

const getColors = (theme: ThemeType, color?: ButtonColorType) => {
  switch (color) {
    case ButtonColors.SECONDARY:
      return {
        bgColor: theme.COLORS.LightWhite,
        textColor: theme.COLORS.DarkGray,
        borderColor: theme.COLORS.LightBlue,
      };
    case ButtonColors.GHOST:
      return {
        bgColor: "transparent",
        textColor: theme.COLORS.Primary,
        borderColor: theme.COLORS.Primary,
      };
    case ButtonColors.GHOST_WHITE:
      return {
        bgColor: "transparent",
        textColor: theme.COLORS.LightWhite,
        borderColor: theme.COLORS.White,
      };
    case ButtonColors.ERROR:
      return {
        bgColor: theme.COLORS.Error,
        textColor: theme.COLORS.LightWhite,
        borderColor: theme.COLORS.Error,
      };
    case ButtonColors.NEW_PRIMARY:
      return {
        bgColor: theme.COLORS.NewPrimary,
        textColor: theme.COLORS.White,
        borderColor: theme.COLORS.NewPrimary,
      };
    case ButtonColors.TEXT_ERROR:
      return {
        bgColor: "transparent",
        textColor: theme.COLORS.Error,
        borderColor: theme.COLORS.White,
      };
    case ButtonColors.LINK:
      return {
        bgColor: "transparent",
        textColor: theme.COLORS.NewPrimary,
        borderColor: "transparent",
      };
    default:
      // Primary color
      return {
        bgColor: theme.COLORS.NewPrimary,
        textColor: theme.COLORS.LightWhite,
        borderColor: theme.COLORS.NewPrimary,
      };
  }
};

const getSizes = (size?: string) => {
  switch (size) {
    case ButtonSize.SMALL:
      return {
        fontSize: "12px",
        minHeight: "32px",
        letterSpacing: "0.38px",
      };
    case ButtonSize.MEDIUM:
      return {
        fontSize: "14px",
        minHeight: "42px",
        letterSpacing: "normal",
      };
    case ButtonSize.LARGE:
      return {
        fontSize: "16px",
        minHeight: "48px",
        letterSpacing: "0.5px",
      };
    default:
      // same as large size
      return {
        fontSize: "16px",
        minHeight: "48px",
        letterSpacing: "0.5px",
      };
  }
};

const Button = styled(
  ({
    _bgColor,
    _textColor,
    _borderColor,
    _fontSize,
    _minWidth,
    _minHeight,
    _letterSpacing,
    _fullWidth,
    _borderRadius,
    _color,
    _isSelected,
    _size,
    _isNoneBorder,
    ...rest
  }) => <MUIButton {...rest} />,
).attrs((props: ButtonPropsType) => {
  const { theme, color, size, minHeight: minHeightFromProps, minWidth } = props;
  const { bgColor, textColor, borderColor } = getColors(theme, color);
  const { fontSize, letterSpacing, minHeight } = getSizes(size);

  return {
    bgColor,
    textColor,
    borderColor,
    fontSize,
    minHeight: minHeightFromProps || minHeight,
    minWidth,
    letterSpacing,
  };
})`
  border-radius: ${({ borderRadius }) => borderRadius || "5px"};
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "initial")};
  min-height: ${({ minHeight }) => (typeof minHeight === "number" ? `${minHeight}px` : minHeight)};
  min-width: ${({ minWidth }) => (minWidth ? `${minWidth}px` : "initial")};
  padding: 0 12px;
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ color }) => (color === ButtonColors.SECONDARY ? "normal" : "bold")};
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  text-align: center;
  text-transform: none;
  letter-spacing: ${({ letterSpacing }) => letterSpacing};
  color: ${({ theme, isSelected, textColor }) => (isSelected ? theme.COLORS.Primary : textColor)};
  border: ${({ color, theme, isSelected, borderColor, isNoneBorder }) => {
    if ([ButtonColors.NEW_PRIMARY, ButtonColors.LINK].includes(color as ButtonColors)) {
      return "unset";
    }

    if (isNoneBorder) {
      return "unset";
    }

    return `solid 0.5px ${isSelected ? theme.COLORS.Primary : borderColor}`;
  }};
  background-color: ${({
    color,
    theme,
    isSelected,
    bgColor,
  }: {
    color?: string;
    theme: ThemeType;
    isSelected?: boolean;
    bgColor: string;
  }) => {
    if (isSelected) {
      return color === ButtonColors.GHOST_WHITE ? "transparent" : theme.COLORS.LightGray;
    }
    return color === ButtonColors.GHOST_WHITE ? "transparent" : bgColor;
  }};

  &:active {
    transform: scale(0.95);
    color: ${({ color, theme }: { color?: string; theme: ThemeType }) =>
      color === ButtonColors.NEW_PRIMARY ? theme.COLORS.White : "inherit"};
    background-color: ${({ color, theme }: { color?: string; theme: ThemeType }) =>
      color === ButtonColors.NEW_PRIMARY ? theme.COLORS.NewPrimaryLight : "inherit"};
    border: ${({ color = ButtonColors.PRIMARY }: { color?: string; theme: ThemeType; isNoneBorder: boolean }) => {
      if (color === ButtonColors.LINK) {
        return "none";
      }

      return `inherit`;
    }};
  }

  &:hover {
    color: ${({ color, theme }: { color?: string; theme: ThemeType }) => {
      if (ButtonColors.NEW_PRIMARY === color) {
        return theme.COLORS.White;
      }

      if (ButtonColors.TEXT_ERROR === color) {
        return theme.COLORS.Error;
      }

      return theme.COLORS.LightWhite;
    }};
    border: ${({
      color = ButtonColors.PRIMARY,
      theme,
      isNoneBorder,
    }: {
      color?: string;
      theme: ThemeType;
      isNoneBorder: boolean;
    }) => {
      if ([ButtonColors.NEW_PRIMARY, ButtonColors.LINK].includes(color as ButtonColors) || isNoneBorder) {
        return "none";
      }

      return `solid 0.5px ${theme.COLORS.SecondPrimary}`;
    }};
    background-color: ${({ color, theme }: { color?: string; theme: ThemeType }) => {
      if (color === ButtonColors.GHOST_WHITE) {
        return "transparent";
      }

      if (color === ButtonColors.NEW_PRIMARY) {
        return theme.COLORS.NewPrimaryDark;
      }

      if (color === ButtonColors.TEXT_ERROR) {
        return "transparent";
      }

      return theme.COLORS.SecondPrimary;
    }};
  }
  &:disabled {
    color: ${({ theme }) => theme.COLORS.LightBlue};
    border: solid 0.5px ${({ theme }) => theme.COLORS.LightBlue};
    background-color: ${({ color, theme }: { color?: string; theme: ThemeType }) =>
      color === ButtonColors.GHOST ? "transparent" : theme.COLORS.LightGray};
  }
`;

export const ButtonWithIcon = styled.button.attrs((props: ButtonWithIconPropsType) => {
  return { isActive: props.isActive };
})`
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 0.5px ${({ theme }) => theme.COLORS.LightBlue};
  border-radius: 5px;
  background-color: ${({ theme, isActive }) => (isActive ? theme.COLORS.Primary : theme.COLORS.White)};
  color: ${({ theme, isActive }) => (isActive ? theme.COLORS.White : theme.COLORS.DarkGray)};
  outline: none;
  cursor: pointer;

  &:active {
    transform: scale(0.95);
  }

  &:hover {
    border: solid 0.5px ${({ theme }) => theme.COLORS.Primary};
  }

  &[disabled] {
    opacity: 0.3;
  }

  &[disabled]:hover {
    border: solid 0.5px ${({ theme }) => theme.COLORS.LightBlue};
  }
`;

export const SmallSquareButton = styled((props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isSelected, isDisabled, ...rest } = props;
  return <div {...rest} />;
})<SmallSquareButtonPropsType>`
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 2px;
  border: solid 0.5px ${({ theme }) => theme.COLORS.LightBlue};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.COLORS.White};
  color: ${({ theme }) => theme.COLORS.DarkMed};

  ${({ isSelected, isDisabled }) =>
    !(isSelected || isDisabled) &&
    css`
      &:active {
        transform: scale(0.95);
      }

      &:hover {
        color: ${({ theme }) => theme.COLORS.Primary};
        border: solid 0.5px ${({ theme }) => theme.COLORS.Primary};
        background-color: ${({ theme }) => theme.COLORS.PrimaryNude};
      }
    `}

  ${({ isSelected }) =>
    isSelected &&
    css`
      color: ${({ theme }) => theme.COLORS.White};
      border: solid 0.5px ${({ theme }) => theme.COLORS.Primary};
      background-color: ${({ theme }) => theme.COLORS.Primary};
    `}

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      color: ${({ theme }) => theme.COLORS.LightBlue};
      border: solid 0.5px ${({ theme }) => theme.COLORS.LightGray};
      background-color: ${({ theme }) => theme.COLORS.LightGray};
    `}
`;

const getFloatingButtonColor = (theme: ThemeType) => ({
  darkGray: theme.COLORS.DarkGray,
  primary: theme.COLORS.Primary,
});

export const FloatingButton = styled.button.attrs((props: FloatingButtonPropsType) => {
  const { color: colorProp, theme } = props;
  const color = colorProp ? getFloatingButtonColor(theme)[colorProp] : theme.COLORS.White;
  return { color };
})`
  width: 50px;
  height: 50px;
  border-radius: 25px;
  box-shadow: 3px 4px 10px 0 ${({ theme }) => theme.COLORS.BoxShadow};
  border: solid 0.5px ${({ theme }) => theme.COLORS.LightBlue};
  background-color: ${({ theme, color }) => color || theme.COLORS.White};
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;

  &:hover {
    border: solid 0.5px ${({ theme }) => theme.COLORS.Primary};
    background-color: ${({ theme }) => theme.COLORS.LightGray};
    svg {
      color: ${({ theme }) => theme.COLORS.Primary};
    }
  }
`;

export const DeleteButton = styled(Button)`
  border: solid 1px ${({ theme }) => theme.COLORS.Error};
  background: ${({ theme }) => theme.COLORS.Error};
  color: ${({ theme }) => theme.COLORS.White};
  position: relative;
  padding: 12px;
`;

export const NewPrimaryButton = Button;

export default Button;

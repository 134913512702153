import React, { FC } from "react";

import Grid from "components/Grid";
import Typography from "components/Typography";

const TermsOfService: FC = () => {
  return (
    <Grid item xs={12}>
      <Grid container justify="center" className="pt-3 pb-4">
        <Typography variant="title2" color="primary">
          เงื่อนไขการให้บริการ
        </Typography>
      </Grid>
      <Typography variant="body3" color="darkMed" className="pb-4">
        เงื่อนไขการให้บริการลูกค้า (ซึ่งต่อไปนี้จะเรียกว่า “เงื่อนไขการให้บริการ”) นี้เป็นข้อตกลงระหว่าง บริษัท ลีนบิซ
        จํากัด ซึ่งมีสำนักงานใหญ่ตั้งอยู่เลขที่ 69/429 หมู่ที่ 2 ตำบลวิชิต อำเภอเมืองภูเก็ต จังหวัดภูเก็ต
        (ซึ่งต่อไปนี้จะเรียกว่า “LeanBiz” หรือ “บริษัท”) กับ ผู้ใช้บริการ (ซึ่งต่อไปนี้จะเรียกว่า “ผู้ใช้บริการ”)
        อีกฝ่ายหนึ่ง เพื่อใช้บริการระบบ LeanBiz ผ่านเว็บไซต์และแอปพลิเคชัน LeanBiz (ซึ่งต่อไปนี้จะเรียกว่า “บริการ”)
        หรือผ่านทางผู้ให้บริการภายนอกและวิธีดำเนินการตามที่บริษัทกำหนด
        ผู้ใช้บริการมีหน้าที่ทําความเข้าใจอย่างละเอียดก่อนการใช้บริการ และคอยตรวจสอบเงื่อนไขการให้บริการนี้อย่างสม่ำเสมอ
        หากมีข้อสงสัยเกี่ยวกับบริการ ผู้ใช้บริการสามารถติดต่อสอบถามได้ผ่านทาง เพจ Facebook LeanBiz (
        <a href="https://www.facebook.com/LeanbizAi">https://www.facebook.com/LeanbizAi</a>)
        หรือช่องทางอื่นตามที่บริษัทกำหนด
      </Typography>
      <Typography variant="body3" color="darkMed">
        ผู้ใช้บริการจะได้รับบริการนี้จาก LeanBiz
        โดยเราจะให้บริการดังกล่าวร่วมกันกับผู้ให้บริการอื่นใดที่เกี่ยวข้องของบริษัทตามเงื่อนไข
        ข้อกำหนดและนโยบายการใช้บริการที่กำหนด หากผู้ใช้บริการได้ลงทะเบียนหรือเปิดใช้บัญชี และใช้บริการของ LeanBiz
        ให้ถือว่าผู้ใช้บริการได้ยอมรับเงื่อนไข ข้อกำหนดและนโยบายการใช้บริการที่ LeanBiz
        หรือผู้ให้บริการที่เกี่ยวข้องกำหนดไว้แล้ว
        และผู้ใช้บริการยินยอมให้บริษัททำการโอนถ่ายข้อมูลส่วนบุคคลของผู้ใช้บริการที่ในความดูแลของบริษัทให้แก่ผู้ให้บริการที่เกี่ยวข้องเพื่อใช้ในการเปิดใช้หรือลงทะเบียนบัญชี
        จัดการ ดำเนินการ บริหาร และให้บริการอื่นใดให้แก่ผู้ใช้บริการ
        รวมถึงเพื่อวัตถุประสงค์อื่นใดที่เกี่ยวข้องและที่เกี่ยวเนื่องกับการให้บริการดังกล่าว
      </Typography>
      <Typography variant="title7" color="primary" className="pt-2 pb-3">
        1. ข้อกำหนดทั่วไปและเงื่อนไขการใช้บริการทั่วไป
      </Typography>
      <div className="pl-4">
        <Typography variant="body3" color="darkMed" className="pb-1">
          (1.1) LeanBiz
          เป็นผู้ให้บริการระบบหรือแพลตฟอร์มจัดการร้านค้าออนไลน์แบบครบวงจรผ่านทางเว็บไซต์และแอปพลิเคชันและให้บริการระบบแชทบอทปัญญาประดิษฐ์ขั้นสูง
          (ซึ่งต่อไปนี้จะเรียกว่า “AI Chatbot”) และระบบการบริหารการจัดเก็บข้อมูลซึ่งรวมถึงคำสั่งอย่างมืออาชีพ
          การบริหารสินค้าคงคลัง การตอบคำถามตลอด 24 ชั่วโมง และตัวแทนในการตอบแชท (ซึ่งต่อไปนี้จะเรียกว่า “Live Chat”)
          เครื่องมือทางการตลาด และรายงานการขายพร้อมด้วยแผนผังและสถิติแสดงยอดขายรวม คำสั่งซื้อ ลูกค้า
          และมูลค่าคำสั่งซื้อเฉลี่ยในแต่ละช่วงเวลาผ่านระบบซอฟต์แวร์ และ/หรือ
          แพลตฟอร์มเทคโนโลยีเพื่อช่วยพัฒนาประสิทธิภาพการขายในกิจการของท่าน (ต่อไปนี้จะเรียกรวมกันว่า “บริการพื้นฐาน”)
          ทั้งนี้ LeanBiz มีบริการทางเลือกให้ผู้ใช้บริการได้เลือกใช้ (ซึ่งต่อไปนี้จะเรียกว่า “บริการทางเลือก”) ซึ่งมี
          ดังนี้
        </Typography>
        <div className="pl-4">
          <Typography variant="body3" color="darkMed" className="pb-1">
            (1.1.1) บริการเพิ่มเติมในฐานะรายการเสริมจากบริการพื้นฐานเพื่อพัฒนาความสัมพันธ์ของท่านกับลูกค้าของท่าน
            (ซึ่งต่อไปนี้จะเรียกว่า “บริการเสริม”)
          </Typography>
          <Typography variant="body3" color="darkMed" className="pb-1">
            (1.1.2) บริการจัดส่งโดยบริษัทขนส่งซึ่งเป็นบุคคลภายนอกเพื่อช่วยจัดส่งสินค้าของท่าน (ซึ่งต่อไปนี้จะเรียกว่า
            “บริการจัดส่ง”)
          </Typography>
        </div>
        <Typography variant="body3" color="darkMed" className="pb-1">
          บริษัทฯ จะให้บริการพื้นฐานและบริการทางเลือก (เรียกรวมกันว่า “บริการ”) ผ่านเว็บไซต์ (
          <a href="https://console.leanbiz.ai">https://console.leanbiz.ai</a>) แอพพลิเคชั่น และ/หรือ
          แพลตฟอร์มของคู่ค้าของบริษัทฯ หรือคู่ค้าอื่นตามสัญญาที่บริษัทฯอาจเข้าผูกพันในอนาคต (แต่ละแห่งเรียกว่า
          “แพลตฟอร์ม”)
        </Typography>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (1.2) ผู้ใช้บริการ เป็นหรือเป็นตัวแทนนิติบุคคล องค์กร สมาคม หรือบุคคลธรรมดา ที่ได้สมัครใช้ระบบ LeanBiz
          ผู้ใช้บริการจะได้ใช้บริการต่าง ๆ โดยบริษัทจะให้บริการดังกล่าวร่วมกันกับผู้ให้บริการขนส่งและคู่ค้าอื่น ๆ
          ที่เกี่ยวข้องของบริษัทตามเงื่อนไข ข้อกำหนดและนโยบายการใช้บริการที่กำหนด ผู้ใช้บริการรับทราบและยอมรับเงื่อนไข
          ข้อกำหนดและนโยบายการให้บริการ LeanBiz หรือผู้ให้บริการที่เกี่ยวข้องกำหนดไว้แล้ว
          และผู้ใช้บริการยินยอมให้บริษัททำการโอนถ่ายข้อมูลส่วนบุคคลของผู้ใช้บริการที่ในความดูแลของบริษัทให้แก่ผู้ให้บริการขนส่งที่เกี่ยวข้องเพื่อใช้ในการจัดการ
          ดำเนินการ บริหาร และให้บริการอื่นใดให้แก่ผู้ใช้บริการ
          รวมถึงเพื่อวัตถุประสงค์อื่นใดที่เกี่ยวข้องและที่เกี่ยวเนื่องกับการให้บริการดังกล่าว
        </Typography>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (1.3) ผู้ใช้บริการตกลงที่จะปฏิบัติและผูกพันตามเงื่อนไขการให้บริการ
          ซึ่งการใช้บริการของผู้ใช้บริการถือเป็นการยอมรับเงื่อนไขการให้บริการนี้ โดยบริษัทอาจแก้ไข
          หรือเพิ่มเติมเงื่อนไขการให้บริการได้ ไม่ว่าในเวลาใด โดยบริษัทจะแจ้งให้ผู้ใช้บริการทราบ
          และการที่ผู้ใช้บริการใช้บริการต่อไปภายหลังจากที่มีการแก้ไข หรือเพิ่มเติมดังกล่าว ย่อมถือเป็นการยอมรับการแก้ไข
          หรือเพิ่มเติมในแต่ละครั้ง
        </Typography>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (1.4) เว้นแต่จะได้มีการแจ้ง หรือกําหนดไว้เป็นอย่างอื่น คุณลักษณะใหม่ ๆ ที่ได้แก้ไข เพิ่มเติม
          หรือปรับปรุงในบริการให้ถือว่าอยู่ภายใต้เงื่อนไขการให้บริการฉบับนี้ทั้งสิ้น
        </Typography>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (1.5) ในการใช้บริการ ผู้ใช้บริการจะต้องมีบัญชี LeanBiz (ซึ่งต่อไปนี้จะเรียกว่า “บัญชี”)
          เพื่อใช้ในการทํารายการต่าง ๆ อันเกี่ยวเนื่องกับการใช้บริการ
        </Typography>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (1.6) ในกรณีที่ผู้ใช้บริการใช้รหัสผ่านทํารายการใด ๆ อันเกี่ยวเนื่องกับการใช้บริการเสร็จสิ้นสมบูรณ์แล้ว
          ผู้ใช้บริการยอมรับว่าไม่สามารถยกเลิก เพิกถอน เปลี่ยนแปลง หรือแก้ไขรายการดังกล่าวได้
          และผู้ใช้บริการต้องผูกพันตามรายการดังกล่าวทุกประการหรือขึ้นอยู่กับดุลยพินิจของบริษัท
        </Typography>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (1.7) ในกรณีที่ผู้ใช้บริการลืมรหัสผ่าน หรือต้องการเปลี่ยนแปลงรหัสผ่านไม่ว่าด้วยเหตุใด ๆ
          ผู้ใช้บริการต้องดําเนินการแจ้งให้บริษัททราบทันทีตามวิธีการที่บริษัทกําหนด อย่างไรก็ตาม การดําเนินการต่าง ๆ
          ข้างต้นจะไม่มีผลกระทบต่อการกระทำใด ๆ ที่ได้ดําเนินการไปแล้วก่อนการแจ้งให้บริษัททราบ
        </Typography>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (1.8) ผู้ใช้บริการตกลงยินยอมชําระค่าธรรมเนียมการใช้บริการตามอัตราที่บริษัทกําหนด ทั้งนี้
          บริษัทสงวนสิทธิ์ในการเปลี่ยนแปลงอัตราค่าธรรมเนียมไม่ว่าเวลาใด โดยบริษัทจะแจ้งให้ทราบล่วงหน้าทั้งนี้
          ในกรณีที่ผู้ใช้บริการจะต้องชำระค่าธรรมเนียมใด ๆ ให้แก่บริษัทตามที่กำหนดไว้ข้างต้น
          ผู้ใช้บริการรับทราบและยินยอมที่จะปฏิบัติตามข้อกำหนดและเงื่อนไขการใช้บริการ E-Wallet
        </Typography>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (1.9) ในกรณีที่มีข้อผิดพลาด ข้อสงสัย และ/หรือข้อโต้แย้งในการเรียกเก็บค่าบริการจัดส่งพัสดุ
          ผู้ใช้บริการจำเป็นต้องแจ้งความผิดพลาดนั้นเพื่อแก้ไขให้ถูกต้องภายใน 30 วัน
          นับจากวันที่บริษัทแจ้งเก็บค่าบริการจัดส่งพัสดุ
        </Typography>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (1.10) หากผู้ใช้บริการไม่ได้ชำระค่าบริการที่บริษัทแจ้งภายในระยะเวลาที่กำหนด
          บัญชีจะถูกปิดการใช้งานชั่วคราวโดยไม่ต้องแจ้งให้ทราบล่วงหน้าจนกว่าผู้ใช้บริการจะชำระเงินเรียบร้อย
        </Typography>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (1.11) ในกรณีผู้ใช้บริการได้กระทำการหรือดำเนินธุรกรรมอันเป็นการฉ้อโกงหรือเป็นที่ต้องสงสัย
          ผู้ใช้บริการรับทราบและยินยอมให้บริษัทดำเนินการตรวจสอบได้โดยที่ไม่ต้องแจ้งให้ทราบล่วงหน้า
          และผู้ใช้บริการยินดีที่จะส่งข้อมูลและหลักฐานตามที่บริษัทแจ้งขอเพื่อใช้ในการตรวจสอบ ในระหว่างการตรวจสอบ
          ผู้ใช้บริการรับทราบและยินยอมว่าบริษัทมีความจำเป็นที่ต้องระงับการให้บริการ
          และ/หรืองดเว้นการทำธุรกรรมและยอดเก็บเงินปลายทาง (COD)
          ของผู้ใช้บริการเป็นการชั่วคราวจนกว่าจะดำเนินการตรวจสอบเสร็จสิ้น
          หากบริษัทพบว่าผู้ใช้บริการกระทำความผิดตามที่กฎหมายกำหนด
          ผู้ใช้บริการยินยอมที่จะถูกดำเนินคดีความตามขั้นตอนและกระบวนการตามที่กฎหมายแห่งประเทศไทยกำหนด ทั้งนี้
          บริษัทไม่มีส่วนเกี่ยวข้องในการกระทำความผิดใด ๆ ของผู้ใช้บริการ
        </Typography>
        <div className="pl-4">
          <Typography variant="body3" color="darkMed" className="pb-1">
            (1.11.1)
            ในกรณีที่บริษัทได้รับแจ้งร้องเรียนกรณีผู้รับปลายทางได้รับพัสดุไม่ตรงตามคำสั่งซื้อและ/หรือได้รับพัสดุทั้งที่ไม่ได้มีการสั่งซื้อ
            ไม่ว่าจะได้รับแจ้งจากช่องทางใดหรือหน่วยงานใด และบริษัทตรวจสอบพบว่า ผู้รับพัสดุปลายทางจำนวน 30%
            ของรอบการส่งพัสดุปลายทางนั้น ๆ ยืนยันตามข้อร้องเรียนข้างต้น บริษัทสามารถระงับจำนวนเงิน COD
            ทั้งหมดที่ผู้ใช้บริการจะต้องได้รับไว้ไม่มีกำหนดจนกว่าผู้รับพัสดุปลายทางหรือผู้เสียหายจะทำการร้องเรียนเพื่อขอเงินคืน
            โดยยอดเงินที่ระงับไว้ดังกล่าวใช้เพื่อสำรองจ่ายในการแก้ไขเยียวยากับผู้เสียหายที่ได้รับผลกระทบจากการส่งพัสดุไม่ตรงคำสั่งซื้อและ/หรือได้รับพัสดุทั้งที่ไม่ได้มีการสั่งซื้อ
          </Typography>
        </div>
      </div>
      <Typography variant="title7" color="primary" className="pt-2 pb-3">
        2. คำรับรองและคำรับประกัน
      </Typography>
      <div className="pl-4">
        <Typography variant="body3" color="darkMed" className="pb-1">
          ผู้ใช้บริการรับรองและรับประกันว่า
        </Typography>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (2.1) ผู้ใช้บริการมีความสามารถตามกฎหมายในการเข้าทำ ลงนามและปฏิบัติตามข้อผูกผันตามข้อตกลงการให้บริการฉบับนี้
        </Typography>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (2.2) ข้อตกลงการให้บริการฉบับนี้เป็นข้อผูกพันที่ชอบด้วยกฎหมาย สมบูรณ์
          และมีผลผูกพันต่อผู้ใช้บริการได้ตามข้อกำหนดของข้อตกลงฉบับนี้ ;
        </Typography>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (2.3) เอกสารและ/หรือข้อมูลที่บริษัท ได้รับหรือจะได้รับจากผู้ใช้บริการนั้นเชื่อถือได้ เป็นจริง ครบถ้วน ถูกต้อง
          และไม่บิดเบือน และ
        </Typography>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (2.4) บริการและ/หรือส่วนใดส่วนหนึ่งของบริการดังกล่าวจะต้องใช้เพื่อวัตถุประสงค์อันชอบด้วยกฎหมายเท่านั้น
        </Typography>
      </div>
      <Typography variant="title7" color="primary" className="pt-2 pb-3">
        3. ข้อสงวนสิทธิการรับประกัน
      </Typography>
      <div className="pl-4">
        <Typography variant="body3" color="darkMed" className="pb-1">
          (3.1) ท่านตกลงและรับทราบว่า
        </Typography>
        <div className="pl-4">
          <Typography variant="body3" color="darkMed" className="pb-1">
            (3.1.1) บริการจะถูกจัดให้บนพื้นฐานของ “สภาพจริง” และ “ตามเท่าที่มีอยู่” โดยไม่มีการรับประกันใด ๆ ทั้งสิ้น
            (ไม่ว่าโดยชัดแจ้งหรือโดยนัย)
          </Typography>
          <Typography variant="body3" color="darkMed" className="pb-1">
            (3.1.2) บริษัท และกรรมการ เจ้าหน้าที่ ลูกจ้าง และตัวแทนของบริษัท ขอสงวนสิทธิการรับประกันใด ๆ
            (ไม่ว่าโดยชัดแจ้งหรือโดยนัย) ซึ่งรวมถึงแต่ไม่จำกัดเพียง การรับประกันโดยนัยซึ่งความสามารถในทางการค้า
            ความเหมาะสมสำหรับวัตถุประสงค์เฉพาะ ประสิทธิภาพหรือความเหมาะสมตามความมุ่งหมายในการใช้งานของท่าน กรรมสิทธิ์
            และการไม่ถูกละเมิดการบริการรวมถึงข้อมูล เนื้อหา และสิ่งใดทั้งหมดที่อยู่ในบริการดังกล่าว
          </Typography>
          <Typography variant="body3" color="darkMed" className="pb-1">
            (3.1.3)
            ข้อมูลใดหรือสิ่งใดที่ดาวน์โหลดหรือได้มาด้วยประการใดผ่านการใช้บริการถูกเข้าถึงตามดุลยพินิจและความเสี่ยงของผู้ใช้บริการ
            และผู้ใช้บริการจะรับผิดชอบต่อความเสียหายที่เกิดขึ้นกับอุปกรณ์ของผู้ใช้บริการหรือการสูญหายของข้อมูลที่อาจเกิดจากการดาวน์โหลดข้อมูลหรือสิ่งดังกล่าวแต่เพียงผู้เดียว
            และ
          </Typography>
          <Typography variant="body3" color="darkMed" className="pb-1">
            (3.1.4)
            บริษัทเชื่อมต่อบริการบางส่วนกับระบบและ/หรือข้อมูลของบุคคลภายนอกและเนื้อหาหรือข้อมูลที่ได้รับและได้มาจากบุคคลภายนอก
            ดังนั้น บริษัท ไม่ประกัน รับรอง หรือรับประกันในความถูกต้อง และความครบถ้วน หรือประสิทธิภาพ คุณภาพ
            และประสิทธิผลของบริการของบุคคลภายนอกนั้นแต่อย่างใด
          </Typography>
        </div>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (3.2) บริษัทไม่รับรองหรือรับประกันว่า
        </Typography>
        <div className="pl-4">
          <Typography variant="body3" color="darkMed" className="pb-1">
            (ก) บริการหรือส่วนใดส่วนหนึ่งของบริการ เชื่อถือได้ พร้อมใช้งาน เป็นปัจจุบัน หรือปราศจากข้อผิดพลาด
            หรือใช้ได้อย่างต่อเนื่องหรือปลอดภัย
          </Typography>
          <Typography variant="body3" color="darkMed" className="pb-1">
            (ข) ความผิดพลาดใด ๆ ในบริการจะได้รับการแก้ไข และ
          </Typography>
          <Typography variant="body3" color="darkMed" className="pb-1">
            (ค) แต่ละแพลตฟอร์มหรือเซิร์ฟเวอร์ของแพลตฟอร์มนั้นปราศจากไวรัสหรือส่วนประกอบที่เป็นอันตราย
            ส่งผ่านได้อย่างปลอดภัย หรือทำงานได้อย่างเหมาะสม
          </Typography>
        </div>
      </div>

      <Typography variant="title7" color="primary" className="pt-2 pb-3">
        4. ข้อจำกัดความรับผิด
      </Typography>
      <div className="pl-4">
        <Typography variant="body3" color="darkMed" className="pb-1">
          (4.1) บริษัทจะรับผิดต่อความเสียหายที่มีสาเหตุมาจากระบบเท่านั้น ความผิดพลาดที่เกิดจากสัญญาณ, ซอฟต์แวร์ ระบบ
          หรือ/และอุปกรณ์ของผู้ใช้บริการและผู้ให้บริการบุคคลที่สามที่ไม่ได้อยู่ในความควบคุมของบริษัท
          บริษัทไม่ต้องรับผิดชอบต่อความเสียหายดังกล่าว <strong>โดยเฉพาะ</strong>
        </Typography>
        <div className="pl-4">
          <Typography variant="body3" color="darkMed" className="pb-1">
            (4.1.1) บริษัทจะไม่รับผิดชอบต่อการสูญหายหรือความเสียหายใด ๆ ของพัสดุ
            เนื่องจากบริษัทไม่ได้มีหน้าที่และความรับผิดชอบในการดูแลและจัดส่งพัสดุของผู้ใช้บริการ
            บริษัทเป็นเพียงผู้ให้บริการระบบจัดการร้านค้าออนไลน์
            และให้อัตราค่าบริการส่งพัสดุกับผู้ใช้บริการในอัตราพิเศษเท่านั้น
          </Typography>
          <Typography variant="body3" color="darkMed" className="pb-1">
            (4.1.2) บริษัทจะไม่รับผิดชอบต่อค่าบริการส่งพัสดุและยอดชำระเงินปลายทาง (COD)
            ที่ทางผู้ให้บริการขนส่งทำการเรียกเก็บกับผู้ใช้บริการ เว้นแต่จะเป็นไปตามดุลยพินิจของทางบริษัท
          </Typography>
          <Typography variant="body3" color="darkMed" className="pb-1">
            (4.1.3) บริษัทจะไม่รับผิดชอบต่อการกระทำความผิดตามกฎหมายใด ๆ ของผู้ใช้บริการ
            เนื่องจากบริษัทเป็นเพียงผู้ให้บริการระบบจัดการร้านค้าออนไลน์
            ไม่ได้มีหน้าที่และความรับผิดชอบในการจัดการร้านค้าของผู้ใช้บริการ
            และหากมีหน่วยงานแจ้งเรื่องร้องเรียนของผู้ใช้บริการมายังบริษัท
            บริษัทจะแจ้งไปยังผู้ใช้บริการและขอความร่วมมือในการดำเนินการตรวจสอบตามขั้นตอนอีกครั้ง
          </Typography>
        </div>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (4.2) ทั้งนี้ บริษัท กรรมการ เจ้าหน้าที่ ลูกจ้าง ผู้รับมอบอำนาจ
          หรือตัวแทนของบริษัทไม่ต้องรับผิดสำหรับความเสียหายโดยตรง ความเสียหายโดยอ้อม หรือค่าเสียหายสืบเนื่อง
          อันเกิดจากหรือเกี่ยวเนื่องกับ
        </Typography>
        <div className="pl-4">
          <Typography variant="body3" color="darkMed" className="pb-1">
            (4.2.1) การขัดข้อง การระงับชั่วคราว
            หรือการยกเลิกของบริการหรือส่วนใดส่วนหนึ่งของบริการดังกล่าวตามข้อตกลงการให้บริการฉบับนี้
          </Typography>
          <Typography variant="body3" color="darkMed" className="pb-1">
            (4.2.2) การใช้บริการผิดหรือการใช้บริการที่ไม่เหมาะสมโดยผู้ใช้บริการและ/หรือลูกจ้าง เจ้าหน้าที่
            ผู้ที่ผู้ใช้บริการแต่งตั้ง หรือผู้แทนของผู้ใช้บริการ
          </Typography>
          <Typography variant="body3" color="darkMed" className="pb-1">
            (4.2.3) การสื่อสารหรือข้อตกลงใดในช่องทางการสนทนา (Chat Channel)
            ระหว่างลูกค้าและ/หรือสมาชิกและแชทบอทปัญญาประดิษฐ์ (AI Chatbot) และ
          </Typography>
          <Typography variant="body3" color="darkMed" className="pb-1">
            (4.2.4) การกระทำหรืองดเว้นกระทำการของผู้ใช้บริการ ลูกจ้าง เจ้าหน้าที่ ผู้ที่ผู้ใช้บริการแต่งตั้ง
            หรือผู้แทนของผู้ใช้บริการ และบุคคลภายนอก
          </Typography>
        </div>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (4.3) ข้อเรียกร้องใด โดยผู้ใช้บริการต่อบริษัท กรรมการ เจ้าหน้าที่ ลูกจ้าง ผู้รับมอบอำนาจ ที่ปรึกษา ผู้แทน
          หรือตัวแทนของบริษัท
          สำหรับความสูญหายหรือความเสียหายที่เกิดขึ้นจากหรือเกี่ยวเนื่องกับการใช้บริการหรือความไม่สามารถใช้บริการจำกัดไว้เพียงเท่าจำนวนเงินรวมของจำนวนค่าบริการที่ชำระโดยผู้ใช้บริการสำหรับการใช้บริการก่อนยื่นข้อเรียกร้องดังกล่าว
        </Typography>
      </div>
      <Typography variant="title7" color="primary" className="pt-2 pb-3">
        5. การชดใช้ค่าเสียหาย
      </Typography>
      <div className="pl-4">
        <Typography variant="body3" color="darkMed" className="pb-1">
          ผู้ใช้บริการต้องชดใช้ค่าเสียหายและป้องกันความเสียหายแก่บริษัท กรรมการ เจ้าหน้าที่ ลูกจ้าง ผู้รับมอบอำนาจ
          และตัวแทนของบริษัท ภายใน 30 วันนับแต่วันที่ได้รับข้อเรียกร้องจากหรือต่อความสูญเสีย ความรับผิด การเรียกร้อง
          การกระทำ การทวงถาม ความเสียหาย ต้นทุน และค่าใช้จ่าย (รวมทั้งค่าฟ้องร้องดำเนินคดีและค่าทนายความ)
          ที่เกิดขึ้นโดยบริษัท หรือบุคคลใดดังกล่าวของบริษัท อันเป็นผลจากหรือเกี่ยวเนื่องกับ
        </Typography>
        <div className="pl-4">
          <Typography variant="body3" color="darkMed" className="pb-1">
            (ก) การใช้บริการหรือการใช้บริการที่ผิดโดยผู้ใช้บริการ
          </Typography>
          <Typography variant="body3" color="darkMed" className="pb-1">
            (ข) การผิดข้อกำหนดข้อใดของข้อตกลงการให้บริการฉบับนี้
          </Typography>
          <Typography variant="body3" color="darkMed" className="pb-1">
            (ค) การละเมิดสิทธิของบุคคลภายนอกโดยผู้ใช้บริการ หรือ
          </Typography>
          <Typography variant="body3" color="darkMed" className="pb-1">
            (ง) การให้บริการโดยใช้หรืออ้างถึงข้อมูลที่ผิด ไม่ถูกต้อง
            หรือบิดเบือนที่ท่านจัดหาเว้นแต่เกิดจากหรือเป็นผลจากความจงใจหรือประมาทเลินเล่ออย่างร้ายแรงของบริษัท
          </Typography>
        </div>
      </div>

      <Typography variant="title7" color="primary" className="pt-2 pb-3">
        6. การคุ้มครองข้อมูลและนโยบายความเป็นส่วนตัว
      </Typography>
      <div className="pl-4">
        <Typography variant="body3" color="darkMed" className="pb-1">
          ผู้ใช้บริการตกลงและรับทราบว่าบริษัทจะประมวลผลข้อมูลส่วนบุคคลของผู้ใช้บริการตามประกาศนโยบายความเป็นส่วนตัว หรือ
          Privacy Policy ที่ระบุบนเว็บไซต์ของบริษัท (<a href="https://console.leanbiz.ai">https://console.leanbiz.ai</a>
          ) และยืนยันว่าได้อ่านและเข้าใจข้อกำหนดที่ระบุในประกาศความเป็นส่วนตัวครบถ้วนแล้ว
        </Typography>
      </div>

      <Typography variant="title7" color="primary" className="pt-2 pb-3">
        7. ข้อจำกัดการโอนสิทธิหน้าที่
      </Typography>
      <div className="pl-4">
        <Typography variant="body3" color="darkMed" className="pb-1">
          ผู้ใช้บริการจะต้องไม่กระทำการใด ๆ ทั้งทางตรงและทางอ้อมในการเสนอขายบัญชีหรือโอนสิทธิในการใช้งานระบบ
          ไม่ว่าจะทั้งหมดหรือบางส่วนไปยังบุคคลภายนอก ไม่ว่าบุคคลดังกล่าวจะเป็นลูกค้าของผู้ให้บริการใดก็ตาม
          เว้นแต่จะได้รับความยินยอมเป็นลายลักษณ์อักษรจากบริษัท
        </Typography>
      </div>

      <Typography variant="title7" color="primary" className="pt-2 pb-3">
        8. สิทธิในทรัพย์สินทางปัญญา
      </Typography>
      <div className="pl-4">
        <Typography variant="body3" color="darkMed" className="pb-1">
          (8.1) สิทธิในทรัพย์สินทางปัญญาทั้งหมดอันเกิดจากหรือเกี่ยวเนื่องกับบริการ
          (นอกเหนือจากสิทธิในทรัพย์สินทางปัญญาในสิ่งใดที่ผู้ใช้บริการจัดหา) เป็นของบริษัท
        </Typography>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (8.2) ผู้ใช้บริการจะไม่
        </Typography>
        <div className="pl-4">
          <Typography variant="body3" color="darkMed" className="pb-1">
            (ก) อนุญาตช่วง โอนแก่บุคคลภายนอก หรือให้บุคคลภายนอกแสวงหาประโยชน์ในทางพาณิชย์ด้วยประการใด
            หรือทำให้บุคคลภายนอกเข้าถึงแพลตฟอร์มและ/หรือบริการไม่ว่าในทางใด
          </Typography>
          <Typography variant="body3" color="darkMed" className="pb-1">
            (ข) ทำซ้ำ ทำสำเนา ดัดแปลง หรือลอกเลียนแพลตฟอร์มหรือบริการและ/หรือซอฟต์แวร์บนเซิร์ฟเวอร์ (Server) หรือไวร์เลส
            (Wireless) หรืออุปกรณ์ที่ใช้อินเทอร์เน็ตเป็นพื้นฐาน หรือ
          </Typography>
          <Typography variant="body3" color="darkMed" className="pb-1">
            (ค) แยกส่วน แปลง ถอดแบบ ถอดรหัส หรือพยายามที่จะแยก Source Code ของหรือแบ่งแยกซอฟต์แวร์จากแพลตฟอร์มหรือระบบ
          </Typography>
        </div>
      </div>

      <Typography variant="title7" color="primary" className="pt-2 pb-3">
        9. การรักษาความลับ
      </Typography>
      <div className="pl-4">
        <Typography variant="body3" color="darkMed" className="pb-1">
          ภายใต้เงื่อนไขของกฎหมายที่ใช้บังคับหรือหน่วยงานราชการหรือหน่วยงานกำกับดูแล
          คู่สัญญาแต่ละฝ่ายตกลงเก็บรักษาข้อมูลที่มีลักษณะเป็นความลับซึ่งเปิดเผยโดยคู่สัญญาอีกฝ่ายหนึ่งไว้เป็นความลับอย่างเคร่งครัด
          ซึ่งรวมถึงข้อมูลใด ๆ ที่เกี่ยวข้องกับหรือในเรื่องของกิจการ บริการ ผลิตภัณฑ์ ลูกค้า ผู้จัดหา (Suppliers)
          หรือแผนการส่งเสริมการขายและการตลาดของคู่สัญญาอีกฝ่ายหนึ่งนั้น
          และจะไม่เปิดเผยข้อมูลนั้นให้แก่บุคคลใดโดยปราศจากความยินยอมล่วงหน้าเป็นลายลักษณ์อักษรจากคู่สัญญาอีกฝ่าย
          (เว้นแต่ เป็นการเปิดเผยแก่กรรมการ ลูกจ้าง หรือที่ปรึกษาเท่าที่จำเป็นต้องรู้ข้อมูลนั้น
          โดยที่บุคคลดังกล่าวผูกพันในการปฏิบัติหน้าที่ในการรักษาความลับตามที่ระบุไว้ในนี้ด้วย)
          หรือใช้ข้อมูลนั้นเพื่อวัตถุประสงค์อื่นใดนอกจากการปฏิบัติหน้าที่ตามบันทึกข้อตกลงฉบับนี้
        </Typography>
      </div>

      <Typography variant="title7" color="primary" className="pt-2 pb-3">
        10. การหยุด การระงับชั่วคราว และการยกเลิก
      </Typography>
      <div className="pl-4">
        <Typography variant="body3" color="darkMed" className="pb-1">
          (10.1)
          บริษัทขอสงวนสิทธิที่จะหยุดให้บริการได้ไม่ว่าทั้งหมดหรือแต่บางส่วนตามกำหนดระยะเวลาตามปกติหรือประการอื่นใดตามหนังสือบอกกล่าวล่วงหน้าหรือโดยปราศจากหนังสือบอกกล่าวล่วงหน้า
          รวมถึง
        </Typography>
        <div className="pl-4">
          <Typography variant="body3" color="darkMed" className="pb-1">
            (ก) การบำรุงรักษาและการซ่อมแซมเซิร์ฟเวอร์ (Server) เครือข่ายสัญญาณ (Network) ซอฟต์แวร์
            และ/หรือแพลตฟอร์มซึ่งจำเป็นสำหรับการจัดเตรียมการเข้าถึงบริการ และ
          </Typography>
          <Typography variant="body3" color="darkMed" className="pb-1">
            (ข) เหตุสุดวิสัย (ตามที่นิยามด้านล่างนี้)
          </Typography>
        </div>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (10.2)
          บริษัทขอสงวนสิทธิในการระงับชั่วคราวหรือยกเลิกบัญชีของผู้ใช้บริการและ/หรือการเข้าถึงบริการไม่ว่าทั้งหมดหรือบางส่วน
          เนื่องจากเหตุการณ์ดังต่อไปนี้
        </Typography>
        <div className="pl-4">
          <Typography variant="body3" color="darkMed" className="pb-1">
            (10.2.1) ผู้ใช้บริการไม่ปฏิบัติตามหรือผิดข้อกำหนดในข้อตกลงการให้บริการนี้
          </Typography>
          <Typography variant="body3" color="darkMed" className="pb-1">
            (10.2.2) ผู้ใช้บริการไม่ชำระเงินซึ่งครบกำหนดชำระภายใต้ข้อตกลงการให้บริการนี้
          </Typography>
          <Typography variant="body3" color="darkMed" className="pb-1">
            (10.2.3) ข้อมูลใด ๆ รวมถึงรายละเอียดบริการ สินค้า ผลิตภัณฑ์ หรือราคาที่ผู้ใช้บริการให้แก่บริษัท ผิดพลาด
            ไม่ถูกต้อง หรือบิดเบือน
          </Typography>
          <Typography variant="body3" color="darkMed" className="pb-1">
            (10.2.4) เหตุสุดวิสัย หรือ
          </Typography>
          <Typography variant="body3" color="darkMed" className="pb-1">
            (10.2.5)
            การให้บริการของบริษัทผิดกฎหมายหรือขัดต่อความสงบเรียบร้อยหรือศีลธรรมอันดีของประชาชนหรือบริษัทมีเหตุอันควรเชื่อได้ว่ามีการใช้บริการหรือการเข้าทำธุรกรรมใดด้วยระบบตอบรับอัตโนมัติหรือมีการใช้บริการในทางที่ผิดหรือในทางที่มิชอบ
          </Typography>
        </div>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (10.3)
          โดยไม่กระทบต่อสิทธิและหน้าที่ของคู่สัญญาแต่ละฝ่ายที่มีอยู่จนถึงเวลาที่ยกเลิกบริการและสิทธิและการเยียวยาอื่นใดที่มีอยู่ภายใต้ข้อตกลงการให้บริการ
          บริษัทอาจยกเลิกการให้บริการโดยมีผลทันทีโดยส่งหนังสือบอกกล่าวแก่ผู้ใช้บริการเมื่อเกิดเหตุการณ์อย่างใดอย่างหนึ่งดังต่อไปนี้
        </Typography>
        <div className="pl-4">
          <Typography variant="body3" color="darkMed" className="pb-1">
            (10.3.1)
            ผู้ใช้บริการกระทำการผิดข้อกำหนดที่เป็นสาระสำคัญหรือไม่ปฏิบัติหน้าที่อย่างใดอย่างหนึ่งที่กำหนดในข้อตกลงการให้บริการและ
            (หากเยียวยาแก้ไขได้) ไม่เยียวยาแก้ไขความผิดนั้นภายในระยะเวลา 14 วัน
          </Typography>
          <Typography variant="body3" color="darkMed" className="pb-1">
            (10.3.2) ผู้ใช้บริการเลิกกิจการ หรือมีคำสั่งให้เลิกกิจการ หรือล้มละลาย
          </Typography>
          <Typography variant="body3" color="darkMed" className="pb-1">
            (10.3.3)
            ผู้ใช้บริการตกเป็นผู้ล้มละลายหรือถือจะตกเป็นผู้ล้มละลายรือไม่สามารถชำระหนี้เมื่อถึงกำหนดชำระได้ภายใต้กฎหมายที่ใช้บังคับ
          </Typography>
          <Typography variant="body3" color="darkMed" className="pb-1">
            (10.3.4) เหตุสุดวิสัย หรือ
          </Typography>
          <Typography variant="body3" color="darkMed" className="pb-1">
            (10.3.5)
            มีการเปลี่ยนแปลงกฎหมายหรือกฎเกณฑ์ของหน่วยงานราชการซึ่งทำให้การปฏิบัติหน้าที่ตามข้อตกลงการให้บริการนี้ผิดกฎหมาย
          </Typography>
        </div>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (10.4)
          ผู้ใช้บริการอาจบอกเลิกหรือยกเลิกการใช้บริการส่วนใดได้ทุกเมื่อและตกลงและรับทราบว่าบริษัทมีสิทธิคิดค่าธรรมเนียมการให้บริการ
          ค่าธรรมเนียมเพิ่มเติม ค่าขนส่ง ค่าไปรษณีย์ ค่าธรรมเนียมและค่าใช้จ่ายอื่น ๆ
          หรือเงินหรือค่าตอบแทนที่ค้างชำระซึ่งเกิดขึ้นจากการใช้บริการของผู้ใช้บริการได้ (ซึ่งต่อไปนี้จะเรียกว่า
          “ค่าธรรมเนียม”)
          โดยหักจากบัตรเครดิตหรือบัตรเดบิตของผู้ใช้บริการจนกว่าการบอกเลิกหรือการยกเลิกนั้นจะมีผลใช้บังคับ
        </Typography>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (10.5) เพื่อหลีกเลี่ยงข้อสงสัย
          ผู้ใช้บริการตกลงและรับทราบว่าค่าธรรมเนียมซึ่งผู้ใช้บริการชำระให้แก่บริษัทเป็นค่าบริการไม่สามารถขอคืน
          แลกเปลี่ยน โอน หรือแลกคืนได้ไม่ว่าในกรณีใด ภายใต้ข้อจำกัดใดที่กำหนดโดยบทบัญญัติแห่งกฎหมาย
          นโยบายการไม่คืนค่าบริการนี้ให้มีผลบังคับใช้ได้ตลอดระยะเวลาไม่ว่าผู้ใช้บริการหรือบริษัทจะตัดสินใจบอกเลิกหรือยกเลิกการใช้บริการส่วนใดก็ตาม
        </Typography>
      </div>

      <Typography variant="title7" color="primary" className="pt-2 pb-3">
        11. เหตุสุดวิสัย
      </Typography>
      <div className="pl-4">
        <Typography variant="body3" color="darkMed" className="pb-1">
          (11.1) บริษัทมิได้ผิดข้อตกลงการให้บริการหรือรับผิดสำหรับ ความล่าช้าในการดำเนินการ
          หรือไม่สามารถปฏิบัติหน้าที่ใดหากความล่าช้าหรือความไม่สามารถดำเนินการดังกล่าวเป็นผลจากเหตุการณ์ พฤติการณ์
          หรือเหตุอันใดที่นอกเหนือการควบคุมตามสมควรของบริษัทฯ รวมถึงความชำรุดเสียหายของคอมพิวเตอร์ เซิร์ฟเวอร์ (Server)
          หรืออุปกรณ์ หรือความล้มเหลวของบริการอินเทอร์เน็ต เว็บไซต์ แพลตฟอร์ม
          หรือแพลตฟอร์มของบุคคลภายนอกไม่ว่าด้วยเหตุใด รวมถึงกรณีการถูกโจมตีจากแฮ็คเกอร์ กรณีการถูกไวรัสคอมพิวเตอร์
          กรณีมีผลกระทบอย่างมีนัยสำคัญที่เกิดขึ้นโดยการเปลี่ยนแปลงของเทคโนโลยีด้านโทรคมนาคม
          หรือกรณีมีคำสั่งของหน่วยงานราชการหรือกฎหมาย (“เหตุสุดวิสัย”)
        </Typography>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (11.2) เมื่อเกิดเหตุสุดวิสัยขึ้น บริษัทอาจระงับบริการหรือให้บริการล่าช้า
          ไม่ว่าบางส่วนหรือทั้งหมดแก่ผู้ใช้บริการในระหว่างที่เหตุการณ์หรือพฤติการณ์ดังกล่าวยังคงอยู่
          และตกลงจะใช้ความพยายามที่เหมาะสมในทางพาณิชย์เพื่อดำเนินการลดผลกระทบจากการระงับหรือความล่าช้าดังกล่าวซึ่งในกรณีใดก็ตาม
          ผู้ใช้บริการตกลงและรับทราบว่าบริษัท ไม่ต้องรับผิดหรือมีหน้าที่ใด ๆ
          ต่อผู้ใช้บริการสำหรับการระงับหรือความล่าช้าดังกล่าว
        </Typography>
      </div>

      <Typography variant="title7" color="primary" className="pt-2 pb-3">
        12. การแบ่งแยกได้ของข้อกำหนด
      </Typography>
      <div className="pl-4">
        <Typography variant="body3" color="darkMed" className="pb-1">
          ข้อกำหนดใดไม่ชอบด้วยกฎหมาย ตกเป็นโมฆะ หรือมิอาจใช้บังคับได้
          ให้ข้อกำหนดข้อนั้นไม่มีผลบังคับเพียงเท่าที่ไม่สามารถบังคับใช้ได้โดยไม่กระทบความสมบูรณ์ของข้อกำหนดอื่นใดของข้อตกลงการให้บริการนี้
          นอกจากนี้ ความไม่ชอบด้วยกฎหมาย
          การตกเป็นโมฆะหรือการใช้บังคับมิได้ดังกล่าวไม่มีทำให้เป็นโมฆะซึ่งข้อกำหนดดังกล่าวหรือข้อกำหนดอื่น ๆ
          ในเขตอำนาจทางกฎหมายอื่น
        </Typography>
      </div>

      <Typography variant="title7" color="primary" className="pt-2 pb-3">
        13. กฎหมายที่ใช้บังคับ
      </Typography>
      <Typography variant="body3" color="darkMed" className="pb-1 pl-4">
        ขอบเขตการให้บริการ ข้อตกลงการให้บริการฉบับนี้ และประเด็นที่เกี่ยวกับความสมบูรณ์ การตีความ
        และการบังคับใช้ใช้บังคับตามกฎหมายแห่งประเทศไทย
      </Typography>

      <Typography variant="title7" color="primary" className="pt-2 pb-3">
        14. การแก้ไขเพิ่มเติม
      </Typography>
      <div className="pl-4">
        <Typography variant="body3" color="darkMed" className="pb-1">
          (14.1) ผู้ใช้บริการตกลงและรับทราบว่าบริษัทอาจเพิ่มเติม
          เปลี่ยนแปลงหรือแก้ไขข้อกำหนดใดที่ระบุในข้อตกลงการให้บริการนี้ได้ทุกเมื่อตามที่บริษัทเห็นสมควรและผู้ใช้บริการตกลงที่จะผูกพันตามข้อตกลงที่เพิ่มเติม
          เปลี่ยนแปลงหรือแก้ไขนั้นทุกประการ
        </Typography>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (14.2) หากมีการเพิ่มเติม การเปลี่ยนแปลง หรือการแก้ไขเป็นผล
        </Typography>
        <div className="pl-4">
          <Typography variant="body3" color="darkMed" className="pb-1">
            (ก) ก่อให้เกิดภาระหน้าที่เพิ่มขึ้น และ/หรือ
          </Typography>
          <Typography variant="body3" color="darkMed" className="pb-1">
            (ข) ก่อให้เกิดภาระค่าใช้จ่ายหรือความรับผิดเพิ่มขึ้น
            และบริษัทไม่ได้รับคำคัดค้านจากผู้ใช้บริการเป็นลายลักษณ์อักษรภายใน 15
            วันนับจากวันที่ระบุบนเว็บไซต์และ/หรือการแจ้งเป็นลายลักษณ์อักษรของบริษัทให้ถือว่าผู้ใช้บริการตกลงและยอมรับการเพิ่มเติม
            การเปลี่ยนแปลงหรือการแก้ไขดังกล่าวแล้ว
          </Typography>
        </div>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (14.3) การใช้บริการอย่างต่อเนื่องของผู้ใช้บริการภายต่อไปหลังจากการประกาศหรือการแจ้งแก้ไข เปลี่ยนแปลง
          หรือเพิ่มเติมไม่ว่าผู้ใช้บริการจะพิจารณาแล้วหรือไม่ถือว่าผู้ใช้บริการยอมรับและตกลงที่จะผูกพันตามการเพิ่มเติม
          การเปลี่ยนแปลง หรือการแก้ไขนั้นแล้ว
        </Typography>
      </div>
    </Grid>
  );
};

export default TermsOfService;

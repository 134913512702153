import React, { FC, useContext } from "react";
import { useParams, Switch, Redirect, Route, useHistory } from "react-router-dom";
import {
  LayoutDefault,
  PageProductsInventoryProducts,
  PageProductsInventoryNewProducts,
  PageProductsInventoryEditProducts,
  FormNewProductProps,
  FormEditProductProps,
} from "@leanbizai/deeple-ui";
import { Pages } from "@leanbizai/web-console";

import {
  PROJECT,
  PRODUCTS_INVENTORY,
  PRODUCTS,
  INVENTORY,
  CATEGORIES,
  CONTENT_MANAGEMENT,
  HIGHLIGHT_PRODUCT,
} from "constants/Router";
import { Products } from "domain/Product";
import ContentManagement from "domain/Product/DigitalContentManagement";
import ProductInventory from "domain/ProductInventory";
import HighlightProduct from "domain/Product/HighlightProduct";
import Category from "domain/Category";
import useGetUserWithPermission from "utils/hooks/useGetUserWithPermission";
import useTabWithLink from "utils/hooks/useTabWithLink";
import { ProjectIdType } from "types/Project";

import PrivateRoute from "routers/PrivateRoute";
import { NotFound } from "pages/NotFound";
import { getProductTabConfig } from "utils/route";
import { notifyError, notifySuccess } from "utils/notify";
import { GuidelineContext } from "utils/context";

const ProductsSubRoute: FC<{ isDigitalContentEnabled: boolean }> = ({ isDigitalContentEnabled }) => {
  const { projectId = "" } = useParams<ProjectIdType>();
  const { loading, isAdmin } = useGetUserWithPermission(projectId);
  const { handleFetchGuideline, showBroadcastBar } = useContext(GuidelineContext);
  const tabConfigs = getProductTabConfig(isDigitalContentEnabled);
  const { rootPath, defaultTabPath } = useTabWithLink(tabConfigs, PRODUCTS_INVENTORY, projectId);

  const { push } = useHistory();
  return (
    <LayoutDefault projectId={projectId} navigate={push} notifyError={notifyError} notifySuccess={notifySuccess}>
      <Switch>
        <PrivateRoute
          exact
          loading={loading}
          path={`${rootPath}/${CATEGORIES}`}
          isAllowed={isAdmin}
          redirectTo="/"
          component={Category}
        />

        <PrivateRoute exact loading={loading} path={`${rootPath}/${PRODUCTS}`} isAllowed={isAdmin} redirectTo="/">
          <Products newAddProductCard={<PageProductsInventoryProducts />} />
        </PrivateRoute>

        <PrivateRoute exact loading={loading} path={`${rootPath}/${PRODUCTS}/new`} isAllowed={isAdmin} redirectTo="/">
          <PageProductsInventoryNewProducts
            onBeforeCloseModalAddProductSuccessfully={async () => {
              await handleFetchGuideline();
              showBroadcastBar();
            }}
            render={(props: FormNewProductProps) => <Pages.FormProduct {...props} />}
          />
        </PrivateRoute>

        <PrivateRoute
          exact
          loading={loading}
          path={`${rootPath}/${PRODUCTS}/:productId/edit`}
          isAllowed={isAdmin}
          redirectTo="/"
        >
          <PageProductsInventoryEditProducts
            render={(props: FormNewProductProps) => <Pages.FormProduct {...props} />}
          />
        </PrivateRoute>

        <PrivateRoute
          exact
          loading={loading}
          path={`${rootPath}/${INVENTORY}`}
          isAllowed={isAdmin}
          redirectTo="/"
          component={ProductInventory}
        />

        {isDigitalContentEnabled && (
          <PrivateRoute
            exact
            loading={loading}
            path={`${rootPath}/${CONTENT_MANAGEMENT}`}
            isAllowed={isAdmin}
            redirectTo="/"
            component={ContentManagement}
          />
        )}

        <PrivateRoute
          exact
          loading={loading}
          path={`${rootPath}/${HIGHLIGHT_PRODUCT}`}
          isAllowed={isAdmin}
          redirectTo="/"
          component={HighlightProduct}
        />

        <PrivateRoute exact loading={loading} path={`${rootPath}`} isAllowed={isAdmin} redirectTo="/">
          <Redirect
            to={defaultTabPath ? `/${PROJECT}/${projectId}/${PRODUCTS_INVENTORY}/${defaultTabPath}` : `/${PROJECT}`}
          />
        </PrivateRoute>

        <Route path={`${rootPath}/*`}>
          <NotFound />
        </Route>
      </Switch>
    </LayoutDefault>
  );
};

export default ProductsSubRoute;

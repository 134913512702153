const stat = {
  en: {
    "dashboard.menu": "Dashboard",
    "dashboard.header": "Overview",
    "stat.dateFilter.label.day": "Today",
    "stat.dateFilter.label.yesterday": "Yesterday",
    "stat.dateFilter.label.week": "Last 7 days",
    "stat.dateFilter.label.month": "Last 30 days",
    "statWidget.title.income": "Income",
    "statWidget.title.orders": "Orders",
    "statWidget.title.customers": "Customers",
    "statWidget.title.orderValue": "Average Orders Value",
    "statWidget.title.latestOrder": "Latest orders",
    "statWidget.title.topSelling": "Top 5 products",
    "graphStat.legend.label.totalSales": "Total sales",
    "graphStat.legend.label.orders": "Orders",
    "graphStat.legend.label.customers": "Customers",
    "graphStat.legend.label.orderCreatedTime": "Time of order created",
    "graphStat.legend.label.orderPaidTime": "Time of order paid",
    "statWidget.title.statistics": "Statistics",
    "dashboard.dateTime.label.0": "00:00 - {{endTime}} (now)",
    "dashboard.dateTime.label.1": "{{startTime}} - {{endTime}}",
    "dashboard.dateTime.label.7": "{{startTime}}  - {{endTime}}",
    "dashboard.dateTime.label.30": "{{startTime}} - {{endTime}}",
    "dashboard.dateTime.label.-1": "{{startTime}} - {{endTime}}",
    "dashboard.calendar.apply": "Submit",
    "dashboard.calendar.header": "Date and time range",
    "dashboard.calendar.error.limit": "Start time has to be different more than 1 minute from end time",
    carrierName: "Carrier name",
    orderState: "Order state",
    shippingMethod: "Shipping method",
    paymentMethod: "Payment method",
    platform: "Platform",
    productName: "Product name",
    customerName: "Customer name",
    paymentBankName: "Payment bank name",
    shippingDescription: "Shipping description",
    shippingAddress: "Shipping address",
    grandTotal: "Grand total",
    shippingDiscount: "Shipping discount",
    productDiscount: "Product discount",
    dateRange: "Date range",
    AddFilter: "Add filter",
    BANK_QR_CODE: "QR code",
    BANK_TRANSFER: "Bank Transfer",
    BILL_PAYMENT_CEN_PAY: "Cen Pay",
    CASH_ON_DELIVERY: "Cash on Delivery",
    DEEPLE_PAY: "LeanBiz pay",
    CHILL_PAY_OTHER_METHOD: "Chill Pay - Others",
    CREDIT_CARD_K_BANK: "Kasikorn bank credit card",
    ELECTRONIC_PAYMENT_ALI_PAY: "Ali Pay",
    ELECTRONIC_PAYMENT_BLUE_PAY: "Blue Pay",
    ELECTRONIC_PAYMENT_LINE_PAY: "Line Pay",
    ELECTRONIC_PAYMENT_WE_CHAT_PAY: "WeChat Pay",
    INSTALLMENT_K_BANK: "Instalment with Kasikorn Bank",
    INSTALLMENT_T_BANK: "Instalment with Thanachart Bank",
    INTERNET_BANKING_BAY: "Krungsri Bank Internet Banking",
    INTERNET_BANKING_BBL: "Bangkok Bank Internet Banking",
    INTERNET_BANKING_KTB: "KrungThai Bank Internet Banking",
    INTERNET_BANKING_SCB: "SCB Bank Internet Banking",
    INTERNET_BANKING_T_BANK: "Thanachart Bank Internet Banking",
    PAY_PLUS_K_BANK: "K Plus",
    PROMPT_PAY: "Prompt Pay",
    productSKUCode: "Product SKU Code",
    price: "Product price",
    total: "Total price",
    categoryId: "Category",
    platformLabelAll: "All",
    platformLabelOnline: "Online",
    platformLabelOffline: "Offline",
    platformLabelPlatform: "Platform",
  },
  th: {
    "dashboard.menu": "รายงานการขาย",
    "dashboard.header": "ภาพรวม",
    "stat.dateFilter.label.day": "วันนี้",
    "stat.dateFilter.label.yesterday": "เมื่อวาน",
    "stat.dateFilter.label.week": "7 วันล่าสุด",
    "stat.dateFilter.label.month": "30 วันล่าสุด",
    "statWidget.title.income": "รายได้",
    "statWidget.title.orders": "รายการสั่งซื้อ",
    "statWidget.title.customers": "ลูกค้า",
    "statWidget.title.orderValue": "มูลค่าเฉลี่ยต่อการสั่งซื้อ",
    "statWidget.title.latestOrder": "รายการสั่งซื้อล่าสุด",
    "statWidget.title.topSelling": "สินค้ายอดนิยม 5 อันดับแรก",
    "graphStat.legend.label.totalSales": "ยอดขายรวม",
    "graphStat.legend.label.orders": "รายการสั่งซื้อ",
    "graphStat.legend.label.customers": "ลูกค้า",
    "graphStat.legend.label.orderCreatedTime": "เวลาสั่งซื้อ",
    "graphStat.legend.label.orderPaidTime": "เวลาชำระสินค้า",
    "statWidget.title.statistics": "สถิติ",
    "dashboard.dateTime.label.0": "00:00 - {{endTime}} (ปัจจุบัน)",
    "dashboard.dateTime.label.1": "{{startTime}} - {{endTime}}",
    "dashboard.dateTime.label.7": "{{startTime}}  - {{endTime}}",
    "dashboard.dateTime.label.30": "{{startTime}} - {{endTime}}",
    "dashboard.dateTime.label.-1": "{{startTime}} - {{endTime}}",
    "dashboard.calendar.apply": "ยืนยัน",
    "dashboard.calendar.header": "ช่วงวันที่และเวลา",
    "dashboard.calendar.error.limit": "เวลาเริ่มต้นต้องน้อยกว่าเวลาสิ้นสุดอย่างน้อย 1 นาที",
    carrierName: "ผู้ให้บริการจัดส่ง",
    orderState: "สถานะการสั่งซื้อ",
    shippingMethod: "วิธีการจัดส่ง",
    paymentMethod: "วิธีการชำระเงิน",
    platform: "ช่องทางแชท",
    productName: "ชื่อสินค้า",
    customerName: "ชื่อลูกค้า",
    paymentBankName: "ชื่อธนาคาร",
    shippingDescription: "ชื่อผู้ให้บริการจัดส่ง",
    shippingAddress: "ที่อยู่จัดส่ง",
    grandTotal: "ราคารวมสุทธิ",
    shippingDiscount: "ส่วนลดค่าจัดส่ง",
    productDiscount: "ส่วนลดสินค้า",
    dateRange: "ช่วงระยะเวลา",
    AddFilter: "เพิ่มตัวกรอง",
    BANK_QR_CODE: "ชำระผ่าน QR code",
    BANK_TRANSFER: "โอนเงินธนาคาร",
    BILL_PAYMENT_CEN_PAY: "ชำระผ่าน Cen Pay",
    CASH_ON_DELIVERY: "เก็บเงินปลายทาง",
    DEEPLE_PAY: "LeanBiz pay",
    CHILL_PAY_OTHER_METHOD: "ช่องทางอื่นของ ChillPay",
    CREDIT_CARD_K_BANK: "บัตรเครดิตกสิกร",
    ELECTRONIC_PAYMENT_ALI_PAY: "อาลีเพย์",
    ELECTRONIC_PAYMENT_BLUE_PAY: "บลูเพย์",
    ELECTRONIC_PAYMENT_LINE_PAY: "ไลน์เพย์",
    ELECTRONIC_PAYMENT_WE_CHAT_PAY: "วีแชทเพย์",
    INSTALLMENT_K_BANK: "ผ่อนกับ ธ.กสิกรไทย",
    INSTALLMENT_T_BANK: "ผ่อนกับ ธ.ธนชาติ",
    INTERNET_BANKING_BAY: "อินเตอร์เน็ตแบงค์กิ้ง ธ.กรุงศรีอยุธยา",
    INTERNET_BANKING_BBL: "อินเตอร์เน็ตแบงค์กิ้ง ธ.กรุงเทพ",
    INTERNET_BANKING_KTB: "อินเตอร์เน็ตแบงค์กิ้ง ธ.กรุงไทย",
    INTERNET_BANKING_SCB: "อินเตอร์เน็ตแบงค์กิ้ง ธ.ไทยพาณิชย์",
    INTERNET_BANKING_T_BANK: "อินเตอร์เน็ตแบงค์กิ้ง ธ.ธนชาติ",
    PAY_PLUS_K_BANK: "ผ่านเคพลัส",
    PROMPT_PAY: "พร้อมเพย์",
    productSKUCode: "รหัสสินค้า",
    price: "ราคาสินค้า",
    total: "ราคารวม",
    categoryId: "หมวดหมู่สินค้า",
    platformLabelAll: "ทั้งหมด",
    platformLabelOnline: "ออนไลน์",
    platformLabelOffline: "ออฟไลน์",
    platformLabelPlatform: "ช่องทาง",
  },
};

export default stat;

import React, { FC } from "react";

import Grid from "components/Grid";
import Typography from "components/Typography";

const PrivacyPolicy: FC = () => {
  return (
    <Grid item xs={12}>
      <Grid container justify="center" className="pt-3 pb-4">
        <Typography variant="title2" color="primary">
          นโยบายความเป็นส่วนตัว
        </Typography>
      </Grid>
      <Typography variant="body3" color="darkMed" className="pb-4">
        นโยบายความเป็นส่วนตัวฉบับนี้เกิดขึ้นระหว่าง บริษัท ลีนบิซ จํากัด ซึ่งมีสำนักงานใหญ่ตั้งอยู่เลขที่ 69/429 หมู่ที่
        2 ตำบลวิชิต อำเภอเมืองภูเก็ต จังหวัดภูเก็ต (ซึ่งต่อไปนี้จะเรียกว่า “บริษัท” หรือ “LeanBiz”)
        และระหว่างผู้ใช้บริการระบบ LeanBiz
      </Typography>
      <Typography variant="body3" color="darkMed">
        LeanBiz กำหนดข้อมูลที่เก็บรวบรวมและวิธีนำไปใช้ และมีผลบังคับใช้เมื่อผู้ใช้บริการสมัครใช้บริการระบบ
        นโยบายคุ้มครองข้อมูลส่วนบุคคลนี้มีผลเพื่อบังคับใช้กับการลงทะเบียนสมัครบริการ การสมัครสมาชิก และบริการระบบ
        การเข้าถึงรวมถึงการใช้เนื้อหาต่าง ๆ
        ตลอดจนระบบและบริการที่เกี่ยวข้องกับการให้บริการของบริษัททั้งที่มีอยู่ในปัจจุบัน
        และที่บริษัทจะได้พัฒนาหรือจัดให้มีขึ้นในอนาคต ทั้งนี้
        ผู้ใช้บริการสามารถศึกษาเงื่อนไขการให้บริการได้ที่เว็บไซต์ของบริษัท (
        <a href="https://console.leanbiz.ai">https://console.leanbiz.ai</a>)
      </Typography>
      <Typography variant="title7" color="primary" className="pt-2 pb-3">
        1. วัตถุประสงค์
      </Typography>
      <div className="pl-4">
        <Typography variant="body3" color="darkMed" className="pb-1">
          (1.1)
          เพื่อให้มีหลักเกณฑ์การคุ้มครองสิทธิของผู้ใช้บริการเกี่ยวกับข้อมูลให้สอดคล้องกับแนวทางการคุ้มครองข้อมูลส่วนบุคคล
        </Typography>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (1.2) ดำรงไว้ซึ่งความเชื่อมั่นและสร้างความเชื่อมั่นของผู้ใช้บริการในการใช้บริการของ LeanBiz
        </Typography>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (1.3)
          เพื่อปกป้องสิทธิส่วนบุคคลและความเป็นส่วนตัวของผู้ใช้บริการรวมถึงสร้างความมั่นใจในการเปิดเผยข้อมูลส่วนบุคคลกับทาง
          LeanBiz
        </Typography>
      </div>
      <Typography variant="title7" color="primary" className="pt-2 pb-3">
        2. ข้อมูลส่วนตัวที่เก็บรวบรวม
      </Typography>
      <div className="pl-4">
        <Typography variant="body3" color="darkMed" className="pb-1">
          (2.1) การเก็บรวบรวมและดำเนินการข้อมูลส่วนบุคคลใดๆ ต่อไปนี้หรือทั้งหมดที่เกี่ยวกับผู้ใช้บริการ ได้แก่
        </Typography>
        <div className="pl-4">
          <Typography variant="body3" color="darkMed" className="pb-1">
            (ก) ข้อมูลเกี่ยวกับตัวผู้ใช้บริการ - ข้อมูลส่วนบุคคลของผู้ใช้บริการ เช่น ชื่อ นามสกุล บัตรประจำตัวประชาชน
            หมายเลขโทรศัพท์ สมุดบัญชี รหัสเงินฝากธนาคารย่อย และรูปถ่ายสินค้าของผู้ใช้บริการ
          </Typography>
          <Typography variant="body3" color="darkMed" className="pb-1">
            (ข) ข้อมูลรายละเอียดการชำระเงินของผู้ใช้บริการ -
            รายละเอียดการชำระเงินที่ผู้ใช้บริการชำระผ่านระบบธุรกรรมออนไลน์ เช่น หมายเลขข้อมูลรหัสธนาคาร
            และข้อมูลการส่งใบเรียกเก็บยอดชำระ
          </Typography>
        </div>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (2.2) บริษัทเก็บรวบรวมข้อมูลส่วนบุคคลจากผู้ใช้บริการของบริษัทและเป็นไปตามวัตถุประสงค์ตามกฎหมาย
        </Typography>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (2.3) การรักษาความปลอดภัยของข้อมูลส่วนตัวของผู้ใช้บริการ โดยทางบริษัทจะรวบรวม รักษา
          และประมวลผลข้อมูลส่วนบุคคลของผู้ใช้บริการ เมื่อได้รับความยินยอมจากผู้ใช้บริการ
        </Typography>
        <Typography variant="body3" color="darkMed" className="pb-1">
          (2.4) ข้อมูลที่เกิดขึ้นในระบบ ผู้ใช้บริการและ LeanBiz มีสิทธิเป็นเจ้าของร่วมกัน
        </Typography>
      </div>
      <Typography variant="title7" color="primary" className="pt-2 pb-3">
        3. วิธีการเก็บรวบรวมข้อมูลส่วนบุคคล
      </Typography>
      <div className="pl-4">
        <Typography variant="body3" color="darkMed" className="pb-1">
          ทางบริษัทเก็บรวบรวมข้อมูลส่วนบุคคลเท่าที่จำเป็นโดยตรงจากผู้ใช้บริการเพื่อการให้บริการของบริษัท ผ่านแพลตฟอร์ม
          Facebook, Line Official Account, Instagram และอื่น ๆ
          ที่จะเพิ่มเข้ามาในอนาคตหรือที่บริษัทเห็นสมควรเพื่อประโยชน์ต่อผู้ใช้บริการ
        </Typography>
        <div className="pl-4">
          <Typography variant="body3" color="darkMed" className="pb-1">
            แต่การเก็บรวบรวมนั้นจะไม่จำกัดเพียงแค่
          </Typography>
          <Typography variant="body3" color="darkMed" className="pb-1">
            - ชื่อ
          </Typography>
          <Typography variant="body3" color="darkMed" className="pb-1">
            - ที่อยู่จดหมายอิเล็กทรอนิกส์ (e-mail)
          </Typography>
          <Typography variant="body3" color="darkMed" className="pb-1">
            - หมายเลขข้อมูลบัญชีธนาคารสำหรับเรียกเก็บยอดชำระ หรือ ข้อมูลยอดการชำระ
          </Typography>
          <Typography variant="body3" color="darkMed" className="pb-1">
            - หมายเลขติดต่อ
          </Typography>
          <Typography variant="body3" color="darkMed" className="pb-1">
            - หมายเลขบัตรประชาชน
          </Typography>
          <Typography variant="body3" color="darkMed" className="pb-1">
            - รูปภาพ เสียง หรือวิดีโอบันทึก ที่ผู้ใช้บริการได้เปิดเผยให้กับทางบริษัท
          </Typography>
          <Typography variant="body3" color="darkMed" className="pb-1">
            - ข้อมูลที่แสดงตัวตนอย่างถูกต้องตามกฎหมาย
          </Typography>
          <Typography variant="body3" color="darkMed" className="pb-1">
            - ข้อมูลการเข้าใช้งานในระบบของบริษัท
          </Typography>
          <Typography variant="body3" color="darkMed" className="pb-1">
            - ข้อมูลตำแหน่ง
          </Typography>
          <Typography variant="body3" color="darkMed" className="pb-1">
            - ข้อมูลส่วนบุคคลที่ผู้ใช้บริการอาจมีการให้แก่บริษัทผ่านทางระบบ
          </Typography>
        </div>
        <Typography variant="body3" color="darkMed" className="pb-1">
          นอกจากนี้ในกรณีที่ผู้บริการลงทะเบียนเข้าใช้บริการของระบบ LeanBiz
          บริษัทอาจเข้าถึงข้อมูลของผู้ใช้บริการที่ได้ให้ไว้แก่ทางบริษัทด้วยความสมัครใจภายใต้นโยบายความเป็นส่วนตัวของผู้ใช้บริการ
          ซึ่งบริษัทจะจัดการและใช้ข้อมูลส่วนบุคคลภายใต้เงื่อนไขที่กำหนดไว้ในนโยบายความเป็นส่วนตัวฉบับนี้เสมอ
        </Typography>
      </div>

      <Typography variant="title7" color="primary" className="pt-2 pb-3">
        4. การเก็บรักษาข้อมูลส่วนบุคคลและการใช้ข้อมูลส่วนบุคคล
      </Typography>
      <div className="pl-4">
        <Typography variant="body3" color="darkMed" className="pb-1">
          บริษัทปฏิบัติตามนโยบายความเป็นส่วนตัวอย่างมีมาตรฐานและได้นำมาตรการด้านความปลอดภัยมาใช้ด้วยความพยายามอย่างดีที่สุด
          เพื่อให้แน่ใจว่าข้อมูลส่วนตัวของผู้ใช้บริการมีความปลอดภัย
          ข้อมูลส่วนตัวของผู้ใช้บริการจะถูกเก็บไว้ในเครือข่ายที่มีความปลอดภัยและสามารถเข้าถึงได้โดยบุคลากรภายในองค์กรบางส่วนที่มีจำนวนจำกัดเท่านั้น
          ผู้ใช้บริการมีหน้าที่รับผิดชอบในการรักษาความลับของข้อมูลใด ๆ ที่บริษัทส่งถึงผู้ใช้บริการ
          และในการปฏิบัติตามขั้นตอนด้านความปลอดภัยต่าง ๆ ที่เราได้แจ้งให้ผู้ใช้บริการทราบ
          โดยเฉพาะรหัสผ่านที่ทำให้ผู้ใช้บริการสามารถเข้าระบบของบริษัท
          เราขอให้ผู้ใช้บริการไม่เปิดเผยรหัสผ่านนี้แก่ผู้ใดก็ตาม
          มิฉะนั้นเราไม่สามารถรับประกันความปลอดภัยของข้อมูลส่วนบุคคลของผู้ใช้บริการได้อย่างสมบูรณ์
        </Typography>
      </div>
      <Typography variant="title7" color="primary" className="pt-2 pb-3">
        5. การเปิดเผยข้อมูลส่วนบุคคล
      </Typography>
      <div className="pl-4">
        <Typography variant="body3" color="darkMed" className="pb-1">
          บริษัทจะไม่เปิดเผยข้อมูลดังกล่าวต่อบุคคลใดโดยปราศจากอนุญาตจากผู้ใช้บริการ
          อย่างไรก็ตามเพื่อประโยชน์สูงสุดของผู้ใช้บริการและการให้บริการของบริษัท
          ทั้งนี้ผู้ใช้บริการจะต้องรับทราบและยินยอมว่าบริษัทอาจมีการเปิดเผยข้อมูลส่วนบุคคลของผู้ใช้บริการสู่บุคคลที่สามหรือเพื่อนำข้อมูลส่วนบุคคลของท่านไปใช้ประโยชน์ในการเพิ่มประสิทธิภาพการให้บริการมากยิ่งขึ้น
          หรือเพื่อปรับปรุงและพัฒนารูปแบบการให้บริการและการเข้าถึงเนื้อหาต่างๆรวมถึงเพื่อประโยชน์ในการดำเนินการของบริษัท
          โดยในการเปิดเผยข้อมูลส่วนตัวของผู้ใช้บริการดังกล่าวนี้
          บริษัทจะดำเนินการให้บุคคลเหล่านั้นเก็บรักษาข้อมูลส่วนบุคคลของผู้ใช้บริการไว้เป็นความลับอย่างถึงที่สุด
          และไม่นำไปใช้เพื่อวัตถุประสงค์อื่นนอกเหนือจากขอบเขตที่บริษัทกำหนดไว้
        </Typography>
      </div>

      <Typography variant="title7" color="primary" className="pt-2 pb-3">
        6. การตลาดหรือการส่งเสริมการขาย
      </Typography>
      <div className="pl-4">
        <Typography variant="body3" color="darkMed" className="pb-1">
          บริษัทอาจนำข้อมูลส่วนบุคคลของผู้ใช้บริการไปใช้เพื่อวิเคราะห์ความต้องการของผู้ใช้บริการใหม่ในอนาคตของทางบริษัทและแนวโน้มของตลาด
          รวมทั้งเพื่อให้ได้รับข้อมูลเชิงลึก ซึ่งทางบริษัทอาจนำไปใช้เพื่อปรับเปลี่ยน ปรับปรุง แก้ไข และพัฒนาระบบ
          การดำเนินการนี้อาจทำให้ทางบริษัทต้องนำข้อมูลส่วนบุคคลที่บริษัทเก็บรักษาไว้เกี่ยวกับการที่ผู้ใช้บริการได้ใช้บริการต่างๆ
          ของระบบ ไปรวมกับข้อมูลที่ทางบริษัทได้เก็บรวบรวมเกี่ยวกับการใช้งานในระบบของผู้ใช้บริการ
          และทางบริษัทจะนำไปเก็บรวบรวมเกี่ยวกับผู้ใช้บริการท่านอื่น ๆ
          เพื่อให้ได้มาซึ่งข้อมูลเชิงลึกและทราบแนวโน้มของตลาด
          โดยทางบริษัทอาจให้ข้อมูลเชิงลึกเหล่านี้แก่พันธมิตรที่เป็นบุคคลภายในองค์กรเพื่อนำมาซึ่งกระบวนการในการวิเคราะห์เพื่อจุดประสงค์ในการส่งเสริมโปรโมชั่น
          ทั้งนี้เพื่อส่งเสริมกิจกรรมทางการตลาดดังกล่าวนี้เราจะแจ้งบอกกล่าวสื่อสารในวิธีการและรูปแบบต่างๆ
          เกี่ยวกับการทำการตลาด และ ข้อมูลและสื่อเกี่ยวกับกิจกรรมส่งเสริมการตลาดที่เกี่ยวข้องกับระบบและการบริการ
          อาจจำหน่าย ทำการตลาด หรือส่งเสริมการขาย
          ซึ่งผู้ใช้บริการสามารถยกเลิกการรับข้อมูลโฆษณาทางการตลาดได้ตลอดเวลาโดยการแจ้งมายังบริษัท
          ที่จัดให้มีในสื่อการตลอดอิเล็กทรอนิกส์ทั้งในแพลตฟอร์ม Facebook และแพลตฟอร์ม Line Official Account
          หรืออื่นๆตามบริษัทเห็นสมควรโดยบริษัทอาจใช้ข้อมูลสำหรับติดต่อของผู้ใช้บริการ หรือสื่อการตลาดจากเรา
          หรือบริษัทที่เกี่ยวข้อง กฎหมายคุ้มครองข้อมูลส่วนบุคคล
        </Typography>
      </div>

      <Typography variant="title7" color="primary" className="pt-2 pb-3">
        7. มาตรการในการรักษาความปลอดภัย
      </Typography>
      <div className="pl-4">
        <Typography variant="body3" color="darkMed" className="pb-1">
          จัดเก็บข้อมูลส่วนบุคคลของลูกค้าและจำกัดสิทธิ์การเข้าถึงข้อมูลเฉพาะผู้ที่ได้รับอนุญาตเท่านั้น โดยทางบริษัท
          ได้นำมาตรการด้านความปลอดภัยที่เหมาะสมกับความเสี่ยงที่เกิดขึ้น ตามการพัฒนาทางเทคโนโลยี และ
          จะมีเพียงเฉพาะฝ่ายพัฒนาระบบของทางบริษัทที่จะสามารถเข้าถึงข้อมูลส่วนตัวของผู้ใช้บริการได้เท่านั้น
          หากผู้ใช้มีความประสงค์ให้ทางบริษัทลบข้อมูลส่วนตัวของผู้ใช้บริการตามนโยบายการรักษาข้อมูลส่วนบุคคลของผู้ใช้บริการออกนั้น
          สามารถแจ้งทางบริษัทได้ทันทีทางอีเมล <a href="mailto: contact@leanbiz.ai">contact@leanbiz.ai</a>{" "}
          หลังจากบริษัทได้รับเรื่องแล้วจะดำเนินการให้ภายใน 60 วัน
        </Typography>
      </div>
    </Grid>
  );
};

export default PrivacyPolicy;

import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import Button from "components/Button";
import Grid from "components/Grid";
import Typography from "components/Typography";
import Divider from "components/Divider";

import { RadioBox } from "components/Radio";
import { AddonType } from "types/Addon";
import { convertPriceFormat } from "utils/common";

type ImageSearchItemListPropsType = {
  addons: AddonType[];
  selectedImageAddon?: AddonType;
  handleChangeImageSearchAddon: (selectedAddon?: AddonType) => void;
};

const ImageSearchItemList: FC<ImageSearchItemListPropsType> = (props) => {
  const { t } = useTranslation();
  const { addons: addonImageSearch, selectedImageAddon, handleChangeImageSearchAddon } = props;

  const [lastItem] = addonImageSearch.slice(-1);
  const overQuota = `${convertPriceFormat(lastItem ? lastItem.imageSearchQuota : 0, 0)}+`;
  const extraPrice = selectedImageAddon ? selectedImageAddon.extraChargeRate : 0;

  return (
    <Grid container>
      <Grid item xs={12}>
        <RadioBox
          title={t("Disabled")}
          description={t("addon.imageSearch.disabled.description")}
          isActive={!selectedImageAddon}
          onClick={() => {
            handleChangeImageSearchAddon(undefined);
          }}
          rtl
        />
      </Grid>

      {addonImageSearch.map((addonItem: AddonType) => {
        const { id, pricePerMonth, imageSearchQuota } = addonItem;

        const title = `${
          pricePerMonth === 0 ? `${t("addon.imageSearch.freeTrial")}` : ""
        }${t("addon.imageSearch.item.title", { quota: convertPriceFormat(imageSearchQuota, 0) })}`;
        const description = `${t("addon.imageSearch.item.description", {
          price: convertPriceFormat(pricePerMonth, 0),
        })} ${pricePerMonth === 0 ? "" : t("addon.imageSearch.monthly")}`;
        const isActive = Boolean(selectedImageAddon && selectedImageAddon.id === id);

        return (
          <Grid item xs={12} key={id} className="pt-3">
            <RadioBox
              title={title}
              description={description}
              isActive={isActive}
              onClick={() => {
                handleChangeImageSearchAddon(addonItem);
              }}
              rtl
            />
          </Grid>
        );
      })}

      <Grid item container className="pt-3">
        <Divider thickness="0.5px" className="m-0 w-100" />
        <Grid item container alignItems="center" justify="space-between" className="p-3">
          <Grid item container xs={8} sm={10} className="pl-3">
            <Grid item xs={12}>
              <Typography variant="title7" color="dark">
                {t("addon.imageSearch.item.title", { quota: overQuota })}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body3" color="dark">
                {t("addon.imageSearch.contactUs.description")}
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <a href="mailto:contact@leanbiz.ai">
              <Button size="small" fullWidth>
                {t("addon.imageSearch.contactUs.button")}
              </Button>
            </a>
          </Grid>
        </Grid>
        <Divider thickness="0.5px" className="m-0 w-100" />
        <Grid item className="py-3 px-4">
          {!!extraPrice && (
            <Typography variant="body3">{t("addon.imageSearch.extraPrice", { price: extraPrice })}</Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ImageSearchItemList;

const notification = {
  en: {
    "Update successfully.": "Update successfully.",
    "Update fail.": "Update fail.",
    "Create successfully.": "Create successfully.",
    "Create fail.": "Create fail.",
    "Delete successfully.": "Delete successfully.",
    "Delete fail.": "Delete fail.",
    "Submit successfully.": "Submit successfully.",
    "Submit fail.": "Submit fail.",
    "Upload successfully": "Upload successfully",
    "Upload fail": "Upload fail",
    "notify.connectSuccess": "Connect success",
    // notification setting
    "NotificationSetting.header": "Notification setting",
    "notification.configuration.type.CHECKOUT": "Customer Checkout",
    "notification.configuration.type.ORDER": "Order State",
    "notification.configuration.type.CHAT": "Chat",
    "notification.configuration.type.INVENTORY": "Inventory",
    "notification.configuration.name.PROCEEDING_TO_CHECKOUT": "Pending for order confirmation",
    "notification.configuration.name.DELIVERY_METHOD_SELECTION": "Pending for delivery method selection",
    "notification.configuration.name.FILLING_ADDRESS": "Pending for filling address",
    "notification.configuration.name.SHIPPING_METHOD_SELECTION": "Pending for shipping method selection",
    "notification.configuration.name.DELIVERY_PERIOD_SELECTION": "Pending for delivery period selection ",
    "notification.configuration.name.CHECKOUT_ON_WEBVIEW": "Pending for checkout on web view",
    "notification.configuration.name.PENDING_FOR_SHIPPING_COST": "Order pending for shipping cost",
    "notification.configuration.name.NEW_ORDER": "New order",
    "notification.configuration.name.AWAITING_PAYMENT_APPROVAL": "Order paid",
    "notification.configuration.name.ABANDONED_ORDER": "Abandoned order",
    "notification.configuration.name.COD_NEW_ORDER": "New COD order",
    "notification.configuration.name.COD_AWAITING_PAYMENT_APPROVAL": "COD order paid",
    "notification.configuration.name.COD_READY_TO_SHIP": "COD order ready to ship",
    "notification.configuration.name.NEW_CUSTOMER": "New customer",
    "notification.configuration.name.POTENTIAL_CUSTOMER": "Potential customer",
    "notification.configuration.name.SPECIAL_KNOWLEDGE": "Specialized knowledge",
    "notification.configuration.name.BOT_CANNOT_RESPOND": "AI - unsupported message",
    "notification.configuration.name.ADMIN_CONTACTING_REQUEST": "Request for Admin Contact",
    "notification.configuration.name.LOW_PRODUCT_QUANTITY": "Low product quantity",
    "notification.configuration.name.OUT_OF_STOCK": "Out of stock",
    "notification.configuration.name.FULL_TAX_INVOICE": "Full tax invoice request",
    "notification.configuration.name.DIRECT_MESSAGE": "New direct message",
    "notification.configuration.name.ORDER_PAID_WITH_DEEPLE_PAY": "Order paid with LeanBiz pay",
    "notification.configuration.name.LALAMOVE_SHIPMENT_REJECTED_STATUS": "Rejected shipments of Lalamove",
    "notification.configuration.name.LALAMOVE_SHIPMENT_EXPIRED_STATUS": "No accepted  shipments of Lalamove",
    "notification.configuration.name.ADMIN_ENGLISH_SPEAKER_REQUEST": "English-speaking Admin request",
    "notification.configuration.description.PROCEEDING_TO_CHECKOUT":
      "Notify Admin when customers might need help to confirm their orders.",
    "notification.configuration.description.DELIVERY_METHOD_SELECTION":
      "Notify Admin when customers might need help to select between or pick-up.",
    "notification.configuration.description.FILLING_ADDRESS":
      "Notify Admin when customers might need help to fill out their addresses.",
    "notification.configuration.description.SHIPPING_METHOD_SELECTION":
      "Notify Admin when customers might need help to select their shipping methods.",
    "notification.configuration.description.DELIVERY_PERIOD_SELECTION":
      "Notify Admin when customers might need help to arrange their delivery schedules.",
    "notification.configuration.description.CHECKOUT_ON_WEBVIEW":
      "Notify Admin when customers might help to check out on web view.",
    "notification.configuration.description.PENDING_FOR_SHIPPING_COST":
      "Notify Admin when customers need to know their shipping costs",
    "notification.configuration.description.NEW_ORDER": "Notify Admin when customers create new orders.",
    "notification.configuration.description.AWAITING_PAYMENT_APPROVAL":
      "Notify Admin when customers send their payment slips.",
    "notification.configuration.description.ABANDONED_ORDER":
      "Notify Admin when customers do not complete their order transactions.",
    "notification.configuration.description.COD_NEW_ORDER": "Notify Admin when customers create new COD orders.",
    "notification.configuration.description.COD_AWAITING_PAYMENT_APPROVAL":
      "Notify Admin when customers send their deposit slips.",
    "notification.configuration.description.COD_READY_TO_SHIP":
      "Notify Admin when customers are waiting for COD orders to be shipped.",
    "notification.configuration.description.NEW_CUSTOMER": "Notify Admin when there are new customers.",
    "notification.configuration.description.POTENTIAL_CUSTOMER": "Notify Admin when there are potential customers.",
    "notification.configuration.description.SPECIAL_KNOWLEDGE":
      "Notify Admin when customers ask questions relevant to specialized knowledge.",
    "notification.configuration.description.BOT_CANNOT_RESPOND":
      "Notify Admin when the bot cannot respond to customers’ questions.",
    "notification.configuration.description.ADMIN_CONTACTING_REQUEST":
      "Notify Admin when customers request to contact Admin.",
    "notification.configuration.description.LOW_PRODUCT_QUANTITY":
      "Notify Admin when a product quantity reaches at the specific minimum product number set on the inventory page",
    "notification.configuration.description.OUT_OF_STOCK": "Notify Admin for when a product is out of stock.",
    "notification.configuration.description.FULL_TAX_INVOICE":
      "Notify Admin when customers request for full tax invoice.",
    "notification.configuration.description.DIRECT_MESSAGE": "Notify Admin when customers send a new message.",
    "notification.configuration.description.ORDER_PAID_WITH_DEEPLE_PAY":
      "Notify Admin when customers make payment with LeanBiz pay.",
    "notification.configuration.description.LALAMOVE_SHIPMENT_REJECTED_STATUS":
      "Notify Admin when drivers reject the shipments from Lalamove",
    "notification.configuration.description.LALAMOVE_SHIPMENT_EXPIRED_STATUS":
      "Notify Admin when no driver from accept the shipments from Lalamove",
    "notification.configuration.name.BANK_SLIP_WITHOUT_ORDER": "Payment slip without new order detected",
    "notification.configuration.description.BANK_SLIP_WITHOUT_ORDER":
      "Notify Admin when customers send their payment slips without opening new orders",
    "notification.configuration.description.ADMIN_ENGLISH_SPEAKER_REQUEST":
      "Notify Admin when customers request to contact one who can communicate in English",
  },
  th: {
    "Update successfully.": "การอัปเดต สำเร็จ",
    "Update fail.": "การอัปเดต ล้มเหลว",
    "Create successfully.": "การสร้าง สำเร็จ",
    "Create fail.": "การสร้าง ล้มเหลว",
    "Delete successfully.": "การลบ สำเร็จ",
    "Delete fail.": "การลบ ล้มเหลว",
    "Submit successfully.": "การส่งข้อมูล สำเร็จ",
    "Submit fail.": "การส่งข้อมูล ล้มเหลว",
    "Upload successfully": "อัปโหลดข้อมูล สำเร็จ",
    "Upload fail": "อัปโหลดข้อมูล ล้มเหลว",
    "notify.connectSuccess": "เชื่อมต่อสำเร็จ",
    // notification setting
    "NotificationSetting.header": "ตั้งค่าการแจ้งเตือน",
    "notification.configuration.type.CHECKOUT": "การชำระเงินของลูกค้า",
    "notification.configuration.type.ORDER": "รายการสั่งซื้อ",
    "notification.configuration.type.CHAT": "แชท",
    "notification.configuration.type.INVENTORY": "คลังสินค้า",
    "notification.configuration.name.PROCEEDING_TO_CHECKOUT": "อาจพบปัญหาในการยืนยันคำสั่งซื้อ",
    "notification.configuration.name.DELIVERY_METHOD_SELECTION": "อาจพบปัญหาในการเลือกวิธีการรับสินค้า ",
    "notification.configuration.name.FILLING_ADDRESS": "อาจพบปัญหาในการกรอกที่อยู่ในการจัดส่ง",
    "notification.configuration.name.SHIPPING_METHOD_SELECTION": "อาจพบปัญหาในการเลือกช่องทางการจัดส่ง",
    "notification.configuration.name.DELIVERY_PERIOD_SELECTION": "อาจพบปัญหาในการเลือกวันเวลาในการรับสินค้า",
    "notification.configuration.name.CHECKOUT_ON_WEBVIEW": "อาจพบปัญหาในการยืนยันคำสั่งซื้อผ่าน web view",
    "notification.configuration.name.PENDING_FOR_SHIPPING_COST": "มีรายการสั่งซื้อที่รอแอดมินคำนวณค่าส่ง",
    "notification.configuration.name.NEW_ORDER": "มีรายการสั่งซื้อใหม่",
    "notification.configuration.name.AWAITING_PAYMENT_APPROVAL": "มีการส่งหลักฐานการโอนเงิน",
    "notification.configuration.name.ABANDONED_ORDER": "มีรายการสั่งซื้อที่ไม่มีการชำระเงิน",
    "notification.configuration.name.COD_NEW_ORDER": "มีรายการสั่งซื้อใหม่แบบเก็บเงินปลายทาง",
    "notification.configuration.name.COD_AWAITING_PAYMENT_APPROVAL":
      "มีการชำระเงินมัดจำรายการสั่งซื้อแบบเก็บเงินปลายทาง",
    "notification.configuration.name.COD_READY_TO_SHIP": "มีรายการสั่งซื้อที่รอส่งแบบเก็บเงินปลายทาง",
    "notification.configuration.name.NEW_CUSTOMER": "ลูกค้าใหม่",
    "notification.configuration.name.POTENTIAL_CUSTOMER": "ลูกค้าต้องการสั่งซื้อสินค้า",
    "notification.configuration.name.SPECIAL_KNOWLEDGE": "คำถามเฉพาะทางของร้าน",
    "notification.configuration.name.BOT_CANNOT_RESPOND": "ข้อความที่บอทไม่รองรับ",
    "notification.configuration.name.ADMIN_CONTACTING_REQUEST": "ลูกค้าต้องการติดต่อแอดมิน",
    "notification.configuration.name.LOW_PRODUCT_QUANTITY": "จำนวนสินค้าเหลือน้อย",
    "notification.configuration.name.OUT_OF_STOCK": "สินค้าหมด",
    "notification.configuration.name.FULL_TAX_INVOICE": "ลูกค้าต้องการใบกำกับภาษีฉบับเต็ม",
    "notification.configuration.name.DIRECT_MESSAGE": "ลูกค้าส่งข้อความถึงแอดมิน",
    "notification.configuration.name.ORDER_PAID_WITH_DEEPLE_PAY": "มีรายการสั่งซื้อที่ชำระเงินด้วย LeanBiz pay",
    "notification.configuration.name.LALAMOVE_SHIPMENT_REJECTED_STATUS": "มีการยกเลิกคำสั่งส่งสินค้าจาก Lalamove",
    "notification.configuration.name.LALAMOVE_SHIPMENT_EXPIRED_STATUS": "ไม่มีการตอบรับการส่งสินค้าของ Lalamove",
    "notification.configuration.name.ADMIN_ENGLISH_SPEAKER_REQUEST": "ลูกค้าต้องการแอดมินพูดภาษาอังกฤษ",
    "notification.configuration.description.PROCEEDING_TO_CHECKOUT":
      "แจ้งเตือนแอดมินเมื่อลูกค้าอาจต้องการความช่วยเหลือในการยืนยันคำสั่งซื้อ",
    "notification.configuration.description.DELIVERY_METHOD_SELECTION":
      "แจ้งเตือนแอดมินเมื่อลูกค้าอาจต้องการความช่วยเหลือในการเลือกการจัดส่งถึงบ้านหรือรับที่หน้าร้าน",
    "notification.configuration.description.FILLING_ADDRESS":
      "แจ้งเตือนแอดมินเมื่อลูกค้าอาจต้องการความช่วยเหลือในการกรอกที่อยู่ในการจัดส่ง",
    "notification.configuration.description.SHIPPING_METHOD_SELECTION":
      "แจ้งเตือนแอดมินเมื่อลูกค้าอาจต้องการความช่วยเหลือในการเลือกช่องทางการจัดส่ง",
    "notification.configuration.description.DELIVERY_PERIOD_SELECTION":
      "แจ้งเตือนแอดมินเมื่อลูกค้าอาจต้องการความช่วยเหลือในการเลือกวันเวลาในการรับสินค้า",
    "notification.configuration.description.CHECKOUT_ON_WEBVIEW":
      "แจ้งเตือนแอดมินเมื่อลูกค้าใช้เวลานานก่อนยืนยันการสั่งซื้อผ่าน web view",
    "notification.configuration.description.PENDING_FOR_SHIPPING_COST":
      "แจ้งเตือนแอดมินเมื่อมีรายการที่กำลังรอให้แอดมินคำนวณค่าส่ง",
    "notification.configuration.description.NEW_ORDER": "แจ้งเตือนแอดมินเมื่อมีการเปิดคำสั่งซื้อใหม่",
    "notification.configuration.description.AWAITING_PAYMENT_APPROVAL":
      "แจ้งเตือนแอดมินเมื่อมีการส่งหลักฐานการโอนเงินเข้ามา",
    "notification.configuration.description.ABANDONED_ORDER": "แจ้งเตือนแอดมินเมื่อมีรายการสั่งซื้อที่ไม่มีการชำระเงิน",
    "notification.configuration.description.COD_NEW_ORDER":
      "แจ้งเตือนแอดมินเมื่อมีการเปิดคำสั่งซื้อใหม่แบบเก็บเงินปลายทาง",
    "notification.configuration.description.COD_AWAITING_PAYMENT_APPROVAL":
      "แจ้งเตือนแอดมินเมื่อมีการส่งหลักฐานการโอนเงินมัดจำเข้ามา",
    "notification.configuration.description.COD_READY_TO_SHIP":
      "แจ้งเตือนแอดมินเมื่อมีรายการที่รอส่งแบบเก็บเงินปลายทาง",
    "notification.configuration.description.NEW_CUSTOMER": "แจ้งเตือนแอดมินเมื่อมีลูกค้าใหม่",
    "notification.configuration.description.POTENTIAL_CUSTOMER": "แจ้งเตือนแอดมินเมื่อมีลูกค้าต้องการสั่งซื้อสินค้า",
    "notification.configuration.description.SPECIAL_KNOWLEDGE": "แจ้งเตือนแอดมินเมื่อลูกค้าสอบถามคำถามเฉพาะทางของร้าน",
    "notification.configuration.description.BOT_CANNOT_RESPOND":
      "แจ้งเตือนแอดมินเมื่อบอทไม่สามารถตอบคำถามลูกค้าได้ (Fallback messages)",
    "notification.configuration.description.ADMIN_CONTACTING_REQUEST":
      "แจ้งเตือนแอดมินเมื่อลูกค้าต้องการการติดต่อแอดมิน",
    "notification.configuration.description.LOW_PRODUCT_QUANTITY": "แจ้งเตือนแอดมินเมื่อจำนวนสินค้าในคลังเหลือน้อย",
    "notification.configuration.description.OUT_OF_STOCK": "แจ้งเตือนแอดมินเมื่อสินค้าหมด",
    "notification.configuration.description.FULL_TAX_INVOICE":
      "แจ้งเตือนแอดมินเมื่อลูกค้าสอบถามเกี่ยวกับใบกำกับภาษีฉบับเต็ม",
    "notification.configuration.description.DIRECT_MESSAGE": "แจ้งเตือนแอดมินเมื่อมีข้อความใหม่จากลูกค้า",
    "notification.configuration.description.ORDER_PAID_WITH_DEEPLE_PAY":
      "แจ้งเตือนแอดมินเมื่อมีลูกค้าทำการชำระเงินด้วย LeanBiz pay",
    "notification.configuration.description.LALAMOVE_SHIPMENT_REJECTED_STATUS":
      "แจ้งเตือนแอดมินเมื่อมีคนขับจากยกเลิกคำสั่งส่งสินค้าจาก Lalamove",
    "notification.configuration.description.LALAMOVE_SHIPMENT_EXPIRED_STATUS":
      "แจ้งเตือนแอดมินเมื่อมีคนขับไม่รับตอบรับคำส่งสินค้าจาก Lalamove",
    "notification.configuration.name.BANK_SLIP_WITHOUT_ORDER": "พบสลิปการโอนเงินก่อนมีรายการสั่งซื้อเกิดขึ้น",
    "notification.configuration.description.BANK_SLIP_WITHOUT_ORDER":
      "แจ้งเตือนแอดมินเมื่อลูกค้าส่งสลิปการโอนเงินเข้ามา ในขณะที่ยังไม่เปิดรายการสั่งซื้อ",
    "notification.configuration.description.ADMIN_ENGLISH_SPEAKER_REQUEST":
      "แจ้งเตือนแอดมินเมื่อลูกค้าต้องการคุยกับแอดมินที่สามารถสื่อสารเป็นภาษาอังกฤษ",
  },
};

export default notification;

const planBilling = {
  en: {
    "planBilling.verified": "Verified",
    "planBilling.inProgress": "Pending",
    "planBilling.confirmation.subTitle":
      "Please note that your certificate will need be verified again if you re-upload the document to use in the invoice.",
    "planBilling.header": "Billing",
    "planBilling.heading.title": "Billing details",
    "planBilling.title.summary": "Overview",
    "planBilling.label.summary.total": "Total",
    "planBilling.label.upload": "Business Registration Certificate",
    "planBilling.label.addon.preText": "We also have monthly",
    "planBilling.label.addon": "Add-on",
    "planBilling.label.addon.postText": "packages for your extra usage",
    "planBilling.button.addon": "Add-on",
    "planBilling.tooltip.contactSale": "Please contact at contact@leanbiz.ai",
    "planBilling.title.billingInfo": "Billing information",
    "planBilling.info.label.taxId": "TAX ID :",
    "planBilling.info.label.contactPerson": "Contact person :",
    "planBilling.info.label.email": "Email :",
    "planBilling.info.label.phoneNumber": "Phone number :",
    "planBilling.info.form.label.companyName": "Company name",
    "planBilling.info.form.label.address": "Company address",
    "planBilling.info.form.label.email": "Email",
    "planBilling.info.form.label.taxId": "TAX ID",
    "planBilling.info.form.label.phoneNumber": "Phone number",
    "planBilling.info.form.label.contactPerson": "Contact person",
    "planBilling.info.form.button.back": "Back",
    "planBilling.info.form.button.submit": "Save",
    "planBilling.title.invoiceHistory": "Invoice history",
    "planBilling.label.invoiceHistory.seeAll": "See all invoices",
    "planBilling.info.error.companyName.required": "Please fill in your company name",
    "planBilling.info.error.name.required": "Please fill in your name",
    "planBilling.info.error.titleName.required": "Please fill in your title",
    "planBilling.info.error.image.required": "Please upload image",
    "planBilling.info.error.postalCode.required": "Please fill in your postal code",
    "planBilling.info.error.postalCode.invalid": "Your postal code is invalid",
    "planBilling.info.error.subDistrict.required": "Please fill in your sub-district",
    "planBilling.info.error.district.required": "Please fill in your district",
    "planBilling.info.error.province.required": "Please fill in your province",
    "planBilling.info.error.address.required": "Please fill in your address",
    "planBilling.info.error.companyAddress.required": "Please fill in your company address",
    "planBilling.info.error.email.required": "Please fill in your email",
    "planBilling.info.error.taxId.required": "Please fill in your Tax ID",
    "planBilling.info.error.email.format": "Please enter your email address in format yourname@work.com",
    "planBilling.info.error.taxId.format": "Tax ID must be 13 digit characters",
    "planBilling.info.error.phoneNumber.format": "Phone number is required and should be number 9-10 digits",
    "planBilling.info.error.contactPerson.maxLength": "Please enter a value using 50 characters or fewer",
    "planBilling.label.summary.usagePeriod": "Renewal date : ",
    "planBilling.packageSummary.label.free": "Free package",
    "planBilling.packageSummary.label.starter_1": "Starter-S package",
    "planBilling.packageSummary.label.starter_2": "Starter-M package",
    "planBilling.packageSummary.label.starter_3": "Starter-L package",
    "planBilling.packageSummary.label.standard": "Basic Package",
    "planBilling.packageSummary.label.premium": "Advance package",
    "planBilling.packageSummary.label.professional": "Standard package",
    "planBilling.packageSummary.freeSummary": "500 customers, 50 orders",
    "planBilling.packageSummary.newSummary": "{{subscriberQuota}} customers, {{productQuota}} orders",
    "planBilling.packageSummary.itemValueSuffix.main": "customers",
    "planBilling.packageSummary.itemValueSuffix.newPlan.main": "customers",
    "planBilling.packageSummary.itemValueSuffix.c_commerce": "orders",
    "planBilling.packageSummary.itemValueSuffix.newPlan.c_commerce": "products",
    "planBilling.packageSummary.itemValueSuffix.image_search": "image search",
    "planBilling.packageSummary.itemValueSuffix.newPlan.image_search": "image search",
    "planBilling.link.changePlan": "Change package",
    "planBilling.summary.addon.label.title": "Add-on",
    "planBilling.summary.addon.label.member": "Add {{count}} Member",
    "planBilling.summary.addon.label.member_plural": "Add {{count}} Members",
    "planBilling.summary.addon.unit.member": "member",
    "planBilling.summary.addon.label.offline_order": "Offline order",
    "planBilling.summary.addon.label.image_search": "{{count}} AI Visual Product Search",
    "planBilling.summary.addon.label.response_message": "Custom AI response message",
    "planBilling.summary.addon.label.payment_gateway": "Payment gateway",
    "planBilling.summary.addon.label.inventory": "Inventory integration",
    "planBilling.summary.addon.label.zort_integration": "Zort integration",
    "planBilling.summary.addon.label.broadcast": "Unlimited broadcast messages",
    "planBilling.summary.addon.label.channel": "Add {{count}} channel",
    "planBilling.summary.addon.label.channel_plural": "Add {{count}} channels",
    "planBilling.summary.addon.unit.image_search": "time",
    "planBilling.summary.extra.label.title": "Extra",
    "planBilling.summary.extra.label.c_commerce": "{{count}} orders",
    "planBilling.summary.extra.label.main": "{{count}} customers",
    "planBilling.summary.extra.label.image_search": "{{count}} AI Visual Product Search",
    "planBilling.summary.extra.unit.c_commerce": "order",
    "planBilling.summary.extra.unit.main": "customer",
    "planBilling.summary.extra.unit.image_search": "time",
    "planBilling.info.noBillingInfo": "No billing information",
    "planBilling.label.summary.subtotal": "Sub total",
    "planBilling.label.summary.vat": "Vat",
    "planBilling.vatOption.included": "(Included)",
    "planBilling.vatOption.excluded": "(Excluded)",
    IMAGE_SEARCH: "Image search",
    "billingInfoWarningModal.description":
      "Please fill in the Billing information before making change to package and add-on.",
    "billingInfoWarningModal.button.confirm": "Manage Billing",
    "planBilling.loading.title": "Saving your billing information",
    "planBilling.error.title": "There’s problem in saving your billing information.",
    "planBilling.error.description.duplicateKey":
      "There’s duplicate data now. Please try to refresh the page and fill out the form again.",
    "planBilling.subscriptionType.annually": "Yearly",
  },
  th: {
    "planBilling.verified": "ยืนยันแล้ว",
    "planBilling.inProgress": "รอดำเนินการ",
    "planBilling.confirmation.subTitle":
      "ใบจดทะเบียนการค้าของคุณจะต้องมีการตรวจสอบอีกครั้ง หลังจากคุณอัปโหลดไฟล์ใหม่เพื่อใช้ในการออกใบเสร็จ",
    "planBilling.header": "การเรียกเก็บเงิน",
    "planBilling.heading.title": "รายละเอียดการเรียกเก็บเงิน",
    "planBilling.title.summary": "สรุปการใช้งาน",
    "planBilling.label.summary.total": "รวมทั้งหมด",
    "planBilling.label.upload": "ใบจดทะเบียนการค้า",
    "planBilling.label.addon.preText": "คุณสามารถซื้อ",
    "planBilling.label.addon": "ฟีเจอร์เสริม",
    "planBilling.label.addon.postText": "รายเดือนเพิ่มเติมได้ สำหรับการใช้งานที่มากขึ้น",
    "planBilling.button.addon": "ฟีเจอร์เสริม",
    "planBilling.tooltip.contactSale": "กรุณาติดต่อเซลล์ที่ contact@leanbiz.ai",
    "planBilling.title.billingInfo": "ข้อมูลการเรียกเก็บเงิน",
    "planBilling.info.label.taxId": "หมายเลขประจำตัวผู้เสียภาษี :",
    "planBilling.info.label.contactPerson": "ชื่อผู้ติดต่อ :",
    "planBilling.info.label.email": "อีเมล :",
    "planBilling.info.label.phoneNumber": "หมายเลขโทรศัพท์ :",
    "planBilling.info.form.label.companyName": "ชื่อบริษัท",
    "planBilling.info.form.label.address": "ที่อยู่บริษัท",
    "planBilling.info.form.label.email": "อีเมล",
    "planBilling.info.form.label.taxId": "หมายเลขประจำตัวผู้เสียภาษี",
    "planBilling.info.form.label.phoneNumber": "หมายเลขโทรศัพท์",
    "planBilling.info.form.label.contactPerson": "ชื่อผู้ติดต่อ",
    "planBilling.info.form.button.back": "กลับ",
    "planBilling.info.form.button.submit": "บันทึก",
    "planBilling.title.invoiceHistory": "ประวัติใบแจ้งค่าบริการ",
    "planBilling.label.invoiceHistory.seeAll": "ใบแจ้งค่าบริการทั้งหมด",
    "planBilling.info.error.companyName.required": "กรุณากรอกชื่อบริษัท",
    "planBilling.info.error.name.required": "กรุณากรอกชื่อ",
    "planBilling.info.error.titleName.required": "กรุณากรอกคำนำหน้าชื่อ",
    "planBilling.info.error.image.required": "กรุณาอัปโหลดรูปภาพ",
    "planBilling.info.error.postalCode.required": "กรุณากรอกรหัสไปรษณีย์",
    "planBilling.info.error.postalCode.invalid": "กรุณากรอกรหัสไปรษณีย์ให้ถูกต้อง",
    "planBilling.info.error.subDistrict.required": "กรุณากรอกแขวง/ตำบล",
    "planBilling.info.error.district.required": "กรุณากรอกเขต/อำเภอ",
    "planBilling.info.error.province.required": "กรุณากรอกจังหวัด",
    "planBilling.info.error.address.required": "กรุณากรอกที่อยู่",
    "planBilling.info.error.companyAddress.required": "กรุณากรอกที่อยู่บริษัท",
    "planBilling.info.error.email.required": "กรุณากรอกอีเมล",
    "planBilling.info.error.taxId.required": "กรุณากรอกหมายเลขประจำตัวผู้เสียภาษี",
    "planBilling.info.error.email.format": "กรุณากรอกอีเมลให้ถูกต้องตามรูปแบบ ตัวอย่างเช่น yourname@work.com",
    "planBilling.info.error.taxId.format": "หมายเลขประจำตัวผู้เสียภาษีต้องเป็นตัวเลข 13 หลัก",
    "planBilling.info.error.phoneNumber.format": "กรุณากรอกหมายเลขโทรศัพท์ และควรเป็นตัวเลข 9-10 หลัก",
    "planBilling.info.error.contactPerson.maxLength": "กรุณากรอกไม่เกิน 50 ตัวอักษร",
    "planBilling.label.summary.usagePeriod": "รอบบิลถัดไป : ",
    "planBilling.packageSummary.label.free": "แพ็กเกจฟรี",
    "planBilling.packageSummary.label.starter_1": "แพ็กเกจ Starter-S",
    "planBilling.packageSummary.label.starter_2": "แพ็กเกจ Starter-M",
    "planBilling.packageSummary.label.starter_3": "แพ็กเกจ Starter-L",
    "planBilling.packageSummary.label.standard": "แพ็คเกจเบสิค",
    "planBilling.packageSummary.label.premium": "แพ็กเกจแอดวานซ์",
    "planBilling.packageSummary.label.professional": "แพ็กเกจสแตนดาร์ด",
    "planBilling.packageSummary.freeSummary": "ลูกค้า 500 คน, 50 รายการสั่งซื้อ",
    "planBilling.packageSummary.newSummary": "ลูกค้า {{subscriberQuota}} คน, {{productQuota}} สินค้า",
    "planBilling.packageSummary.itemValueSuffix.main": "ลูกค้า",
    "planBilling.packageSummary.itemValueSuffix.newPlan.main": "ลูกค้า",
    "planBilling.packageSummary.itemValueSuffix.c_commerce": "รายการสั่งซื้อ",
    "planBilling.packageSummary.itemValueSuffix.newPlan.c_commerce": "สินค้า",
    "planBilling.packageSummary.itemValueSuffix.image_search": "การค้นหารูปภาพด้วย AI",
    "planBilling.packageSummary.itemValueSuffix.newPlan.image_search": "การค้นหารูปภาพด้วย AI",
    "planBilling.link.changePlan": "เปลี่ยนแพ็กเกจ",
    "planBilling.summary.addon.label.title": "ฟีเจอร์เสริม",
    "planBilling.summary.addon.label.member": "เพิ่มสมาชิก {{count}} คน",
    "planBilling.summary.addon.label.member_plural": "เพิ่มสมาชิก {{count}} คน",
    "planBilling.summary.addon.unit.member": "คน",
    "planBilling.summary.addon.label.offline_order": "รายการสั่งซื้อออฟไลน์",
    "planBilling.summary.addon.label.image_search": "การค้นหารูปภาพด้วย AI {{count}} ครั้ง",
    "planBilling.summary.addon.label.response_message": "ระบบการจัดการถามตอบของแชทบอท",
    "planBilling.summary.addon.label.payment_gateway": "Payment gateway",
    "planBilling.summary.addon.label.inventory": "inventory integration",
    "planBilling.summary.addon.label.zort_integration": "Zort integration",
    "planBilling.summary.addon.label.broadcast": "ข้อความบรอดแคสต์ไม่จำกัดจำนวน",
    "planBilling.summary.addon.label.channel": "เพิ่มช่องทางการเชื่อมต่อ {{count}} ช่อง",
    "planBilling.summary.addon.label.channel_plural": "เพิ่มช่องทางการเชื่อมต่อ {{count}} ช่อง",
    "planBilling.summary.addon.unit.image_search": "ครั้ง",
    "planBilling.summary.extra.label.title": "ส่วนที่คิดเพิ่ม",
    "planBilling.summary.extra.label.c_commerce": "{{count}} รายการสั่งซื้อ",
    "planBilling.summary.extra.label.main": "ลูกค้า {{count}} คน",
    "planBilling.summary.extra.label.image_search": "การค้นหารูปภาพด้วย AI {{count}} ครั้ง",
    "planBilling.summary.extra.unit.c_commerce": "รายการสั่งซื้อ",
    "planBilling.summary.extra.unit.main": "คน",
    "planBilling.summary.extra.unit.image_search": "ครั้ง",
    "planBilling.info.noBillingInfo": "ไม่มีข้อมูลการเรียกเก็บเงิน",
    "planBilling.label.summary.subtotal": "ราคารวม",
    "planBilling.label.summary.vat": "ภาษีมูลค่าเพิ่ม",
    "planBilling.vatOption.included": "(Included)",
    "planBilling.vatOption.excluded": "(Excluded)",
    IMAGE_SEARCH: "การค้นหารูปภาพด้วย AI",
    "billingInfoWarningModal.description": "โปรดกรอกข้อมูลการเรียกเก็บเงินก่อนจะทำการเปลี่ยนแพ็กเกจหรือฟีเจอร์เสริม",
    "billingInfoWarningModal.button.confirm": "จัดการการเรียกเก็บเงิน",
    "planBilling.loading.title": "กำลังบันทึกข้อมูลการเรียกเก็บเงินของคุณ",
    "planBilling.error.title": "พบปัญหาในการบันทึกข้อมูลการเรียกเก็บเงินของคุณ",
    "planBilling.error.description.duplicateKey":
      "พบปัญหาในการบันทึกข้อมูลการเรียกเก็บเงินของคุณ มีข้อมูลที่ซ้ำกันในขณะนี้ โปรดลองรีเฟรชหน้านี้และกรอกแบบฟอร์มอีกครั้ง",
    "planBilling.subscriptionType.annually": "รายปี",
  },
};

export default planBilling;

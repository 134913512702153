import { OptionType } from "react-select";
import {
  PromotionType as PromotionTypeType,
  PromotionOperator,
  PromotionThreshold,
  PromotionDiscount,
  FreeProduct,
} from "@leanbizai/shopping-cart-calculator";
import { CategoryType } from "types/Category";
import { ProductTypeType, ProductType } from "./Product";

export type SuggestPromotionOptionValue =
  | ProductSelectorType
  | SpecificAmountSelectorType
  | PromotionSelectorType
  | PriceSelectorType
  | BundleSelector;

export type SuggestPromotionOption = {
  label: string;
  isDisabled?: boolean;
  value?: SuggestPromotionOptionValue[];
  options?: SubSuggestPromotionOption[];
};

export type SubSuggestPromotionOption = {
  label: string;
  title?: string;
  isDisabled?: boolean;
  value?: PromotionConfig;
  description?: string[];
};

export type BundleConfig = PromotionConfig & {
  [BundleSelector.BUNDLE]?: PromotionConfig;
  [BundleSelector.NO_BUNDLE]?: PromotionConfig;
};

export type PromotionConfig = {
  isActive?: boolean;
  maxCriteriaLength?: number; // 0,undefined = unlimited
  promotion?: string;
  condition?: PromotionCondition;
  suggestPromotionOption?: SubSuggestPromotionOption;
  isBundled?: boolean;
  awardFromLowToHigh?: boolean;
  awardTotalPrice?: boolean;
  discount?: string;
  threshold?: string;
  type?: DiscountType;
};

export type PromotionPriceConfig = PromotionConfig & {
  [PriceSelectorType.THB]?: BundleConfig;
  [PriceSelectorType.PERCENT]?: BundleConfig;
  [BundleSelector.BUNDLE]?: PromotionConfig;
  [BundleSelector.NO_BUNDLE]?: PromotionConfig;
};

export type PromotionTypesType = {
  [key: string]: {
    [key: string]: {
      value: string;
      label: string;
      isBundled: boolean;
      threshold?: string;
      discount?: string;
      awardFromLowToHigh: boolean;
      awardTotalPrice?: boolean;
    };
  };
};

export type PromotionSettingDiscountType = {
  fixed?: number;
  amount?: number;
  percent?: number;
};

type PromotionSettingThresholdType = {
  price?: number;
  amount?: number;
};

export type PromotionCriteria = {
  operator?: PromotionOperator;
  thresholds: PromotionThreshold[];
  discount?: PromotionDiscount;
  freeProducts?: FreeProduct[];
  minimumThreshold?: number;
  maximumThreshold?: number;
};

export type PromotionSettingsType = {
  type: string;
  condition: PromotionCondition;
  isBundled: boolean;
  awardFromLowToHigh: boolean;
  threshold?: PromotionSettingThresholdType;
  discount?: PromotionSettingDiscountType;
  awardTotalPrice?: boolean;
  shippingMethod?: string;
  shippingIds?: string[];
  isRepeatable?: boolean;
  criteria?: PromotionCriteria[];
};

export type PromotionProductSKUImagesType = {
  src: string;
};

export type PromotionProductSKUType = {
  id: string;
  amount?: number;
  price: number;
  projectId?: string;
  productId: string;
  productCode: string;
  image: string;
  selected: boolean;
  isFree: boolean;
  stock?: number;
  cfCode?: string;
  product: {
    id: string;
    name: string;
    productCode: string;
    images: Array<PromotionProductSKUImagesType>;
    categories: CategoryType[];
    type: ProductType;
    cfCode?: string;
  };
  productType: ProductTypeType[];
  promotions?: PromotionType[]; // this field from order query
};

export type PromotionFreeProductSKUType = PromotionProductSKUType & { amount: number };

export type PromotionType = {
  id: string;
  name: string;
  shortName: string;
  description?: string;
  isActive: boolean;
  type: PromotionTypeType;
  settings: PromotionSettingsType;
  selectedProducts: PromotionProductSKUType[];
  freeProducts: PromotionFreeProductSKUType[];
  totalDiscount?: number;
  campaignId?: string;
  isIncludedAllProducts?: boolean;
};

export type PromotionOptionType = {
  isBundled: boolean;
  threshold?: string;
  discount?: string;
  group: string;
  condition: string;
  awardFromLowToHigh: boolean;
  awardTotalPrice?: boolean;
  type: PromotionTypeType;
} & OptionType;

export type PromotionGroupOptionType = {
  label: string;
  options: PromotionOptionType;
};

export type PromotionInputType = {
  name: string;
  shortName: string;
  description?: string;
  isActive: boolean;
  type: string;
  isIncludedAllProducts?: boolean;
  settings: PromotionSettingsType;
  selectedProducts?: { skuId: string }[];
  freeProducts?: FreeProductSKUId[];
};

export type PromotionFormType = {
  promotionType: PromotionOptionType;
  promotionName: string;
  promotionShortName: string;
  promotionDescription: string;
  promotionThreshold: number;
  promotionDiscount: number;
} & { [key: string]: number };

export type PromotionLabelsType = {
  [key: string]: string;
};

export type PromotionTypeLabelsType = {
  PRODUCT: PromotionLabelsType;
  SHIPPING: PromotionLabelsType;
};

export enum PromotionCondition {
  BUY_ANY_PRODUCT = "BUY_ANY_PRODUCT",
  BUY_ANY_SELECTED_PRODUCT = "BUY_ANY_SELECTED_PRODUCT",
  BUY_X_PIECE_OF_ANY_PRODUCT = "BUY_X_PIECE_OF_ANY_PRODUCT",
  BUY_X_PIECE_OF_SELECTED_PRODUCT = "BUY_X_PIECE_OF_SELECTED_PRODUCT",
  BUY_X_PIECE_OF_SELECTED_PRODUCT_IN_BUNDLE = "BUY_X_PIECE_OF_SELECTED_PRODUCT_IN_BUNDLE",
  BUY_X_PRICE_IN_TOTAL_OF_ANY_PRODUCT = "BUY_X_PRICE_IN_TOTAL_OF_ANY_PRODUCT",
  BUY_X_PRICE_IN_TOTAL_OF_SELECTED_PRODUCT = "BUY_X_PRICE_IN_TOTAL_OF_SELECTED_PRODUCT",
  BUY_X_PRICE_IN_TOTAL_OF_SELECTED_PRODUCT_IN_BUNDLE = "BUY_X_PRICE_IN_TOTAL_OF_SELECTED_PRODUCT_IN_BUNDLE",
}

export enum ProductSelectorType {
  SPECIFY_PRODUCTS = "SPECIFY_PRODUCTS",
  ALL_PRODUCTS = "ALL_PRODUCTS",
}

export type PromotionVariables = { projectId: string; campaignId: string; promotionId?: string };

export type PromotionQuery = { promotion: PromotionType };

export enum SpecificAmountSelectorType {
  FOR_EVERY_PURCHASE = "FOR_EVERY_PURCHASE",
  AT_A_SPECIFIC_AMOUNT = "AT_A_SPECIFIC_AMOUNT",
  AT_A_SPECIFIC_PRICE = "AT_A_SPECIFIC_PRICE",
}

export enum PromotionSelectorType {
  DISCOUNT = "DISCOUNT",
  SPECIAL_PRICE = "SPECIAL_PRICE",
  FREE_CHEAPEST_PRODUCT = "FREE_CHEAPEST_PRODUCT",
  SHIPPING_DISCOUNT = "SHIPPING_DISCOUNT",
  FREE_SHIPPING_PRICE = "FREE_SHIPPING_PRICE",
  FREE_PRODUCT = "FREE_PRODUCT",
}

export enum PriceSelectorType {
  THB = "THB",
  PERCENT = "PERCENT",
}

export enum AmountType {
  PRICE = "PRICE",
  AMOUNT = "AMOUNT",
}

export enum DiscountType {
  SHIPPING = "SHIPPING",
  PRODUCT = "PRODUCT",
  FIXED = "FIXED",
}

export enum BundleSelector {
  BUNDLE = "BUNDLE",
  NO_BUNDLE = "NO_BUNDLE",
}

export type ProductSelectorTypeOption = { label: string; value: ProductSelectorType };
export type SpecificAmountTypeOption = { label: string; value: SpecificAmountSelectorType };
export type PromotionSelectorTypeOption = { label: string; value: PromotionSelectorType };
export type PriceSelectorTypeOption = { label: string; value: PriceSelectorType };
export type PromotionFormData = {
  name: string;
  shortName: string;
  description: string;
  promotionSelectorType: PromotionSelectorType;
  specificAmountSelectorType: SpecificAmountSelectorType;
  productSelectorType: ProductSelectorType;
  type: string;
  condition: string;
  discountType: string;
  discount: {
    amount: number;
    fixed: number;
    percent: number;
  };
  freeProducts: PromotionFreeProductSKUType[];
  operator: string;
  thresholds: {
    amount: number;
    price: number;
    freeProducts: PromotionFreeProductSKUType[];
  };
  isCustomPromotion: boolean;
  isIncludedAllProducts: boolean;
  isShippingPromotion: boolean;
  isStandard: boolean;
  isExpress: boolean;
  isBundled: boolean;
  isActive: boolean;
  awardFromLowToHigh: boolean;
  awardTotalPrice: boolean;
  criteria?: PromotionCriteria[];
  priceSelectorType: PriceSelectorType;
  maxCriteriaLength: number;
  shippingIds?: string[];
};

export type FreeProductSKUId = {
  skuId: string;
  amount: number;
};

export type ProductSKUId = { skuId: string };

export type PromotionFormContext = {
  productSelectorType?: ProductSelectorType;
  specificAmountSelectorType?: SpecificAmountSelectorType;
  promotionSelectorType?: PromotionSelectorType;
  priceSelectorType?: PriceSelectorType;
  hasPromotionId?: boolean;
};

export type PromotionQueryVariables = {
  projectId: string;
  campaignId: string;
  promotionId: string;
};

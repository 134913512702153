import React, { useState, FC, memo } from "react";
import ReactPaginate from "react-paginate";
import { useTranslation } from "react-i18next";

import { NO_DEEPLE_PAY_TRANSACTIONS } from "constants/Image";

import Table from "components/Table";
import { UnknownData } from "types/TransactionReport";
import Grid from "components/Grid";
import { ShowingResult, RowsSelectorPerPage } from "components/TablePagination";
import useIsDesktop from "utils/hooks/useIsDesktop";

import { getColumns, tableHooks, getSubTableColumns } from "./config";
import useIncomeTransactions from "./hooks/useIncomeTransactions";
import MobileIncomeTransaction from "./MobileIncomeTransaction";
import SubTable from "../../components/SubTable";
import { GridPagination, GridRowsPerPage, GridShowingResult, Typography, PaginationWrapper } from "../../styled";
import { ROWS_PER_PAGE, ROWS_PER_PAGES, DEFAULT_PAGE_START } from "../config";

export const IncomeTransactionTable: FC<{ projectId: string }> = ({ projectId }) => {
  const isDesktop = useIsDesktop();
  const { t } = useTranslation();
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);
  const [page, setPage] = useState(DEFAULT_PAGE_START);
  const { incomeTransactions, isLoading, total } = useIncomeTransactions({ page, perPage: rowsPerPage, projectId });

  const handlePageClick = (data: { selected: number }) => {
    const { selected } = data;
    // +1 because of ReactPaginate page start from 0 but my page start from 1
    setPage(selected + 1);
  };

  const newHandleChangeRowsPerPage = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setPage(DEFAULT_PAGE_START);
  };

  const tableColumns = getColumns<any>();
  return (
    <>
      {isDesktop ? (
        <Table<UnknownData, Record<string, UnknownData>>
          data={incomeTransactions}
          columns={tableColumns}
          hooks={tableHooks}
          isBlurContent={false || isLoading}
          customSubTable={(row) => {
            return <SubTable columns={getSubTableColumns(row)} data={row ? [row] : []} />;
          }}
        />
      ) : (
        <MobileIncomeTransaction data={incomeTransactions} />
      )}
      {!isLoading && total > 0 && (
        <Grid container>
          <Grid item className="px-2" container xs={12} alignItems="center" justify="center">
            <GridShowingResult item xs={6} md="auto" lg="auto">
              <ShowingResult page={page} rowsPerPage={rowsPerPage} total={total} />
            </GridShowingResult>
            <GridPagination item xs={12} md="auto" lg="auto" container>
              <PaginationWrapper>
                <ReactPaginate
                  previousLabel="<"
                  nextLabel=">"
                  breakLabel="..."
                  // -1 because of ReactPaginate page start from 0 but my page start from 1
                  forcePage={page - 1}
                  pageCount={Math.ceil(total / rowsPerPage) || 0}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={2}
                  onPageChange={handlePageClick}
                  activeClassName="active"
                  containerClassName="d-flex flex-wrap p-0"
                  pageClassName="page-no"
                  breakClassName="break"
                  previousClassName="page-no previous"
                  nextClassName="page-no next"
                />
              </PaginationWrapper>
            </GridPagination>

            <GridRowsPerPage item xs={6} md="auto" lg="auto" container alignItems="center" justify="flex-end">
              <Grid item>
                <Typography className="d-inline-block mr-2" color="darkGray">
                  {t("salesReport.rowsSelector.show")}
                </Typography>
              </Grid>
              <Grid item>
                <RowsSelectorPerPage
                  options={ROWS_PER_PAGES}
                  onChange={newHandleChangeRowsPerPage}
                  rowsPerPage={rowsPerPage}
                />
              </Grid>
              <Grid item>
                <Typography className="d-inline-block ml-2" color="darkGray">
                  {t("salesReport.rowsSelector.perPage")}
                </Typography>
              </Grid>
            </GridRowsPerPage>
          </Grid>
        </Grid>
      )}
      {total === 0 && (
        <Grid container justify="center" style={{ minHeight: "inherit" }}>
          <div className="d-flex flex-column justify-content-center text-center">
            <img src={NO_DEEPLE_PAY_TRANSACTIONS} alt="No LeanBiz pay transaction" />
            <Typography className="mt-3" variant="title2" color="lightBlue">
              {t("deeplePay.noDataFound")}
            </Typography>
          </div>
        </Grid>
      )}
    </>
  );
};
export default memo(IncomeTransactionTable);

const newLogIn = {
  en: {
    TITLE: "Advanced AI Chatbot and Store Management",
    SUB_TITLE:
      "Our service provides AI Chatbot for online retailers to recommend products, close sales and support store management as a salesperson.",
    WELCOME_BACK_TO_LEANBIZ: "Welcome back to LeanBiz",
    EMAIL: "Email",
    ENTER_YOUR_EMAIL: "Enter your email",
    PASSWORD: "Password",
    ENTER_YOUR_PASSWORD: "Enter your password",
    SIGN_IN: "Sign in",
    SIGN_IN_APPLE: "Sign in with Apple",
    SIGN_IN_FACEBOOK: "Sign in with Facebook",
    SIGN_IN_GOOGLE: "Sign in with Google",
    SIGN_UP_FREE: "Sign up for free",
    OR: "OR",
    FORGOT_PASSWORD: "Forgot password",
  },
  th: {
    TITLE: "แชทบอท AI และเครื่องมือการจัดการร้านค้า",
    SUB_TITLE:
      "เราให้บริการ AI ตอบแชทสำหรับธุรกิจออนไลน์ที่สามารถตอบคำถาม เสนอขายสินค้า ปิดการขาย รวมทั้งช่วยให้แอดมินจัดการออเดอร์และระบบหลังร้านได้อย่างมีประสิทธิภาพ",
    WELCOME_BACK_TO_LEANBIZ: "ยินดีต้อนรับสู่ LeanBiz",
    EMAIL: "อีเมล",
    ENTER_YOUR_EMAIL: "กรอกอีเมลของคุณ",
    PASSWORD: "รหัสผ่าน",
    ENTER_YOUR_PASSWORD: "กรอกรหัสผ่านของคุณ",
    SIGN_IN: "ลงชื่อเข้าใช้งาน",
    SIGN_IN_APPLE: "ลงชื่อเข้าใช้งานด้วย Apple",
    SIGN_IN_FACEBOOK: "ลงชื่อเข้าใช้งานด้วย Facebook",
    SIGN_IN_GOOGLE: "ลงชื่อเข้าใช้งานด้วย Google",
    SIGN_UP_FREE: "สร้างบัญชีใหม่ฟรี",
    OR: "หรือ",
    FORGOT_PASSWORD: "ลืมรหัสผ่าน",
  },
};

export default newLogIn;

import { ShippingRuleProperties as ShippingRuleType } from "@leanbizai/shopping-cart-calculator";
import { PromotionType } from "./Promotion";
import { DigitalContentStatus } from "./DigitalContent";

export enum STATE_TAB {
  PRODUCT_DETAILS = "PRODUCT_DETAILS",
  CONTENT = "CONTENT",
  INVENTORY = "INVENTORY",
  FOOD_PRODUCT_DETAILS = "FOOD_PRODUCT_DETAILS",
  VARIATIONS = "VARIATIONS",
  SKU_PRODUCT_DETAILS = "SKU_PRODUCT_DETAILS",
}

export type SKUKeyType = {
  id?: string;
  key: string;
};

export type ProductInventoryType = {
  productSKUId: string;
  value: number;
};

export type ProductTypeType = {
  id?: string;
  value: string;
  key: SKUKeyType;
};

export type variableTypes = {
  value: string;
  key: string;
};

export type ProductImageType = {
  src: string;
};

export type DigitalProductInventory = {
  status: DigitalContentStatus;
  inventory: number;
};

export type ProductSKUType = {
  id: string;
  nanoid?: string;
  amount?: number;
  productId: string;
  inventory: number;
  isFree: boolean;
  price: number;
  productCode: string;
  cfCode: string;
  reserved: number;
  selected: boolean;
  deleted: boolean;
  productType: Array<ProductTypeType>;
  options?: ProductOptionType[];
  note?: string;
  productPrice: number;
  shippingRules?: ShippingRuleType[];
  weight?: number;
  image: string;
  // for digital content type
  digitalContentOptions?: DigitalContentOptions;
  digitalProductInventories: DigitalProductInventory[];

  variableTypes?: Array<variableTypes>;

  stockAmount: number; // data from chat log
};

export type ProductSKUDimensionInput = Array<number | null> | null;

export type AddProductSKUType = {
  id?: string;
  nanoid?: string;
  price: number;
  productCode: string;
  cfCode?: string | null;
  image: string;
  selected: boolean;
  deleted?: boolean; // TODO: support this field on add new product. but for now insert on backend is doing soft delete
  isFree: boolean;
  productType: Array<AddProductTypeType>;
  productId?: string;
  options?: ProductOptionType[];
  weight?: number;
  dimension?: ProductSKUDimensionInput; // using [] in frontend when init form
  // for digital content type
  digitalContentOptions?: DigitalContentOptions;
};

export type CartProductSKUType = {
  id: string;
  amount: number;
  productId: string;
  inventory: number;
  isFree: boolean;
  image: string;
  price: number;
  productCode: string;
  cfCode: string;
  reserved: number;
  selected: boolean;
  deleted: boolean;
  productType: Array<ProductTypeType>;
  options?: ProductOptionType[];
  note?: string;
  productPrice: number;
  promotions?: PromotionType[];
  product: ProductItemPropsType;
};

export type CartProductWithOptionType = {
  id: string;
  amount: number;
  productId: string;
  inventory: number;
  isFree: boolean;
  image: string;
  price: number;
  productCode: string;
  reserved: number;
  selected: boolean;
  deleted: boolean;
  productType: Array<ProductTypeType>;
  options: ProductOptionType[];
  note?: string;
  productPrice: number;
  promotions?: PromotionType[];
  product: ProductItemPropsType;
};

export type CategoryType = {
  id: string;
  name: string;
};

export type ProductItemPropsType = {
  id: string;
  cfCode: string;
  type: ProductType;
  defaultPrice: number;
  name: string;
  images: Array<ProductImageType>;
  productSKUs: Array<ProductSKUType>;
  categories: Array<CategoryType>;
  outputType: ProductOutputType;
};

export type NewProductItemProps = {
  id: string;
  cfCode?: string;
  type?: ProductType;
  defaultPrice?: number;
  name?: string;
  isChecked?: boolean;
  images: Array<ProductImageType>;
  productSKUs: Array<NewProductSKU>;
  categories: Array<CategoryType>;
  outputType?: ProductOutputType;
};

export type SearchProductSKUType = {
  id: string;
  type: ProductType;
  productId: string;
  productCode: string;
  price: number;
  image: ProductImageType;
  productType: Array<ProductTypeType>;
  productName: string;
};

export type GetProductsQueryType = {
  products: {
    results: Array<ProductItemPropsType>;
    total: number;
  };
};

export type GetActiveProductsQueryType = {
  activeProducts: {
    results: Array<ProductItemPropsType>;
    total: number;
  };
};

export type GetNewProductsQueryType = {
  products: {
    results: Array<NewProductItemProps>;
    total: number;
  };
};

export type GetProductsVariablesType = {
  projectId: string;
  filter: {
    productName: string;
    sortBy: string;
    isFree?: boolean;
  };
};

export type ProductImagesType = {
  nanoid: string;
  src: string;
};

export type ProductImagesDefaultType = {
  src: string;
};

export type ProductPropertiesType = {
  id?: string;
  name: string;
  value: string;
};

export type ProductVariationsType = {
  id?: string;
  nanoid: string;
  key: string;
  value: Array<string>;
};

export type AddProductTypeType = {
  id?: string;
  value: string;
  key: { id?: string; key: string };
};

export type ProductOptionChoiceType = {
  name: string;
  price: number;
  isDefault: boolean;
  isActive: boolean;
  isSelected?: boolean;
};

export type ProductOptionType = {
  isActive: boolean;
  name: string;
  isMultipleChoices: boolean;
  isRequired: boolean;
  maximum: number;
  choices: ProductOptionChoiceType[];
};

export type GQLProductSKUType = {
  id?: string;
  nanoid?: string;
  price: number;
  productCode: string;
  cfCode: string;
  image: { src: string }; // Beware !!! this is datatype from backend
  selected: boolean;
  deleted: boolean;
  isFree: boolean;
  productType: Array<ProductTypeType>;
  productId?: string;
  options?: ProductOptionType[];
  weight: number;
};

export type FacebookLiveProductSKUType = {
  id: string;
  amount?: number;
  productId: string;
  inventory: number;
  isFree: boolean;
  price: number;
  productCode: string;
  cfCode: string;
  reserved: number;
  selected: boolean;
  deleted: boolean;
  productType: Array<ProductTypeType>;
  productPrice: number;
  image: { src: string }; // Beware !!! this is datatype from backend
  stockAmount: number; // data from chat log
  product: ProductItemPropsType;
};

export enum OrderProductOption {
  ORDER_UP = "order-up",
  ORDER_DOWN = "order-down",
}

export enum ProductType {
  DIGITAL_CONTENT = "DIGITAL_CONTENT",
  SINGLE_SKU_WITH_OPTIONS = "SINGLE_SKU_WITH_OPTIONS",
  SKU = "SKU",
}

export enum ProductOptionKey {
  IS_ACTIVE = "isActive",
  NAME = "name",
  IS_MULTIPLE_CHOICES = "isMultipleChoices",
  IS_REQUIRED = "isRequired",
  MAXIMUM = "maximum",
  CHOICES = "choices",
}

export enum ProductSKUInputDataType {
  PRICE = "price",
  SELECTED = "selected",
  IS_FREE = "isFree",
  PRODUCT_CODE = "productCode",
  IMAGE = "image",
  WEIGHT = "weight",
  DIMENSION = "dimension",
  PRODUCT_CF_CODE = "productCfCode",
}

export enum ErrorAddProduct {
  BASIC_PACK_CANNOT_EXCEED_PRODUCT_QUOTA = "ADD_PRODUCT:BASIC_PACK_CANNOT_EXCEED_PRODUCT_QUOTA",
  DUPLICATED_PRODUCT_NAME = "ADD_PRODUCT:DUPLICATED_PRODUCT_NAME",
  DUPLICATED_PRODUCT_CODE = "ADD_PRODUCT:DUPLICATED_PRODUCT_CODE",
  DESCRIPTION_EXCEED_LIMIT = "ADD_PRODUCT:DESCRIPTION_EXCEED_LIMIT",
  PRICE_REACH_LIMIT = "ADD_PRODUCT:PRICE_REACH_LIMIT",
  DUPLICATED_CF_CODE = "ADD_PRODUCT:DUPLICATED_CF_CODE",
}

export enum ErrorEditProduct {
  DUPLICATED_PRODUCT_CODE = "EDIT_PRODUCT:DUPLICATED_PRODUCT_CODE",
  DUPLICATED_PRODUCT_NAME = "EDIT_PRODUCT:DUPLICATED_PRODUCT_NAME",
  INVENTORY_NOT_EMPTY_CASE = "EDIT_PRODUCT:INVENTORY_NOT_EMPTY_CASE",
  DESCRIPTION_EXCEED_LIMIT = "EDIT_PRODUCT:DESCRIPTION_EXCEED_LIMIT",
  PRICE_REACH_LIMIT = "EDIT_PRODUCT:PRICE_REACH_LIMIT",
  INCORRECT_PRODUCT = "EDIT_PRODUCT:INCORRECT_PRODUCT",
  DUPLICATED_CF_CODE = "EDIT_PRODUCT:DUPLICATED_CF_CODE",
}

export enum ErrorDeleteProduct {
  INVENTORY_NOT_EMPTY_CASE = "DELETE_PRODUCT:INVENTORY_NOT_EMPTY_CASE",
}

export enum ErrorImportProduct {
  BASIC_PACK_CANNOT_EXCEED_PRODUCT_QUOTA = "IMPORT_PRODUCTS:BASIC_PACK_CANNOT_EXCEED_PRODUCT_QUOTA",
  CATEGORY_NOT_FOUND = "IMPORT_PRODUCTS:CATEGORY_NOT_FOUND",
  DESCRIPTION_EXCEED_LIMIT = "IMPORT_PRODUCTS:DESCRIPTION_EXCEED_LIMIT",
  DUPLICATED_PRODUCT_CF_CODE = "IMPORT_PRODUCTS:DUPLICATED_PRODUCT_CF_CODE",
  DUPLICATED_PRODUCT_CODE = "IMPORT_PRODUCTS:DUPLICATED_PRODUCT_CODE",
  DUPLICATED_PRODUCT_CODE_IN_IMPORT_DATA = "IMPORT_PRODUCTS:DUPLICATED_PRODUCT_CODE_IN_IMPORT_DATA",
  DUPLICATED_PRODUCT_NAME = "IMPORT_PRODUCTS:DUPLICATED_PRODUCT_NAME",
  DUPLICATED_PRODUCT_NAME_IN_IMPORT_DATA = "IMPORT_PRODUCTS:DUPLICATED_PRODUCT_NAME_IN_IMPORT_DATA",
  DUPLICATED_PRODUCT_TYPE_SKU = "IMPORT_PRODUCTS:DUPLICATED_PRODUCT_TYPE_SKU",
  EMPTY_PRODUCT_VARIABLE_TYPES = "IMPORT_PRODUCTS:EMPTY_PRODUCT_VARIABLE_TYPES",
  EXCEED_PRODUCT_QUOTA = "IMPORT_PRODUCTS:EXCEED_PRODUCT_QUOTA",
  FILE_TYPE_IS_NOT_PERMITTED = "IMPORT_PRODUCTS:FILE_TYPE_IS_NOT_PERMITTED",
  IMAGE_URL_IS_INVALID = "IMPORT_PRODUCTS:IMAGE_URL_IS_INVALID",
  INCORRECT_UPLOAD_FORMAT = "IMPORT_PRODUCTS:INCORRECT_UPLOAD_FORMAT", // Generic file format error
  MISSING_C_COMMERCE_PACKAGE = "IMPORT_PRODUCTS:MISSING_C_COMMERCE_PACKAGE",
  PRICE_CANNOT_BE_LESSTHAN_OR_EQUAL_ZERO = "IMPORT_PRODUCTS:PRICE_CANNOT_BE_LESSTHAN_OR_EQUAL_ZERO",
  PRICE_REACH_LIMIT = "IMPORT_PRODUCTS:PRICE_REACH_LIMIT",
  PRODUCT_CF_CODE_EXISTED = "IMPORT_PRODUCTS:PRODUCT_CF_CODE_EXISTED",
  PRODUCT_CODE_CANNOT_BE_EMPTY = "IMPORT_PRODUCTS:PRODUCT_CODE_CANNOT_BE_EMPTY",
  PRODUCT_CODE_NOT_SUPPORT_CHARACTER = "PRODUCT_CODE:NOT_SUPPORT_CHARACTER",
  DIGITAL_CONTENT_AVAILABILITY_TYPE_IS_NOT_MATCHED = "IMPORT_DIGITAL_CONTENTS:DIGITAL_CONTENT_AVAILABILITY_TYPE_IS_NOT_MATCHED",
  DUPLICATED_DIGITAL_CONTENT = "IMPORT_DIGITAL_CONTENTS:DUPLICATED_DIGITAL_CONTENT",
  INCORRECT_DIGITAL_CONTENTS = "IMPORT_DIGITAL_CONTENTS:INCORRECT_DIGITAL_CONTENTS",
  PRODUCT_CODE_NOT_FOUND = "IMPORT_DIGITAL_CONTENTS:PRODUCT_CODE_NOT_FOUND",
  PRODUCT_OUTPUT_TYPE_IS_NOT_CODE_IMPORTED = "IMPORT_DIGITAL_CONTENTS:PRODUCT_OUTPUT_TYPE_IS_NOT_CODE_IMPORTED",
}

export enum ErrorImportInventory {
  DUPLICATED_PRODUCT_SKU_CODES = "IMPORTED_PRODUCT_INVENTORIES:DUPLICATED_PRODUCT_SKU_CODES",
  EXCEED_MAXIMUM_IMPORT_ROWS = "IMPORTED_PRODUCT_INVENTORIES:EXCEED_MAXIMUM_IMPORT_ROWS",
  FILE_TYPE_IS_NOT_PERMITTED = "IMPORTED_PRODUCT_INVENTORIES:FILE_TYPE_IS_NOT_PERMITTED",
  INVALID_UPLOAD_FORMAT = "IMPORTED_PRODUCT_INVENTORIES:INVALID_UPLOAD_FORMAT",
  INVENTORY_LESS_THAN_RESERVED = "IMPORTED_PRODUCT_INVENTORIES:INVENTORY_LESS_THAN_RESERVED",
  PRODUCT_SKU_CODES_NOT_EXISTED = "IMPORTED_PRODUCT_INVENTORIES:PRODUCT_SKU_CODES_NOT_EXISTED",
}

export enum DigitalContentAvailability {
  PERIOD_AFTER_PURCHASED = "PERIOD_AFTER_PURCHASED",
  EXPIRY_DATE = "EXPIRY_DATE",
  DATE_RANGE = "DATE_RANGE",
  NO_EXPIRY = "NO_EXPIRY",
}

export enum DigitalContentProductType {
  TOP_UP_AND_VOUCHER = "TOP_UP_AND_VOUCHER",
  EVENT_TICKET = "EVENT_TICKET",
  ONLINE_COURSE = "ONLINE_COURSE",
}

export enum ProductOutputType {
  STATIC = "STATIC",
  QR_CODE = "QR_CODE",
  CODE_IMPORTED = "CODE_IMPORTED",
  CODE_AUTO_GENERATED = "CODE_AUTO_GENERATED",
  CODE_GENERATED_FROM_OTHER = "CODE_GENERATED_FROM_OTHER",
}

export type Product = {
  id?: string;
  type: ProductType;
  name: string;
  brand: string;
  productCode: string;
  cfCode: string;
  description: string;
  unit: string;
  properties: Array<ProductPropertiesType>;
  isCustomSKU: boolean; // all product must contain 1 sku
  images: Array<ProductImagesType>;
  defaultPrice: number;
  productSKUs: Array<ProductSKUType>;
  weight?: number;
  dimension?: Array<number> | [] | null[]; // using [] in frontend when init form
  // #region for digital content type
  contentType?: DigitalContentProductType | string;
  outputType?: ProductOutputType;
  additionalDescription?: string;
  // #endregion

  isOutOfStock?: boolean; // optional flag using for format data
};

export type ProductSKU = {
  id: string;
  productCode: string;
  cfCode: string;
  price: number;
  image: string;
  selected: boolean;
  deleted: boolean;
  isFree: boolean;
  productType: Array<ProductTypeType>;
  inventory: number;
  reserved: number;
  createdAt: string;
  updatedAt: string;
  productName: string;
  productImages: Array<ProductImageType>;
  productId: string;
  options?: ProductOptionType[];
  weight: number;
  dimension?: Array<number> | [] | null[]; // using [] in frontend when init form
  // for digital content type
  digitalContentOptions?: DigitalContentOptions;
};

export type NewProductSKU = {
  id: string;
  productCode: string;
  cfCode: string;
  price: number;
  image: ProductImageType;
  selected: boolean;
  deleted: boolean;
  isFree: boolean;
  isChecked?: boolean;
  productType: Array<ProductTypeType>;
  inventory: number;
  reserved: number;
  productId: string;
  weight: number;
  dimension?: Array<number> | [] | null[]; // using [] in frontend when init form
};

type DigitalContentAvailabilitySettings = {
  unit: string;
  value: number;
  availableAt: string;
  expiredAt: string;
};

type DigitalContentAvailabilityType = {
  type: DigitalContentAvailability;
  settings: DigitalContentAvailabilitySettings;
};

export type DigitalContentOptions = {
  outputType: ProductOutputType;
  settings: DigitalContentSettings;
};

export type BaseDigitalContentSettings = {
  availability: DigitalContentAvailabilityType;
};

export type StaticDigitalContentSettings = BaseDigitalContentSettings & {
  content: string;
};

export type DigitalContentSettings = BaseDigitalContentSettings | StaticDigitalContentSettings;

export type DigitalContent = {
  id: string;
  projectId: string;
  orderId: string;
  productSKUId: string;
  outputType: ProductOutputType;
  content: string; // can be code or static
  refId: string;
  isSold: boolean; // flag for sold code
  isUsed: boolean; // flag when admin marks digital content code when used
  createdAt: string;
  updatedAt: string;
  soldAt: string;
  usedAt: string;
  availableAt?: string; // can be null on static type
  expiredAt?: string; // can be null on static type
};

export type SummaryType = {
  productCode: string;
  value: number;
};

export enum UpdateInventoryType {
  ADD = "ADD",
  REPLACE = "UPDATE",
}

export type TotalWareHouseQuery = {
  totalWareHouse: { totalCategory: number; totalProduct: number; totalProductSKU: number };
};

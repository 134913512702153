import CreditCard from "components/CreditCard";
import Grid from "components/Grid";
import { formatToReadablePrice } from "components/Message/utils/promotionUtil";
import InformationModal from "components/Modal/InformationModal";
import LoadingModal from "components/Modal/Loading";
import PageTitle from "components/PageTitle";
import StatusBox from "components/StatusBox";
import Typography from "components/Typography";
import COLORS from "constants/Colors";
import { DATE_INVOICE_FORMAT } from "constants/DateTimeFormat";
import { CREDIT_TRANSACTION_TYPES, CREDIT_TRANSACTION_TYPES_WITHOUT_LALAMOVE } from "constants/Wallet";
import moment from "moment";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { BillingError } from "types/Billing";
import { ModalStatus } from "types/Common";
import { InvoiceTypes } from "types/invoice";
import { PackageEntityLevel, ProjectIdType } from "types/Project";
import useBillingOverdue from "utils/hooks/useBillingOverdue";
import useGetProject from "utils/hooks/useGetProject";
import useGetUserWithPermission from "utils/hooks/useGetUserWithPermission";
import useIsDesktop from "utils/hooks/useIsDesktop";
import CreditCardList from "./CreditCardList";
import useBillingPackage from "./hooks/useBillingPackage";
import useCreditCard from "./hooks/useCreditCard";
import ModalAddCreditCard from "./ModalAddCreditCard";
import Addon from "./Plan/PlanBilling/Addon";
import BillingInfo from "./Plan/PlanBilling/BillingInfo";
import InvoiceHistory from "./Plan/PlanBilling/InvoiceHistory";
import PaymentSummary from "./Plan/PlanBilling/PaymentSummary";
import { CardWrapper } from "./styled";
import useTotalCredit from "./Wallet/hooks/useTotalCredit";
import { RectangleButton } from "./Wallet/styled";
import WalletCard from "./Wallet/WalletCard";
import WalletTransaction from "./Wallet/WalletTransaction";

export interface BillingOverdueProps {
  amount: number;
  billingDate: string;
}

const BillingOverdue: FC<BillingOverdueProps> = ({ amount, billingDate }) => {
  const { t } = useTranslation();

  return (
    <Grid item xs={12} className="pb-2">
      <StatusBox>
        <Typography variant="body3" color={COLORS.Error}>
          {t("billing.billingOverdue.overdueInstruction", {
            billingDate: moment(billingDate).format(DATE_INVOICE_FORMAT),
          })}
        </Typography>
        <Grid className="d-flex" alignItems="center">
          <Typography variant="title8" color={COLORS.Error}>
            ฿{formatToReadablePrice(amount)}
          </Typography>
        </Grid>
      </StatusBox>
    </Grid>
  );
};

const Billing: FC = () => {
  const { t } = useTranslation();
  const [isShowModalAddCreditCard, setIsShowModalAddCreditCard] = useState(false);
  const [selectedType, setSelectedType] = useState<InvoiceTypes>(InvoiceTypes.MAIN);
  const [informationModalType, setInformationModalType] = useState<ModalStatus | null>(null);
  const [errorRepayMessage, setErrorRepayMessage] = useState<BillingError | string>("");
  const isDesktop = useIsDesktop();
  const { projectId } = useParams<ProjectIdType>();
  const { refetchGetUserWithPermission } = useGetUserWithPermission(projectId);
  const { isDisabled, monthlyBillingPaymentIssueType, packageEntityLevel, refetchProject } = useGetProject(projectId);
  const hasProjectIssue = monthlyBillingPaymentIssueType;

  const handleSuccessRepay = () => {
    refetchProject();
    refetchCreditCard();
    refetchGetUserWithPermission();
    refetchBillingOverdue();
    setInformationModalType(ModalStatus.SUCCESS);
  };

  const handleErrorRepay = (errorMessage: BillingError) => {
    refetchProject();
    refetchCreditCard();
    setInformationModalType(ModalStatus.FAIL);
    setErrorRepayMessage(errorMessage);
  };
  const { repayBilling, isLoadingRepay } = useBillingPackage({
    projectId,
    onCompletedRepay: handleSuccessRepay,
    onErrorRepay: handleErrorRepay,
  });
  const handleSuccessAddCreditCard = () => {
    if (hasProjectIssue) {
      repayBilling();
    }
  };
  const { creditCard, isLoadingAddCreditCard, isLoadingCreditCard, addCreditCard, refetchCreditCard } = useCreditCard(
    projectId,
    handleSuccessAddCreditCard,
  );

  const { credits, isShowLogisticWallet } = useTotalCredit({ projectId, type: selectedType });

  const handleFocusWallet = (type: InvoiceTypes) => {
    setSelectedType(type);
  };
  const { overdue, refetchBillingOverdue } = useBillingOverdue(projectId);

  const desktopFilterType = isShowLogisticWallet ? CREDIT_TRANSACTION_TYPES : CREDIT_TRANSACTION_TYPES_WITHOUT_LALAMOVE;

  return (
    <Grid container alignContent="flex-start" alignItems="flex-start" className={`pb-5 ${isDesktop ? "px-4" : ""}`}>
      <Grid item xs={12} className="my-2">
        <PageTitle title={t("planBilling.header")} />
      </Grid>

      <>
        <Grid item container className="px-3">
          {desktopFilterType.map((type: InvoiceTypes) => {
            const isSelected = type === selectedType;

            return (
              <RectangleButton key={type} isSelected={isSelected} onClick={() => handleFocusWallet(type)}>
                <Typography variant="title8" color="darkMed">
                  {t(`billing.button.${type.toLowerCase()}`)}
                </Typography>
              </RectangleButton>
            );
          })}
        </Grid>
        <Grid item container xs={12} className={isDesktop ? "px-3" : ""}>
          <CardWrapper item xs={12} isDesktop={isDesktop}>
            {selectedType === InvoiceTypes.MAIN && (
              <Grid item xs={12} className="mt-3 px-2 px-md-0">
                {/* LEANBIZ_TEMP_DELETE remove credit card input for now */}
                {/* <CreditCard
                  onClick={() => setIsShowModalAddCreditCard(true)}
                  isViewInfo={!!creditCard}
                  {...creditCard}
                  loading={isLoadingAddCreditCard || isLoadingCreditCard}
                /> */}
              </Grid>
            )}

            {Boolean(credits[0].credit) && (
              <Grid item xs={12} className="mt-3">
                <WalletCard type={selectedType} total={credits[0].credit} />
              </Grid>
            )}

            {selectedType === InvoiceTypes.MAIN && !!creditCard && (
              <Grid item xs={12} className="mt-3">
                <CreditCardList hasProjectIssue={hasProjectIssue} onClickRepayBilling={repayBilling} />
              </Grid>
            )}

            {selectedType === InvoiceTypes.MAIN && (
              <Grid item xs={12} className="mt-3">
                <BillingInfo />
              </Grid>
            )}
          </CardWrapper>

          {/* width flex 1 for desktop */}
          <Grid item xs={12} style={{ flex: "1" }} className={isDesktop ? "mt-3 px-3" : "mt-3"}>
            {selectedType === InvoiceTypes.LOGISTIC ? (
              <Grid item xs={12}>
                <WalletTransaction projectId={projectId} />
              </Grid>
            ) : (
              <>
                {overdue?.isOverdue ? (
                  <BillingOverdue amount={overdue.amount} billingDate={overdue.billingDate} />
                ) : null}

                <Grid item xs={12} className="pb-3">
                  <PaymentSummary isDisabled={isDisabled} />
                </Grid>

                {packageEntityLevel !== PackageEntityLevel.FREE && (
                  <Grid item xs={12} className="pb-3">
                    <Addon />
                  </Grid>
                )}

                <Grid item xs={12} className="pb-3">
                  <InvoiceHistory />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </>

      <ModalAddCreditCard
        isOpen={isShowModalAddCreditCard}
        onClose={() => setIsShowModalAddCreditCard(false)}
        addCreditCard={addCreditCard}
        isLoadingAddCreditCard={isLoadingAddCreditCard}
      />
      <LoadingModal isOpen={isLoadingRepay} description="now is loading" />
      {informationModalType && (
        <InformationModal
          type={informationModalType}
          isOpen
          onClose={() => setInformationModalType(null)}
          description={
            informationModalType === ModalStatus.FAIL
              ? t("billing.repay.informationModal.error", { errorMessage: errorRepayMessage })
              : t("billing.repay.modal.successful")
          }
        />
      )}
    </Grid>
  );
};

Billing.displayName = "Billing";

export default Billing;

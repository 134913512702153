import React, { FC } from "react";
import get from "lodash/get";
import { getTotalSelectedOptionsPrice } from "@leanbizai/shopping-cart-calculator";

import Grid from "components/Grid";
import {
  formatToReadablePrice,
  getPromotionDisplayNameForFlexMessage,
  getPromotionTotalDiscountForProductSKU,
  sortPromotionByTypeAndUpdatedAt,
} from "components/Message/utils/promotionUtil";
import Typography from "components/Typography";
import { COLORS } from "constants/Colors";
import { NO_IMG_AVAILABLE } from "constants/Image";
import { ProductInOrderType, ProductTypeType } from "types/Order";
import { ProductSKUType } from "types/Product";
import getProductOptions from "utils/order/getProductOptions";
import { PromotionType } from "types/Promotion";
import { AmountWrapper, ProductSKUImageSmall } from "../FlexMessage.style";

type FlexOrderProductSKUWithOrderProductsPropsType = {
  groupedPromotionBySKUId: unknown;
  orderProducts: ProductInOrderType[];
  productPromotions: PromotionType[];
};

const FlexOrderProductSKUsWithOrderProducts: FC<FlexOrderProductSKUWithOrderProductsPropsType> = (props) => {
  const { groupedPromotionBySKUId, orderProducts, productPromotions } = props;

  return (
    <>
      {orderProducts.map((orderProduct: ProductInOrderType) => {
        const { product, productSKU } = orderProduct;
        const productTypes: ProductTypeType[] = get(product, "productType") || [];

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const totalSelectedOptionsPrice = getTotalSelectedOptionsPrice(orderProduct.options as any);

        const rawProductPrice =
          (Number(orderProduct.productPrice) + Number(totalSelectedOptionsPrice)) * Number(orderProduct.amount);

        const productPrice = orderProduct.isFree ? "ฟรี" : `฿${formatToReadablePrice(rawProductPrice)}`;

        const productSKUPromotions = get(groupedPromotionBySKUId, `${productSKU.id}`, []).sort(
          sortPromotionByTypeAndUpdatedAt,
        );

        const productSKUPromotion = productSKUPromotions[0];

        const promotionName = getPromotionDisplayNameForFlexMessage(productSKUPromotion);

        const totalDiscount = getPromotionTotalDiscountForProductSKU(
          (orderProduct.productSKU as unknown) as ProductSKUType,
          productPromotions,
        );

        let priceAfterDiscount = Number(rawProductPrice) - Number(totalDiscount);

        if (priceAfterDiscount < 0) {
          // to make sure product price will be more than or equal 0
          priceAfterDiscount = 0;
        }

        const isShowNewPriceStyle = !!totalDiscount;
        /**
         * if show new price that mean show new price per unit
         * but if no and there is totalDiscount from promotion then show discount
         */
        const priceToShow = isShowNewPriceStyle ? priceAfterDiscount : totalDiscount;

        return (
          <Grid
            key={productSKU.id}
            container
            justify="flex-start"
            alignItems="flex-start"
            alignContent="flex-start"
            className="d-flex pb-2"
          >
            <Grid item>
              <ProductSKUImageSmall
                src={((productSKU.image as unknown) as string) || get(product, "images[0].src") || NO_IMG_AVAILABLE}
                className="pr-1"
              />
            </Grid>
            <Grid item className="" style={{ flex: 1 }}>
              <Grid
                container
                direction="column"
                alignItems="flex-start"
                alignContent="flex-start"
                // className="wordBreakAll"
              >
                <Typography variant="title10" color={COLORS.Dark} className=" text-left py-1 wordBreakAll">
                  {product.name}
                </Typography>

                <Grid item container justify="space-between" alignItems="center" alignContent="flex-start">
                  <Grid item container justify="space-between" alignItems="center" alignContent="flex-start">
                    <Grid item xs={2}>
                      <AmountWrapper className="text-center" style={{ fontSize: "10px" }}>
                        {`${orderProduct.amount}x`}
                      </AmountWrapper>
                    </Grid>

                    <Grid item xs={5}>
                      <Grid item className="pl-2">
                        <Typography
                          variant="body4"
                          color={COLORS.Dark}
                          className="text-left py-1 hiddenOverFlowText"
                          style={{ flex: 1 }}
                        >
                          {productTypes.length
                            ? `${productTypes.map((productType: ProductTypeType) => productType.value).join(", ")}`
                            : ""}
                        </Typography>

                        {orderProduct.options && orderProduct.options.length > 0 && (
                          <Typography variant="body4" color={COLORS.Dark} className="text-left py-1 hiddenOverFlowText">
                            {getProductOptions(orderProduct.options || [])}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>

                    <Grid item xs={5}>
                      <Typography variant="title10" color={COLORS.Primary} className="text-right py-1">
                        {priceToShow ? (
                          <>
                            <div style={{ color: COLORS.DarkLight, textDecoration: "line-through" }}>
                              {productPrice}
                            </div>
                            <div>{`฿${formatToReadablePrice(priceToShow)}`}</div>
                          </>
                        ) : (
                          <div>{productPrice}</div>
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {orderProduct.note && (
                <Typography variant="body5" color={COLORS.Dark} className="text-left py-1 hiddenOverFlowText">
                  {orderProduct.note}
                </Typography>
              )}
              {promotionName && (
                <Typography variant="body5" color={COLORS.Error} className="text-left pt-1">
                  {promotionName}
                </Typography>
              )}
            </Grid>
          </Grid>
        );
      })}
    </>
  );
};

export default FlexOrderProductSKUsWithOrderProducts;

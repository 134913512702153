import React, { useState, useContext, useEffect, FC, useCallback, memo } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { useHistory, useLocation, useParams } from "react-router-dom";
import get from "lodash/get";
import clsx from "clsx";
import moment from "moment";
import "moment/locale/th";
import { Utilities } from "@leanbizai/web-console";
import { Button, IconStoreSetting, ProgressBar, AlertOrderApproval, useAlertOrderApproval } from "@leanbizai/deeple-ui";

import AppContainer from "components/AppContainer";
import SideNav from "components/SideNav";
import TopNav from "components/TopNav";
import BroadcastWarning from "components/WarningCard/BroadcastWarning";
import { DEFAULT_AVATAR_IMG } from "constants/Image";
import { PROJECT, PLAN, BILLING, PLAN_UPGRADE, START_PAGE } from "constants/Router";
import { FLAG_USER_WITH_IS_ENTERPRISE } from "graphql/auth/query";
import { UserProfileType } from "types/User";
import { SideNavVariant } from "types/SideNav";
import { PackageEntityLevel, ProjectIdType } from "types/Project";
import useCurrentPage from "routers/useCurrentPage";
import { GuidelineContext, VideoPopupContext } from "utils/context";
import useIsDesktop from "utils/hooks/useIsDesktop";
import useLogout from "utils/hooks/useLogout";
import useGetUserWithPermission from "utils/hooks/useGetUserWithPermission";

import VideoPopup from "components/VideoPopup";
import useGuideline from "hooks/useGuideline";

import { IS_ALLOW_NEW_PACKAGE } from "config";
import { isRunningOnMobileApp } from "utils/mobileUtil";
import { Main, Wrapper } from "./styled";
import useGetProject from "../../utils/hooks/useGetProject";
import { getProgressBarProps } from "./helpers";

window.getProjectId = () => window.projectId;
window.getParentUseHistory = () => useHistory;

const MemorizedAlertOrderApproval = memo(AlertOrderApproval);

const LayoutWithNavbar: FC = ({ children }) => {
  const isDesktop = useIsDesktop();
  const { projectId } = useParams<ProjectIdType>();
  window.projectId = projectId;
  const { logout } = useLogout();

  const { isDisabled, isOwner } = useGetUserWithPermission(projectId);
  const {
    monthlyBillingPaymentIssueType,
    isUsingNotSupportedPackage,
    packageEntityLevel,
    // isDeeplePayEnabled,
  } = useGetProject(projectId);
  const { videoProps, setVideoProps } = useContext(VideoPopupContext);
  const { currentPage } = useCurrentPage();
  const {
    setGuidelineCompletedStepCount,
    resetGuidelineCompletedStepCount,
    isShowBroadcastQuickStart,
    isStartPage,
    guidelineCompletedStepCount,
  } = useContext(GuidelineContext);

  const { i18n, t } = useTranslation();
  const { push } = useHistory();
  const location = useLocation();
  const { hasConnect, hasProducts } = useGuideline(projectId);

  const [isOpenSideNav, setIsOpenSideNav] = useState(false);
  const [isProjectListToggled, setIsProjectListToggled] = useState(false);

  const { data, loading } = useQuery<UserProfileType>(FLAG_USER_WITH_IS_ENTERPRISE, { variables: { projectId } });
  const menuId = "main-menu";

  const currentUser = data && data.me;
  const isLoggedin = Boolean(currentUser);
  const isEmailVerified = Boolean(currentUser?.isEmailVerified);
  const permissions = currentUser && currentUser.permissions;

  const billingPage = `/${PROJECT}/${projectId}/${PLAN}/${BILLING}`;
  const upgradePlanPage = `/${PROJECT}/${projectId}/${PLAN}/${PLAN_UPGRADE}`;
  const isOnBillingPage = location.pathname === billingPage;
  const isUpgradePlan = location.pathname === upgradePlanPage;

  const handleClickRedirectToPlanWallet = () => {
    const planWalletRoute = `/${PROJECT}/${projectId}/${PLAN}/${BILLING}`;

    if (location.pathname !== planWalletRoute) {
      push(planWalletRoute);
    }
  };

  const changeLanguage = (lang: string) => {
    const lowerCaseLanguage: string = lang.toLowerCase();

    if (i18n.language === lowerCaseLanguage) {
      return;
    }

    i18n.changeLanguage(lowerCaseLanguage, () => moment.locale(lowerCaseLanguage));
    Utilities.changeLanguage(lowerCaseLanguage);
  };

  const onClickTopNav = (isOpenInput: boolean) => {
    setIsOpenSideNav(isOpenInput);
  };

  const onLogout = useCallback(async () => {
    await logout();
    resetGuidelineCompletedStepCount();
  }, [logout, resetGuidelineCompletedStepCount]);

  const onClickProjectList = () => {
    resetGuidelineCompletedStepCount();
    setIsOpenSideNav(false);
    setIsProjectListToggled(false);
  };

  const onClickMenuList = () => {
    setIsOpenSideNav(false);
  };

  const onToggleProjectList = () => {
    setIsProjectListToggled(!isProjectListToggled);
  };

  const onCloseSideNav = () => {
    setIsProjectListToggled(false);
    setIsOpenSideNav(false);
  };

  useEffect(() => {
    moment.locale(i18n.language);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loading) {
      return;
    }

    if (currentUser) {
      changeLanguage(currentUser.language);
    }

    if (isEmailVerified) {
      setGuidelineCompletedStepCount(projectId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const avatar = get(data, "me.avatar") || DEFAULT_AVATAR_IMG;
  const email = get(data, "me.email", "");
  const displayName =
    get(data, "me.displayName", "") ||
    // if no displayName. We will use the name before @ of email.
    email.split("@")[0];
  const isShowSideNav = isEmailVerified && projectId && currentPage !== PROJECT;
  const isAllowedToCreateProject = get(data, "me.isAllowedToCreateProject", false);

  const displayUpgradeButton = packageEntityLevel === PackageEntityLevel.FREE && !isRunningOnMobileApp();

  const { pathname } = useLocation();

  const progressBarProps = getProgressBarProps(pathname, projectId, hasProducts, hasConnect, t);
  const alertOrderApproval = useAlertOrderApproval({ projectId, navigate: push });
  return (
    <>
      <AppContainer>
        <TopNav
          avatar={avatar}
          displayName={displayName}
          isActive={isOpenSideNav}
          onToggle={onClickTopNav}
          menuId={menuId}
          permissions={permissions}
          onClickProjectList={onClickProjectList}
          videoProps={videoProps}
          setVideoProps={setVideoProps}
          onLogout={onLogout}
          displayUpgradeButton={displayUpgradeButton}
        />

        <div className="d-flex" style={{ flex: 1, overflowY: "auto" }}>
          {isShowSideNav && (
            <SideNav
              avatar={avatar}
              displayName={displayName}
              id={menuId}
              isOpen={isOpenSideNav}
              isOwner={isOwner}
              isProjectListToggled={isProjectListToggled}
              onClickMenuList={onClickMenuList}
              onClickProjectList={onClickProjectList}
              onClose={onCloseSideNav}
              onLogout={onLogout}
              onToggleProjectList={onToggleProjectList}
              permissions={permissions}
              isAllowedToCreateProject={isAllowedToCreateProject}
              variant={!isDesktop ? SideNavVariant.TEMPORARY : SideNavVariant.PERMANENT}
              displayUpgradeButton={displayUpgradeButton}
            />
          )}
          <Main id="pageMainContainer" className={clsx({ isShowSideNav })}>
            {isLoggedin && projectId && isShowBroadcastQuickStart && (
              <div style={{ margin: "24px" }}>
                <ProgressBar
                  Icon={<IconStoreSetting width={32} height={32} />}
                  activeStep={guidelineCompletedStepCount}
                  totalStep={2}
                  title={progressBarProps.title}
                  subtitle={progressBarProps.subtitle}
                  Button={
                    isStartPage ? null : (
                      <Button variant="outlined" onClick={() => push(`/project/${projectId}/${START_PAGE}`)}>
                        {t("Guideline.startPage")}
                      </Button>
                    )
                  }
                  isShowSubtitleProgress={progressBarProps.isShowSubtitleProgress}
                  isShowRemainingStep
                />
              </div>
            )}
            <MemorizedAlertOrderApproval {...alertOrderApproval} />
            {isLoggedin && projectId && (isDisabled || monthlyBillingPaymentIssueType) && (
              <BroadcastWarning
                isOnBillingPage={isOnBillingPage}
                isOwner={isOwner}
                isDisabled={isDisabled}
                monthlyBillingPaymentIssueType={monthlyBillingPaymentIssueType}
                onClickRedirect={handleClickRedirectToPlanWallet}
              />
            )}

            {/* for package warning */}
            {isLoggedin &&
              projectId &&
              !isDisabled &&
              !monthlyBillingPaymentIssueType &&
              IS_ALLOW_NEW_PACKAGE &&
              isUpgradePlan &&
              isUsingNotSupportedPackage && (
                <BroadcastWarning isUsingNotSupportedPackage={isUsingNotSupportedPackage} />
              )}

            <Wrapper>{children}</Wrapper>
          </Main>
        </div>
      </AppContainer>
      <VideoPopup videoProps={videoProps} setVideoProps={setVideoProps} />
    </>
  );
};

export default LayoutWithNavbar;

import {
  PromotionType as ShoppingCartPromotionType,
  ShoppingCartCalculator,
} from "@leanbizai/shopping-cart-calculator";
import get from "lodash/get";
import { chain } from "lodash";

import { getProductSKUsKeyValueFromProductSKUs } from "utils/common";
import { MESSAGE_TYPES } from "constants/Chat";
import { NO_IMG_AVAILABLE } from "constants/Image";
import { Product, ProductSKUType, ProductType, ProductTypeType } from "types/Product";
import { PromotionType } from "types/Promotion";
import {
  getProductPriceLineFlex,
  getRelatedPromotionByProductSKUIds,
  getRelatedPromotionForProductHeaderBadge,
  groupPromotionsByProductSKUId,
  normalizeProductTypeFromSKU,
} from "./promotionUtil";

const MAXIMUM_FLEX_ELEMENTS_LENGTH = 10;

export function verifyProductIsOutOfStock(product: Product) {
  // every stockAmount or inventory sku is zero
  return product.productSKUs.every((productSKU: ProductSKUType) => {
    const inventory = get(productSKU, "stockAmount") || get(productSKU, "inventory") || 0;
    return !inventory;
  });
}

export function normalizeProductSKUsByProduct(product: Product) {
  if (Array.isArray(get(product, "productSKUs.0.productType"))) {
    return normalizeProductTypeFromSKU(product.productSKUs);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return product.productSKUs.reduce((accumulatedProductSKU: any, currentProductSKU: any) => {
    const productSKUs = accumulatedProductSKU;
    Object.keys(currentProductSKU.productType).forEach((type) => {
      productSKUs[type] = [...(productSKUs[type] || []), currentProductSKU.productType[type]];
    });

    return productSKUs;
  }, {});
}

export const formatProductDataToFlexFormat = (data: { products: Product[] | []; promotions: PromotionType[] | [] }) => {
  const products = data?.products || [];
  const promotions = data?.promotions || [];

  /* eslint-disable @typescript-eslint/no-explicit-any */
  const filteredProducts: any = chain(products)
    .map((product: Product) => {
      const isOutOfStock = verifyProductIsOutOfStock(product);
      return { ...product, isOutOfStock };
    })
    .sortBy((product: Product) => product.isOutOfStock)
    .take(MAXIMUM_FLEX_ELEMENTS_LENGTH - 1) // threshold
    .value();

  return [
    ...filteredProducts.map((product: Product) => {
      const productSKUs = normalizeProductSKUsByProduct(product);
      const productSKUsKeys = Object.keys(productSKUs);

      /**
       * 4 items for product that has 1 SKU type
       * and 3 items for product that has 2 SKU types
       */
      const maximumItemForShowProductSKU = productSKUsKeys.length === 2 ? 4 : 5;
      // show new UI if product has product SKU between 2 - 4 products (2-3 product for product that has 2 SKU types)
      const isShowProductSKUEachRow =
        product.productSKUs.length > 1 && product.productSKUs.length < maximumItemForShowProductSKU;

      // ProductItemPropsType
      const priceArray = product.productSKUs.map((sku: ProductSKUType) => sku.price);

      const minPrice = Math.min(...priceArray);
      const maxPrice = Math.max(...priceArray);

      const actions = product.isOutOfStock ? "สินค้าหมด" : "เลือกสินค้า";

      const productSKUsGroupData = product.productSKUs
        ? getProductSKUsKeyValueFromProductSKUs(product.productSKUs)
        : [];

      const productType = get(product, "type", ProductType.SKU);
      let filteredPromotions = promotions;
      if (productType === ProductType.DIGITAL_CONTENT) {
        filteredPromotions = promotions.filter(
          (promotion: PromotionType) => promotion.type !== ShoppingCartPromotionType.SHIPPING,
        );
      }

      const productSKUsForFindRelatedPromotion = product.productSKUs;
      const productSKUIds: string[] = productSKUsForFindRelatedPromotion.map(
        (productSKU: ProductSKUType) => `${productSKU.id}`,
      );
      const relatedPromotion = getRelatedPromotionByProductSKUIds(productSKUIds, filteredPromotions);
      const promotionHeader = getRelatedPromotionForProductHeaderBadge(relatedPromotion);
      const promotionHeaderShortName = promotionHeader ? promotionHeader.shortName : "";
      const promotionHeaderName = promotionHeader ? promotionHeader.description || promotionHeader.name : "";
      /* eslint-disable @typescript-eslint/no-explicit-any */
      const getPriceAfterDiscount = (productSKU: any, productSKUPromotions: any) => {
        // pretend that product SKU alway in stock to calculate discount price
        const shoppingCartCalculator = new ShoppingCartCalculator([
          { ...productSKU, id: `${productSKU.id}`, amount: 1, inventory: 999 },
        ]);
        const cartSummary = shoppingCartCalculator
          .setBulkPromotionWithPromotionProperties(productSKUPromotions)
          .calculate();
        const { grandTotal } = cartSummary;

        return grandTotal;
      };

      const groupedPromotionBySKUId = groupPromotionsByProductSKUId(filteredPromotions);

      const defaultMinPrice = Math.min(...priceArray).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      const defaultMaxPrice = Math.max(...priceArray).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

      let priceAfterDiscountText = "";
      let productMenu =
        get(product, "type") === ProductType.SINGLE_SKU_WITH_OPTIONS ? `[เลือก]` : `[ดูข้อมูลเพิ่ม],[${actions}]`;

      const defaultProductPriceText =
        defaultMinPrice === defaultMaxPrice
          ? `฿${defaultMinPrice.split(".")[0]}.${defaultMinPrice.split(".")[1]}`
          : `฿${defaultMinPrice.split(".")[0]}.${defaultMinPrice.split(".")[1]} - ฿${defaultMaxPrice.split(".")[0]}.${
              defaultMaxPrice.split(".")[1]
            }`;

      let displayProductSKU = productSKUsGroupData.map((productSKU) => {
        return {
          type: MESSAGE_TYPES.PRODUCT_SKU,
          value: `${productSKU.key} : ${productSKU.value.map((value: string) => ` ${value}`)}`,
        };
      });

      // show SINGLE_SKU_WITH_OPTIONS style( only button that open webview )
      if (product.type === ProductType.SINGLE_SKU_WITH_OPTIONS) {
        let priceAfterDiscount = 0;

        // show discounted price only if product has 1 SKU
        if (product.productSKUs && product.productSKUs.length === 1) {
          const productSKU = get(product, "productSKUs.0", {});
          const productSKUPromotions = get(groupedPromotionBySKUId, `${productSKU.id}`, []);

          priceAfterDiscount = getPriceAfterDiscount(productSKU, productSKUPromotions);
        }
        const newPriceWithDiscount = getProductPriceLineFlex(
          Number(minPrice),
          Number(maxPrice),
          Number(priceAfterDiscount),
        );
        if (newPriceWithDiscount) {
          priceAfterDiscountText = newPriceWithDiscount;
        }
      } // return product SKU each row
      else if (isShowProductSKUEachRow) {
        productMenu = `[ดูข้อมูลเพิ่ม]`;
        const displayProductSKUData: { type: MESSAGE_TYPES; value: string }[] = [];
        product.productSKUs.forEach((productSKU: ProductSKUType) => {
          const productPrice = Number(productSKU.price);
          const productSKUPromotions = get(groupedPromotionBySKUId, `${productSKU.id}`, []);

          const isProductSKUsInStock =
            (productSKU.inventory && productSKU.inventory > 0) ||
            (productSKU.stockAmount && productSKU.stockAmount > 0);
          const productTypes = productSKU.productType;

          let productTypeString = productTypes
            .map((productVariableType: ProductTypeType) => {
              return `${get(productVariableType, "key.key")} : ${productVariableType.value}`;
            })
            .join(" ");

          if (isProductSKUsInStock) {
            productTypeString += "action[เลือก]";
          } else {
            productTypeString += "action[สินค้าหมด]";
          }

          const promotionDescription = productSKUPromotions?.[0]?.description;
          const promotionName = productSKUPromotions?.[0]?.name;
          const priceAfterDiscount = getPriceAfterDiscount(productSKU, productSKUPromotions);

          displayProductSKUData.push({
            type: MESSAGE_TYPES.PRODUCT_SKU_WITH_ACTION,
            value: `${productTypeString}`,
          });

          displayProductSKUData.push({
            type: MESSAGE_TYPES.PRODUCT_SKU_PRICE,
            value: `฿${Number(productPrice).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}${
              priceAfterDiscount > 0 && priceAfterDiscount !== productPrice
                ? `-฿${Number(priceAfterDiscount).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}`
                : ""
            }`,
          });

          if (promotionDescription || promotionName) {
            displayProductSKUData.push({
              type: MESSAGE_TYPES.PRODUCT_SKU_PROMOTION,
              value: `${promotionDescription || promotionName || ""}`,
            });
          }
        });

        displayProductSKU = displayProductSKUData;
      } else {
        // return all SKU text
        let priceAfterDiscount = 0;
        // show discounted price only if product has 1 SKU
        if (product.productSKUs && product.productSKUs.length === 1) {
          const productSKU = get(product, "productSKUs.0", {});
          const productSKUPromotions = get(groupedPromotionBySKUId, `${productSKU.id}`, []);
          priceAfterDiscount = getPriceAfterDiscount(productSKU, productSKUPromotions);
        }
        const newPriceWithDiscount = getProductPriceLineFlex(
          Number(minPrice),
          Number(maxPrice),
          Number(priceAfterDiscount),
        );
        if (newPriceWithDiscount) {
          priceAfterDiscountText = newPriceWithDiscount;
        }
      }

      return {
        id: `${product.id}`,
        isUnavailable:
          (product.productSKUs && !product.productSKUs.length) ||
          product.productSKUs.every((productSKU: ProductSKUType) => productSKU.selected === false),
        isSingleProductSKUOutOfStock: product.productSKUs && product.productSKUs.length === 1 && !!product.isOutOfStock,
        type: "FLEX",
        value: [
          {
            type: MESSAGE_TYPES.PRODUCT_IMAGE,
            value: get(product, "images.0.src") || NO_IMG_AVAILABLE,
          },
          {
            type: MESSAGE_TYPES.PRODUCT_NAME,
            value: get(product, "name", "product name"),
          },
          !isShowProductSKUEachRow && {
            type: MESSAGE_TYPES.PRODUCT_DISCOUNT_PRICE,
            value: priceAfterDiscountText,
          },
          !isShowProductSKUEachRow && {
            type: MESSAGE_TYPES.PRODUCT_PRICE,
            value: defaultProductPriceText,
          },
          ...displayProductSKU,
          {
            type: MESSAGE_TYPES.PRODUCT_MENU,
            value: productMenu,
          },
          promotionHeaderShortName && {
            type: MESSAGE_TYPES.PRODUCT_PROMOTION_BADGE,
            value: promotionHeaderShortName,
          },
          !isShowProductSKUEachRow &&
            promotionHeaderName && {
              type: MESSAGE_TYPES.PRODUCT_PROMOTION_NAME,
              value: promotionHeaderName,
            },
        ],
      };
    }),
  ];
};

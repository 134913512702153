type StoreDetailForm = {
  name: string;
  label: string;
  placeholder: string;
  icon: string;
};

export const STORE_DETAIL_FORMS: StoreDetailForm[] = [
  {
    name: "subDistrict",
    label: "Sub-district",
    placeholder: "",
    icon: "",
  },
  {
    name: "district",
    label: "District",
    placeholder: "",
    icon: "",
  },
  {
    name: "city",
    label: "City",
    placeholder: "",
    icon: "",
  },
  {
    name: "postcode",
    label: "Postcode",
    placeholder: "",
    icon: "",
  },
  {
    name: "phoneNumber",
    label: "Phone number",
    placeholder: "",
    icon: "",
  },
  {
    name: "facebookLink",
    label: "",
    placeholder: "Facebook",
    icon: "/assets/icon/ic-facebook-color@3x.png",
  },
  {
    name: "instagramLink",
    label: "",
    placeholder: "Instagram",
    icon: "/assets/icon/ic-instagram-color@3x.png",
  },
  {
    name: "twitterLink",
    label: "",
    placeholder: "Twitter",
    icon: "/assets/icon/ic-x.png",
  },
  {
    name: "websiteLink",
    label: "",
    placeholder: "Website",
    icon: "/assets/icon/ic-website-color@3x.png",
  },
];

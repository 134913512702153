import React from "react";
import { ToastContainer } from "react-toastify";
import { AppRouter } from "routers";
import GlobalStyledComponent from "styles/GlobalStyledComponent";
import { LoggerService } from "services";
import { updateLocale } from "utils/moment";
import { ErrorProvider } from "utils/context";
import initializeFreshDesk from "utils/hooks/useInitFreshDesk";

import ContextProviders from "./ContextProviders";
import "@leanbizai/web-console/dist/style.css";
import "@leanbizai/deeple-ui/dist/style.css";

LoggerService.init();
updateLocale();

const INITIAL_AUTO_CLOSE_MODAL_IN_MILLISECOND = 5000;

const App: React.FC = () => {
  initializeFreshDesk();

  return (
    <ErrorProvider>
      <ContextProviders>
        <GlobalStyledComponent />
        <ToastContainer
          autoClose={INITIAL_AUTO_CLOSE_MODAL_IN_MILLISECOND}
          position="top-center"
          newestOnTop
          draggable={false}
        />
        <AppRouter />
      </ContextProviders>
    </ErrorProvider>
  );
};

export default App;

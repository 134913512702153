import React, { FC, memo } from "react";
import { useTranslation } from "react-i18next";
import { Control } from "react-hook-form";
import { Link } from "@material-ui/core";
import { ValueType, OptionType } from "react-select";
import get from "lodash/get";

import Card from "components/Card";
import Grid from "components/Grid";
import Typography from "components/Typography";
import { ControllerSelect } from "components/Select";
import ControllerSwitch from "components/Switch/ControllerSwitch";
import { SvgIcon } from "components/Icon";
import { IcInfo } from "components/SvgIcons";
import COLORS from "constants/Colors";
import { LALAMOVE_URL, SHIPPING_CALCULATE_OPTIONS } from "constants/Shipping";
import { CALCULATE } from "types/Shipping";

import { HandleChangeCalculateType } from "../type";
import { ErrorMessageLink } from "../../ErrorMessageLink";

type ShippingCalculateTypeCardProps = {
  calculateType: CALCULATE;
  errorFields?: string[];
  isReceiveOrderOutsideDeliveryTime: boolean;
  onChangeCalculateType: HandleChangeCalculateType;
  onToggleIsReceiveOrderOutsideDeliveryTime: () => void;
  projectId: string;
  control: Control;
};

const ShippingCalculateTypeCard: FC<ShippingCalculateTypeCardProps> = ({
  calculateType,
  errorFields,
  isReceiveOrderOutsideDeliveryTime,
  onChangeCalculateType,
  onToggleIsReceiveOrderOutsideDeliveryTime,
  projectId,
  control,
}) => {
  const { t } = useTranslation();

  const shippingCalculateOptions = SHIPPING_CALCULATE_OPTIONS;
  const defaultValueOption = shippingCalculateOptions.find(({ value }) => value === (calculateType as string));

  return (
    <Card fullWidth padding="24px">
      <Typography variant="body2" color={COLORS.DarkMed}>
        {t("Shipping calculate type")}
      </Typography>
      <Grid container>
        <Grid item xs={12}>
          <ControllerSelect
            name="calculateType"
            control={control}
            isSearchable={false}
            className="mt-3"
            value={defaultValueOption}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            defaultValue={calculateType as any}
            formatOptionLabel={(option: ValueType<OptionType>) => t(get(option, "label"))}
            onChange={onChangeCalculateType}
            options={shippingCalculateOptions}
            fullWidth
          />
        </Grid>
        {calculateType === CALCULATE.LALAMOVE && errorFields && (
          <Grid item xs={12} className="pt-3">
            {errorFields.map((errorField: string) => {
              return (
                <Grid item container alignContent="center" xs={12} className="pt-2" key={errorField}>
                  <ErrorMessageLink projectId={projectId} field={errorField} />
                </Grid>
              );
            })}
          </Grid>
        )}
        {calculateType === CALCULATE.LALAMOVE && (
          <>
            <Grid item xs={12} className="pt-3">
              <Card noShadow bgColor={COLORS.Light} noBorder>
                <Grid container className="flex-nowrap">
                  <Grid item className="pl-2 pr-4 d-flex flex-grow-0">
                    <SvgIcon component={IcInfo} fontSize="small" htmlColor={COLORS.DarkMed} className="m-0 p-0" />
                  </Grid>
                  <Grid item className="d-flex flex-grow-1">
                    <Typography variant="body3">{t("shipping.lalamove.remark")}</Typography>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} className="pt-4">
              <Grid container className="flex-nowrap">
                <Link href={LALAMOVE_URL} target="_blank" variant="body1">
                  {t("shipping.lalamove.price.rate")}
                </Link>
              </Grid>
            </Grid>
            <Grid item container xs={12} alignItems="center" className="pt-3">
              <Grid item>
                <ControllerSwitch
                  control={control}
                  name="isReceiveOrderOutsideDeliveryTime"
                  defaultValue={isReceiveOrderOutsideDeliveryTime}
                  onChange={onToggleIsReceiveOrderOutsideDeliveryTime}
                />
              </Grid>
              <Grid item className="pl-3">
                <Typography variant="body2" color={COLORS.DarkMed}>
                  {t("shipping.label.isReceiveOrderOutsideDeliveryTime")}
                </Typography>
              </Grid>
            </Grid>
          </>
        )}

        {calculateType === CALCULATE.SHIPPOP && (
          <>
            <Grid item xs={12} className="pt-3">
              <Card noShadow bgColor={COLORS.Light} noBorder>
                <Grid container className="flex-nowrap">
                  <Grid item className="pl-2 pr-4 d-flex flex-grow-0">
                    <SvgIcon component={IcInfo} fontSize="small" htmlColor={COLORS.DarkMed} className="m-0 p-0" />
                  </Grid>
                  <Grid item className="d-flex flex-grow-1">
                    <Typography variant="body3">{t("shipping.shippop.remark")}</Typography>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} className="pt-3">
              <Card noShadow bgColor={COLORS.Light} noBorder>
                <Grid container className="flex-nowrap">
                  <Grid item className="pl-2 pr-4 d-flex flex-grow-0">
                    <SvgIcon component={IcInfo} fontSize="small" htmlColor={COLORS.DarkMed} className="m-0 p-0" />
                  </Grid>
                  <Grid item className="d-flex flex-grow-1">
                    <Typography variant="body3">{t("shipping.shippop.integration.remark")}</Typography>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </>
        )}

        {calculateType === CALCULATE.DISTANCE_BASE_PRICE && (
          <Grid item xs={12} className="pt-3">
            <Card noShadow bgColor={COLORS.Light} noBorder>
              <Grid container className="flex-nowrap">
                <Grid item className="pl-2 pr-4 d-flex flex-grow-0">
                  <SvgIcon component={IcInfo} fontSize="small" htmlColor={COLORS.DarkMed} className="m-0 p-0" />
                </Grid>
                <Grid item className="d-flex flex-grow-1">
                  <Typography variant="body3">{t("shipping.distance.base.remark")}</Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default memo(ShippingCalculateTypeCard);

import { IcBroadcast, IcCampaign, IcStoreSettings, IcFacebookComment, IcFacebookLive } from "components/SvgIcons";
import { BROADCAST, CAMPAIGNS, TOP_SELLING, AUTO_ENGAGEMENT, FACEBOOK_LIVE } from "constants/Router";

export const TAB_CONFIGS = [
  {
    id: CAMPAIGNS,
    title: "Campaigns",
    svgIcon: IcCampaign,
  },
  {
    id: BROADCAST,
    title: "broadcast",
    svgIcon: IcBroadcast,
  },
  {
    id: AUTO_ENGAGEMENT,
    title: "facebookAutoEngagement.header",
    svgIcon: IcFacebookComment,
  },
  {
    id: FACEBOOK_LIVE,
    title: "facebookLive.header",
    svgIcon: IcFacebookLive,
  },
  {
    id: TOP_SELLING,
    title: "topSellingProduct.header",
    svgIcon: IcStoreSettings,
  },
];

/* eslint-disable no-nested-ternary */
import React, { lazy, Suspense } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { PageIntegration, LayoutDefault } from "@leanbizai/deeple-ui";

import IntegrationInputForm from "domain/Integration/IntegrationInputForm";
import {
  AI_SETTINGS,
  CAMPAIGN,
  CHAT,
  DASHBOARD,
  INTEGRATION,
  MARKETING_TOOLS,
  ORDERS,
  PLAN_UPGRADE,
  PLAN,
  PRODUCTS_INVENTORY,
  PROJECT,
  PROMOTION,
  QUICK_START,
  SHIPPING,
  STORE_SETTINGS,
  USER_SETTING,
  PAYMENTS,
  START_PAGE,
} from "constants/Router";

import FreeTrialWarningModal from "domain/FreeTrialWarningModal";
import { parseProjectId } from "utils/common";
import useGetUserWithPermission from "utils/hooks/useGetUserWithPermission";
import useGetProject from "utils/hooks/useGetProject";
import { isRunningOnMobileApp } from "utils/mobileUtil";
import { IS_USE_NEW_FREEMIUM_PACKAGE } from "config";

import { VideoPopupContextProvider, GuidelineContextProvider } from "utils/context";
import authenticateFreshdesk from "utils/hooks/useAuthenticateFreshdesk";
import AISettingSubRoute from "./SubRoutes/AISettingSubRoute";
import DashboardSubRoute from "./SubRoutes/DashboardSubRoute";
import MarketingToolsSubRoute from "./SubRoutes/MarketingToolsSubRoute";
import PlanUsageSubRoute from "./SubRoutes/PlanUsageSubRoute";
import ProductsSubRoute from "./SubRoutes/ProductsSubRoute";
import ShippingSubRoute from "./SubRoutes/ShippingSubRoute";
import StoreSettingSubRoute from "./SubRoutes/StoreSettingSubRoute";
import UserSettingSubRoute from "./SubRoutes/UserSettingSubRoute";

import LayoutWithNavbar from "./LayoutWithNavbar";

import PrivateRoute from "./PrivateRoute";
import {
  ACCOUNT_SETTINGS_ROUTE_CONFIG,
  QUICK_START_ROUTE_CONFIG,
  PLAN_USAGE_ROUTE_CONFIG,
  START_PAGE_ROUTE_CONFIG,
  getOwnerRouteConfig,
} from "./routerConfig";
import PaymentsSubRoute from "./SubRoutes/PaymentsSubRoute";
import GetStartedSubRoute from "./SubRoutes/GetStartedSubRoute";

const AccountSetting = lazy(() => import("pages/AccountSetting"));
const AISetting = lazy(() => import("pages/AISetting"));
const Chat = lazy(() => import("pages/Chat"));
const Dashboard = lazy(() => import("pages/Dashboard"));
const LockedAccount = lazy(() => import("pages/LockedAccount"));
const MarketingTools = lazy(() => import("pages/MarketingTools"));
const NotFound = lazy(() => import("pages/NotFound"));
const Orders = lazy(() => import("pages/Orders"));
const PlanUsage = lazy(() => import("pages/PlanUsage"));
const Products = lazy(() => import("pages/Products"));
const Promotion = lazy(() => import("pages/Promotion"));
const PromotionForm = lazy(() => import("pages/PromotionForm"));
const NewQuickStart = lazy(() => import("pages/NewQuickStart"));
const Shipping = lazy(() => import("pages/Shipping"));
const StoreSetting = lazy(() => import("pages/StoreSetting"));
const FreeTrialPage = lazy(() => import("pages/FreeTrialPage"));
const Payments = lazy(() => import("pages/Payments"));
const PlanUpgrade = lazy(() => import("pages/PlanUpgrade"));

const AppRoute = () => {
  const projectId = parseProjectId(window.location.pathname);
  authenticateFreshdesk(projectId);

  const { loading: permissionLoading, isOwner, isAdmin, isAdminRole, isDisabled } = useGetUserWithPermission(projectId);
  const {
    isDigitalContentEnabled,
    isIntegrationEnabled,
    isOpenApiConfigurationEnabled,
    isShippopEnabled,
    loading: projectLoading,
    isInitialProjectCompleted,
    isUsingDeeplePay,
    isGuidelineCompleted,
    packageEntityLevel,
  } = useGetProject(projectId);
  const { push } = useHistory();

  const isMobileApp = isRunningOnMobileApp();
  const ownerRouteConfig = getOwnerRouteConfig(projectId);

  const DisabledComponent = LockedAccount;

  const loading = projectLoading || permissionLoading;

  const isCurrentlyUseTrialPlan = !IS_USE_NEW_FREEMIUM_PACKAGE;

  const shouldSelectFreeTrialPlan = !isInitialProjectCompleted && isCurrentlyUseTrialPlan;

  const redirectLink = shouldSelectFreeTrialPlan ? `/${PROJECT}/${projectId}/${PLAN_UPGRADE}` : "/";

  const isAllowDeeplePay = isUsingDeeplePay;

  return (
    <GuidelineContextProvider>
      <VideoPopupContextProvider>
        {packageEntityLevel && <FreeTrialWarningModal isShow={packageEntityLevel === "FREE"} projectId={projectId} />}
        <Suspense fallback={<div />}>
          {/* Loading */}
          <Switch>
            <PrivateRoute
              exact
              loading={loading}
              path={`/${PROJECT}/:projectId/${PLAN_UPGRADE}`}
              isAllowed={isOwner}
              redirectTo="/"
              title="Plan upgrade for free trial"
            >
              {/* {isMobileApp ? (
                <FreeTrialPage />
              ) : IS_USE_NEW_FREEMIUM_PACKAGE ? (
                <PlanUpgradeWithInformation />
              ) : (
                <PlanUpgradeForFreeTrial isFreePackage={shouldSelectFreeTrialPlan} />
              )} */}
              {isMobileApp ? <FreeTrialPage /> : <PlanUpgrade />}
            </PrivateRoute>
            <PrivateRoute
              loading={loading}
              path={`/${PROJECT}/:projectId/get-started`}
              isAllowed
              redirectTo="/"
              title="Get Started"
            >
              <GetStartedSubRoute />
            </PrivateRoute>

            <LayoutWithNavbar>
              <Suspense fallback={<div />}>
                <Switch>
                  <PrivateRoute
                    loading={loading}
                    path={`/${PROJECT}/:projectId/${USER_SETTING}`}
                    isAllowed={isAdmin && !shouldSelectFreeTrialPlan}
                    redirectTo={redirectLink}
                    title={ACCOUNT_SETTINGS_ROUTE_CONFIG.title}
                  >
                    <AccountSetting>
                      <UserSettingSubRoute />
                    </AccountSetting>
                  </PrivateRoute>

                  {/* ORDER page */}
                  <PrivateRoute
                    exact
                    loading={loading}
                    path={`/${PROJECT}/:projectId/${ORDERS}`}
                    isAllowed={isAdmin && !shouldSelectFreeTrialPlan}
                    redirectTo={redirectLink}
                    title={ownerRouteConfig[ORDERS].title}
                  >
                    {isDisabled ? <DisabledComponent /> : <Orders />}
                  </PrivateRoute>
                  <PrivateRoute
                    loading={loading}
                    path={`/${PROJECT}/:projectId/${ORDERS}/:orderNumber`}
                    isAllowed={isAdmin && !shouldSelectFreeTrialPlan}
                    redirectTo={redirectLink}
                    title={ownerRouteConfig[ORDERS].title}
                  >
                    {isDisabled ? <DisabledComponent /> : <Orders />}
                  </PrivateRoute>

                  {/* CHAT page */}
                  <PrivateRoute
                    exact
                    loading={loading}
                    path={`/${PROJECT}/:projectId/${CHAT}`}
                    isAllowed={isAdmin && !shouldSelectFreeTrialPlan}
                    title={ownerRouteConfig[CHAT].title}
                  >
                    {isDisabled ? <DisabledComponent /> : <Chat />}
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    loading={loading}
                    path={`/${PROJECT}/:projectId/${CHAT}/:customerId`}
                    isAllowed={isAdmin && !shouldSelectFreeTrialPlan}
                    title={ownerRouteConfig[CHAT].title}
                  >
                    {isDisabled ? <DisabledComponent /> : <Chat />}
                  </PrivateRoute>

                  {/* PRODUCTS_INVENTORY page */}
                  <PrivateRoute
                    loading={loading}
                    path={`/${PROJECT}/:projectId/${PRODUCTS_INVENTORY}`}
                    isAllowed={isAdmin && !shouldSelectFreeTrialPlan}
                    redirectTo={redirectLink}
                    title={ownerRouteConfig[PRODUCTS_INVENTORY].title}
                  >
                    <Products isDigitalContentEnabled={isDigitalContentEnabled}>
                      <ProductsSubRoute isDigitalContentEnabled={isDigitalContentEnabled} />
                    </Products>
                  </PrivateRoute>

                  {/* SHIPPING page */}
                  <PrivateRoute
                    loading={loading}
                    path={`/${PROJECT}/:projectId/${SHIPPING}`}
                    isAllowed={isAdmin && !shouldSelectFreeTrialPlan}
                    redirectTo={redirectLink}
                    title={ownerRouteConfig[SHIPPING].title}
                  >
                    <Shipping isShippopEnabled={isShippopEnabled}>
                      <ShippingSubRoute isShippopEnabled={isShippopEnabled} />
                    </Shipping>
                  </PrivateRoute>

                  {/* PROMOTION page */}
                  <PrivateRoute
                    exact
                    loading={loading}
                    path={`/${PROJECT}/:projectId/${MARKETING_TOOLS}/${CAMPAIGN}/:campaignId`}
                    isAllowed={isAdmin && !shouldSelectFreeTrialPlan}
                    redirectTo={redirectLink}
                    title={ownerRouteConfig[MARKETING_TOOLS].title}
                  >
                    <Promotion />
                  </PrivateRoute>

                  <PrivateRoute
                    exact
                    loading={loading}
                    path={`/${PROJECT}/:projectId/${MARKETING_TOOLS}/${CAMPAIGN}/:campaignId/${PROMOTION}`}
                    isAllowed={isAdmin && !shouldSelectFreeTrialPlan}
                    redirectTo="/"
                    title={ownerRouteConfig[MARKETING_TOOLS].title}
                  >
                    <PromotionForm />
                  </PrivateRoute>

                  {/* AI_SETTINGS page */}
                  <PrivateRoute
                    loading={loading}
                    path={`/${PROJECT}/:projectId/${AI_SETTINGS}`}
                    isAllowed={isOwner && !shouldSelectFreeTrialPlan}
                    redirectTo={redirectLink}
                    title={ownerRouteConfig[AI_SETTINGS].title}
                  >
                    <AISetting>
                      <AISettingSubRoute />
                    </AISetting>
                  </PrivateRoute>

                  {/* STORE_SETTINGS page */}
                  <PrivateRoute
                    loading={loading}
                    path={`/${PROJECT}/:projectId/${STORE_SETTINGS}`}
                    isAllowed={isAdmin && !shouldSelectFreeTrialPlan}
                    redirectTo={redirectLink}
                    title={ownerRouteConfig[STORE_SETTINGS].title}
                  >
                    <StoreSetting>
                      <StoreSettingSubRoute />
                    </StoreSetting>
                  </PrivateRoute>

                  {/* MARKETING_TOOLS page */}
                  <PrivateRoute
                    loading={loading}
                    path={`/${PROJECT}/:projectId/${MARKETING_TOOLS}`}
                    isAllowed={isAdmin && !shouldSelectFreeTrialPlan}
                    redirectTo={redirectLink}
                    title={ownerRouteConfig[MARKETING_TOOLS].title}
                  >
                    <MarketingTools>
                      <MarketingToolsSubRoute />
                    </MarketingTools>
                  </PrivateRoute>

                  {/* DASHBOARD page */}
                  <PrivateRoute
                    loading={loading}
                    path={`/${PROJECT}/:projectId/${DASHBOARD}`}
                    isAllowed={isAdmin && !shouldSelectFreeTrialPlan}
                    redirectTo={redirectLink}
                    title={ownerRouteConfig[DASHBOARD].title}
                  >
                    <Dashboard>
                      <DashboardSubRoute />
                    </Dashboard>
                  </PrivateRoute>

                  {/* QUICK_START page */}
                  <PrivateRoute
                    exact
                    loading={loading}
                    path={`/${PROJECT}/:projectId/${QUICK_START}`}
                    isAllowed={false}
                    redirectTo={`/${PROJECT}/${projectId}/${START_PAGE}`}
                    title={QUICK_START_ROUTE_CONFIG.title}
                  >
                    <NewQuickStart />
                  </PrivateRoute>

                  {/* START page */}
                  <PrivateRoute
                    exact
                    loading={loading}
                    path={`/${PROJECT}/:projectId/${START_PAGE}`}
                    redirectTo={redirectLink}
                    title={START_PAGE_ROUTE_CONFIG.title}
                    isAllowed={isAdmin || isOwner}
                  >
                    <NewQuickStart />
                  </PrivateRoute>

                  {/* PLAN page */}
                  {!isMobileApp && (
                    <PrivateRoute
                      loading={loading}
                      path={`/${PROJECT}/:projectId/${PLAN}`}
                      isAllowed={isAdmin && !shouldSelectFreeTrialPlan}
                      redirectTo={redirectLink}
                      title={PLAN_USAGE_ROUTE_CONFIG.title}
                    >
                      <PlanUsage>
                        <PlanUsageSubRoute />
                      </PlanUsage>
                    </PrivateRoute>
                  )}

                  {/* INTEGRATION page */}
                  {/* LEANBIZ_DELETED hide unfinished open api page */}
                  {/* <PrivateRoute
                    exact
                    loading={loading}
                    path={`/${PROJECT}/:projectId/${INTEGRATION}`}
                    isAllowed={
                      isAdmin && !shouldSelectFreeTrialPlan && (isIntegrationEnabled || isOpenApiConfigurationEnabled)
                    }
                    redirectTo={redirectLink}
                    title={ownerRouteConfig[INTEGRATION].title}
                  >
                    {isDisabled ? (
                      <DisabledComponent />
                    ) : (
                      <LayoutDefault
                        projectId={projectId}
                        navigate={push}
                        notifyError={window.notifyError}
                        notifySuccess={window.notifySuccess}
                      >
                        <PageIntegration
                          renderIntegrationForm={({ isOpen, onClose, integrationType }) => (
                            <IntegrationInputForm
                              projectId={projectId}
                              queryVariables={{
                                projectId,
                                type: integrationType,
                              }}
                              isOpen={isOpen}
                              onClose={onClose}
                            />
                          )}
                        />
                      </LayoutDefault>
                    )}
                  </PrivateRoute> */}

                  {/* PAYMENT page */}
                  <PrivateRoute
                    loading={loading}
                    path={`/${PROJECT}/:projectId/${PAYMENTS}`}
                    isAllowed={isOwner}
                    redirectTo={redirectLink}
                    title={ownerRouteConfig[PAYMENTS].title}
                  >
                    <Payments>
                      <PaymentsSubRoute />
                    </Payments>
                  </PrivateRoute>

                  <PrivateRoute
                    exact
                    loading={loading}
                    path={`/${PROJECT}/:projectId`}
                    isAllowed={false}
                    redirectTo={
                      isGuidelineCompleted || isAdminRole
                        ? `/${PROJECT}/${projectId}/${ORDERS}`
                        : `/${PROJECT}/${projectId}/${START_PAGE}`
                    }
                  />
                  <Route path="*">
                    <NotFound />
                  </Route>
                </Switch>
              </Suspense>
            </LayoutWithNavbar>

            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </Suspense>
      </VideoPopupContextProvider>
    </GuidelineContextProvider>
  );
};

export default AppRoute;

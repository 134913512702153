import { useQuery, useMutation } from "@apollo/client";
import React, { useCallback, useState, useContext, ReactNode } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import debounce from "lodash/debounce";
import sortBy from "lodash/sortBy";
import get from "lodash/get";
import { Pages } from "@leanbizai/web-console";

import Grid from "components/Grid";
import Typography from "components/Typography";
import Pagination from "components/Pagination";
import CircularProgress from "components/CircularProgress";
import Button, { FloatingButton } from "components/Button";
import Search from "components/Search";
import Modal from "components/Modal";
import ScrollToTop from "components/ScrollToTop";
import { SvgIcon } from "components/Icon";
import { IcAdd, IcDigitalProduct, IcArrowToTop } from "components/SvgIcons";
import WhiteSpaceBottom from "components/WhiteSpaceBottom";
import { IS_ADD_FOOD_PRODUCT_ENABLED, NEW_PRODUCT_LIST_ENABLE } from "config";
import { MAX_SIZE_IMPORTED_FILE, DOWNLOAD_SAMPLE_URL } from "config/product";
import { DEBOUNCE_TIME } from "constants/Order";
import { COLORS } from "constants/Colors";
import { Category, Product } from "types";
import { ProjectIdType, MultiStoreInventoryType } from "types/Project";
import { ProductType } from "types/Product";
import { Device } from "types/Device";
import { PRODUCT, PRODUCTS } from "graphql/product/query";
import { IMPORT_PRODUCTS } from "graphql/product/mutation";
import { ALL_CATEGORY_WITH_HIGHLIGHT_PRODUCTS } from "graphql/category/query";
import { downloadFile } from "utils/common";
import validateFileSize from "utils/common/validateFileSize";
import { notifyError, notifyWarn, notifySuccess } from "utils/notify";
import useToggle from "utils/hooks/useToggle";
import useDevice from "utils/hooks/useDevice";
import { GuidelineContext } from "utils/context";
import useGetProject from "utils/hooks/useGetProject";
import { PLAN, PLAN_UPGRADE, PROJECT } from "constants/Router";
import useScrollToTopElement from "utils/hooks/useScrollToTopElement";
import { DEFAULT_SCROLL } from "constants/Scroll";
import { StickyButtonContainer } from "components/StickyPanel";
import PageTitle from "components/PageTitle";
import isEmpty from "lodash/isEmpty";
import AddFoodForm from "./AddFoodProductForm";
import AddDigitalContentProductContainer from "./AddDigitalContentProductForm";
import ProductListItem from "./ProductListItem";
import ProductTypeSelectorModal from "./ProductTypeSelectorModal";
import mapErrorMessage from "./mapErrorMessage";
import ImportButton from "./ImportButton";

import {
  CategoryContentWrapper,
  AddButton,
  GridButtonWrapper,
  DigitalProductCardBadge,
  ProductItemCardWrapper,
} from "./StyledComponent/product.styled";
import ErrorImportModal from "./ErrorImportModal";
import { ErrorImportProduct } from "../../types/Product";

interface CategoryResponseData {
  categories: {
    results: Category.CategoryType[];
  };
}

interface CategoryResponseVars {
  projectId: string;
}

export const Products: React.FC<{ newAddProductCard: ReactNode }> = ({ newAddProductCard }) => {
  const { t } = useTranslation();
  const { handleFetchGuideline } = useContext(GuidelineContext);
  const { projectId } = useParams<ProjectIdType>();
  const { isDigitalContentEnabled: isDigitalContent, multiStoreInventoryType } = useGetProject(projectId);
  const isClientStore = multiStoreInventoryType === MultiStoreInventoryType.CHILD;
  const isMasterStore = multiStoreInventoryType === MultiStoreInventoryType.MASTER;

  const {
    isOpen: isProductTypeSelectorModalOpen,
    handleToggle: handleToggleProductTypeSelectorModal,
    handleClose: handleCloseProductTypeSelectorModal,
  } = useToggle();

  const {
    isOpen: isProductFoodTypeModalOpen,
    handleToggle: handleToggleProductFoodTypeModal,
    handleClose: handleCloseProductFoodTypeModal,
  } = useToggle();

  const {
    isOpen: isDigitalContentTypeModalOpen,
    handleToggle: handleToggleProductDigitalContentTypeModal,
    handleClose: handleCloseProductDigitalContentTypeModal,
  } = useToggle();

  const device = useDevice();
  const isDesktop = device === Device.DESKTOP;
  const isMobile = device === Device.MOBILE;

  const isDigitalContentEnabled = isDigitalContent;

  const mobileDisplayListLimit = 15;
  const [pageNo, setPageNo] = useState(1);
  const [filterWord, setFilterWordValue] = useState("");
  const [selectedFilterCategoryId, setSelectedFilterCategoryId] = useState<string>();
  const [selectedProductId, setSelectedProductId] = useState("");
  const [importErrorModalData, setImportErrorModalData] = useState({});
  const { scrollTopId, handleScrollToTop, isScroll } = useScrollToTopElement(
    DEFAULT_SCROLL.PRODUCT_SCROLL_TOP,
    isDesktop,
  );

  // New product form
  const [isOpenNewProductForm, setIsOpenNewProductForm] = useState(false);

  const setPageValue = (val: number) => {
    const setVal = val;
    if (setVal < 1) {
      return setPageNo(1);
    }
    return setPageNo(setVal);
  };

  const setFilterWord = debounce((value: string) => {
    setPageNo(1);
    setFilterWordValue(value);
  }, DEBOUNCE_TIME);

  const { data: categoryDataResponse } = useQuery<CategoryResponseData, CategoryResponseVars>(
    ALL_CATEGORY_WITH_HIGHLIGHT_PRODUCTS,
    {
      variables: {
        projectId,
      },
    },
  );

  const categoryResults = categoryDataResponse?.categories?.results || [];
  const categoryData = categoryResults.filter(({ isHomePageCategory }) => !isHomePageCategory);

  const { loading: loadingGetAllProductData, data: allProductData, refetch: refetchProducts } = useQuery(PRODUCTS, {
    variables: {
      projectId,
      limit: mobileDisplayListLimit,
      offset: pageNo - 1,
      filter: {
        productName: filterWord,
        categoryId: selectedFilterCategoryId,
        sortBy: "desc",
      },
    },
    fetchPolicy: "network-only",
  });

  const { loading: loadingGetProductData, data: productData } = useQuery(PRODUCT, {
    skip: !projectId || !selectedProductId,
    variables: {
      projectId,
      id: selectedProductId,
    },
  });

  const [importProducts, { loading: isImportProductsLoading }] = useMutation(IMPORT_PRODUCTS);

  const onClosEditFoodProductModal = () => {
    handleCloseProductFoodTypeModal();
    setSelectedProductId("");
    refetchProducts();
  };

  const onCloseEditDigitalContentProductModal = () => {
    handleCloseProductDigitalContentTypeModal();
    setSelectedProductId("");
    refetchProducts();
  };

  const handleClickAddProduct = () => {
    if (IS_ADD_FOOD_PRODUCT_ENABLED || isDigitalContentEnabled) {
      handleToggleProductTypeSelectorModal();
    } else {
      setIsOpenNewProductForm(true);
    }
  };

  const handleImportProduct = async (file: File) => {
    if (file && projectId) {
      const isOverSize = validateFileSize(file, MAX_SIZE_IMPORTED_FILE);

      if (isOverSize) {
        notifyError(t("product.import.error.overSize", { maxSize: MAX_SIZE_IMPORTED_FILE }), { autoClose: 8000 });
        return;
      }

      try {
        await importProducts({ variables: { projectId, file } });
        refetchProducts();
      } catch (err) {
        const error = get(err, "graphQLErrors.0.extensions.exception");
        const gqlErrorMessage = get(error, "message");
        const errorMessage = await mapErrorMessage(gqlErrorMessage);

        if (
          gqlErrorMessage === ErrorImportProduct.BASIC_PACK_CANNOT_EXCEED_PRODUCT_QUOTA ||
          gqlErrorMessage === ErrorImportProduct.EXCEED_PRODUCT_QUOTA
        ) {
          setImportErrorModalData({
            details: t("importProduct.error.errorType.exceedProductQuota.prefix"),
            suffix: t("importProduct.error.errorType.exceedProductQuota.suffix"),
            link: `/${PROJECT}/${projectId}/${PLAN}/${PLAN_UPGRADE}`,
            linkWord: t("importProduct.error.errorType.exceedProductQuota.link"),
            isOpen: true,
            title: t("importProduct.error.title"),
          });
          return;
        }

        const rows = get(error, "meta.errorRows") || [];
        const errorOptions = rows.map(({ detail, ...props }: { detail: string }) => {
          return {
            ...props,
            detail: mapErrorMessage(detail),
          };
        });

        setImportErrorModalData({
          details: t(errorMessage),
          isOpen: true,
          title: t("importProduct.error.title"),
          errorOptions,
        });
      }
    }
  };

  const handleClickSampleLink = useCallback(() => downloadFile(DOWNLOAD_SAMPLE_URL, "upload-products-example.csv"), []);
  const handleClickProduct = (type: ProductType, id: string) => {
    const isDigitalProduct = type === ProductType.DIGITAL_CONTENT;

    if (type === ProductType.SINGLE_SKU_WITH_OPTIONS) {
      setSelectedProductId(id);
      handleToggleProductFoodTypeModal();
    } else if (type === ProductType.SKU) {
      setSelectedProductId(id);
      setIsOpenNewProductForm(true);
    } else if (isDigitalProduct) {
      setSelectedProductId(id);
      handleToggleProductDigitalContentTypeModal();
    }
  };

  if (loadingGetProductData || isImportProductsLoading) {
    return (
      <Grid container justify="center" className="mt-4">
        <CircularProgress className="m-4" />
      </Grid>
    );
  }

  const allProductDataAvailable = get(allProductData, "products.results");
  const isAllProductDataAvailable = allProductDataAvailable && allProductDataAvailable.length;
  const isOpenAddProductPage =
    !loadingGetProductData &&
    !loadingGetAllProductData &&
    get(allProductData, "products.results", []).length <= 0 &&
    !isOpenNewProductForm &&
    isEmpty(filterWord);

  const isShowOldProductList = get(allProductData, "products.results", []).length > 0;

  const getRenderProductPage = () => {
    return NEW_PRODUCT_LIST_ENABLE ? (
      <div className="w-100">
        {isShowOldProductList ? (
          <Pages.Product
            projectId={projectId}
            notifySuccess={notifySuccess}
            notifyFailed={notifyWarn}
            refetchGuideline={handleFetchGuideline}
            handleClickProduct={handleClickProduct}
            handleImportProduct={handleImportProduct}
            handleDownloadExample={handleClickSampleLink}
            handleToggleProductTypeSelectorModal={handleToggleProductTypeSelectorModal}
            refetchProducts={refetchProducts} // Todo: remove after new product list done
            isOpenAddProductPage={isOpenAddProductPage}
            // To handle state for digital product
            isAddFoodProductEnable={IS_ADD_FOOD_PRODUCT_ENABLED}
            isDigitalContentEnabled={isDigitalContentEnabled}
            setSelectedOldProductId={setSelectedProductId}
            handleToggleProductDigitalContentTypeModal={handleToggleProductDigitalContentTypeModal}
            isOpenNewProductForm={isOpenNewProductForm}
            closeNewProductForm={async () => {
              await handleFetchGuideline();
              setIsOpenNewProductForm(false);
            }}
            isClientStore={isClientStore}
            isMasterStore={isMasterStore}
          />
        ) : (
          newAddProductCard
        )}
      </div>
    ) : (
      <>
        <Grid item container xs={12} id={scrollTopId} className="pt-4">
          <Grid item xs={6} className="pt-1">
            <PageTitle title={t("Products")} className="py-0" />
          </Grid>
          {!isMobile && (
            <Grid
              item
              xs={6}
              container
              justify="flex-end"
              alignItems="center"
              alignContent="center"
              className="d-flex px-3 pb-3"
            >
              <GridButtonWrapper item>
                <Grid item>
                  <AddButton color="primary" size="small" onClick={handleClickAddProduct} className="pl-2">
                    <SvgIcon className="pt-1 pr-2 pb-2" component={IcAdd} fontSize="small" />
                    {t("product.button.addNewProduct")}
                  </AddButton>
                </Grid>
                <Grid item className="px-2">
                  <ImportButton
                    isFileType
                    label={t("product.import.button.label")}
                    subLabel={t("product.import.button.subLabel")}
                    onChange={handleImportProduct}
                    isLoading={isImportProductsLoading}
                    labelLink={t("product.download.sample")}
                    onClickLink={handleClickSampleLink}
                  />
                </Grid>
              </GridButtonWrapper>
            </Grid>
          )}
          {isMobile && (
            <Grid item xs={6} container justify="flex-end" alignItems="center" alignContent="center" className="px-3">
              <Button
                color="primary"
                size="medium"
                onClick={handleClickAddProduct}
                data-cy="addButton"
                className="mx-2"
              >
                <SvgIcon component={IcAdd} fontSize="small" />
                {t("Add")}
              </Button>
            </Grid>
          )}
        </Grid>
        <Grid container item xs={12}>
          <Grid item container xs={12} alignItems="center">
            <Grid item className="pl-4 pr-2 py-1 flex-grow-1">
              <Search defaultValue={filterWord} onChange={(value) => setFilterWord(value)} className="mr-3" />
            </Grid>
          </Grid>

          <Grid container className="pl-4">
            <Grid item xs={12}>
              {categoryData && (
                <Typography variant="body3" className="category-list py-3">
                  {t("Categories")}:
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <CategoryContentWrapper>
                {categoryData &&
                  sortBy(categoryData, ["name"]).map((category: Category.CategoryType) => {
                    return (
                      <Button
                        isSelected={category.id === selectedFilterCategoryId}
                        color="secondary"
                        borderRadius="8px"
                        key={category.id}
                        className="m-1"
                        onClick={() => {
                          if (category.id === selectedFilterCategoryId) {
                            setSelectedFilterCategoryId(undefined);
                            setPageNo(1);
                          } else {
                            setSelectedFilterCategoryId(category.id);
                            setPageNo(1);
                          }
                        }}
                      >
                        {category.name}
                      </Button>
                    );
                  })}
              </CategoryContentWrapper>
            </Grid>
          </Grid>
          <Grid item container className="pt-2 px-2">
            {loadingGetAllProductData ? (
              <Grid container justify="center" className="mt-4">
                <CircularProgress className="m-4" />
              </Grid>
            ) : (
              <>
                {isAllProductDataAvailable ? (
                  allProductDataAvailable.map((product: Product.ProductItemPropsType, index: number) => {
                    const isDigitalProduct = product.type === ProductType.DIGITAL_CONTENT;

                    return (
                      <Grid
                        item
                        container
                        xs={12}
                        sm={6}
                        md={4}
                        key={product.id}
                        onClick={() => handleClickProduct(product.type, product.id)}
                        data-cy={`productCard${index}`}
                      >
                        <ProductItemCardWrapper className="m-2 position-relative" fullWidth noShadow>
                          {isDigitalProduct && (
                            <DigitalProductCardBadge className="position-absolute d-flex py-1 px-2">
                              <SvgIcon
                                className="p-0 m-0"
                                viewBox="0 -6 32 32"
                                component={IcDigitalProduct}
                                fontSize="small"
                                htmlColor={COLORS.White}
                              />
                              <Typography variant="body4" className="pl-1" color="white">
                                Digital
                              </Typography>
                            </DigitalProductCardBadge>
                          )}
                          <Grid container item xs={12}>
                            <ProductListItem
                              isShowFreeText
                              productImage={get(product, "images[0].src")}
                              productSKUs={product.productSKUs}
                              defaultPrice={product.defaultPrice}
                              productName={product.name}
                              isActive={!!product.productSKUs.find((productSKU) => productSKU.selected === true)}
                              isShowCardBadge={isDigitalProduct}
                              isDigitalProduct={isDigitalProduct}
                            />
                          </Grid>
                        </ProductItemCardWrapper>
                      </Grid>
                    );
                  })
                ) : (
                  <Typography variant="body2" className="px-3 mx-auto text-center">
                    {t("Search")} {t("Products")} {t("not found")}!
                  </Typography>
                )}
              </>
            )}
          </Grid>
        </Grid>

        <StickyButtonContainer className="px-2 py-3">
          {!isDesktop && isScroll && (
            <FloatingButton onClick={handleScrollToTop} data-cy="addButton">
              <SvgIcon
                className="mb-1 mt-3 ml-3"
                component={IcArrowToTop}
                fontSize="default"
                htmlColor={COLORS.DarkMed}
              />
            </FloatingButton>
          )}
          <ScrollToTop buttonColor="darkGray" />
        </StickyButtonContainer>

        {Boolean(isAllProductDataAvailable) && (
          <Grid item xs={12} className="px-2 pb-4">
            <WhiteSpaceBottom>
              <Pagination
                rowsPerPage={mobileDisplayListLimit}
                page={pageNo}
                totalItem={get(allProductData, "products.total")}
                handlePageChange={setPageValue}
                isMobile
              />
            </WhiteSpaceBottom>
          </Grid>
        )}
      </>
    );
  };

  return (
    <Grid container className="w-100" justify={isDesktop ? undefined : "flex-end"}>
      {/* Render product page */}
      {getRenderProductPage()}

      <ProductTypeSelectorModal
        isOpen={isProductTypeSelectorModalOpen}
        isDigitalContentEnabled={isDigitalContentEnabled}
        onClickProduct={() => {
          setIsOpenNewProductForm(true);
          handleCloseProductTypeSelectorModal();
        }}
        onClickFood={() => {
          handleToggleProductFoodTypeModal();
          handleCloseProductTypeSelectorModal();
        }}
        onClickDigitalContentProduct={() => {
          handleToggleProductDigitalContentTypeModal();
          handleCloseProductTypeSelectorModal();
        }}
        onClose={handleCloseProductTypeSelectorModal}
      />

      <Modal isOpen={isProductFoodTypeModalOpen} fullScreen onClose={onClosEditFoodProductModal}>
        <AddFoodForm
          productData={productData}
          categoryData={categoryData}
          isEditMode={Boolean(selectedProductId)}
          closeProductModal={onClosEditFoodProductModal}
        />
      </Modal>

      <Modal isOpen={isDigitalContentTypeModalOpen} fullScreen onClose={onCloseEditDigitalContentProductModal}>
        <AddDigitalContentProductContainer
          categoryData={categoryData}
          productData={productData}
          isEditMode={Boolean(selectedProductId)}
          closeProductModal={onCloseEditDigitalContentProductModal}
        />
      </Modal>

      <ErrorImportModal handleCloseModal={() => setImportErrorModalData({})} {...importErrorModalData} />
    </Grid>
  );
};

import { Box } from "@material-ui/core";
import { IconWrapper } from "components/Icon";
import Tooltip from "components/Tooltip";
import Typography from "components/Typography";
import React, { FC, memo } from "react";
import { useTranslation } from "react-i18next";

export type BotIndicatorMenuItemPropTypes = {
  isChatbotEnabled: boolean;
  isAdmin?: boolean;
};

const BotIndicatorMenuItem: FC<BotIndicatorMenuItemPropTypes> = (props) => {
  const { isChatbotEnabled, isAdmin = false } = props;
  const { t } = useTranslation();

  return (
    <Box py="9.5px" px="16px" display="flex" alignItems="center" justifyContent="space-between" width="100%">
      <Box display="flex" className="pr-2" alignItems="center">
        <Tooltip title={<span>{t(isChatbotEnabled ? "BotTogglerOnTooltip" : "BotTogglerOffTooltip")}</span>}>
          <IconWrapper alt="mode" src={`/assets/deeple-svg-icon/${isChatbotEnabled ? "bot" : "admin"}.svg`} size="m" />
        </Tooltip>

        <Typography className="ml-2" variant="body3" color="secondPrimary">
          {isChatbotEnabled ? t("ModeAutoReply") : t("ModeManualReply")}
        </Typography>
      </Box>
      {!isAdmin && (
        <Tooltip title={<span>{t("BotSettingTooltip")}</span>}>
          <IconWrapper alt="bot-setting" src="/assets/deeple-svg-icon/ic-external-link.svg" size="s" />
        </Tooltip>
      )}
    </Box>
  );
};

export default memo(BotIndicatorMenuItem);

const payment = {
  en: {
    // Form input
    "Account name": "Account name",
    "Account number": "Account number",
    "Add new bank account": "Add new bank account",
    "API key": "API key",
    "Bank account type": "Bank account type",
    "Bank account": "Bank account",
    "Bank name": "Bank name",
    "Cash on Delivery (COD)": "Cash on Delivery (COD)",
    "Deposit amount": "Deposit amount",
    "Deposit percent": "Deposit percent",
    "Deposit type": "Deposit type",
    "MD5 secret": "MD5 secret",
    "PromptPay account": "PromptPay account",
    "PromptPay name": "PromptPay name",
    "PromptPay number": "PromptPay number",
    "PromptPay image": "Custom QR (optional)",
    Branch: "Branch",
    Current: "Current",
    Fixed_price: "Fixed price",
    Percentage: "Percentage",
    Saving: "Saving",
    // validation & error
    "Account name is required": "Account name is required",
    "Account number is required": "Account number is required",
    "API Key is required": "API Key is required",
    "MD5 secret key is required": "MD5 secret key is required",
    "Merchant ID is required": "Merchant ID is required",
    "Merchant ID": "Merchant ID",
    "Minimum total price per order is required": "Minimum total price per order is required",
    "Minimum total price per order": "Minimum total price per order",
    "Payment gateway - ChillPay": "Payment gateway - ChillPay",
    "Please active at least 1 payment method except COD": "Please active at least 1 payment method except COD",
    "Please enter Deposit amount": "Please enter Deposit amount",
    "Please select account type": "Please select account type",
    "Please select bank name": "Please select bank name",
    "Please select carrier name": "Please select carrier name",
    "Please select deposit type": "Please select deposit type",
    "PromptPay account name is required": "PromptPay account name is required",
    "Please enter phone number 10 character or ID number 13 character":
      "Please enter phone number 10 character or ID number 13 character",
    "PromptPay number can be either phone number 10 character or ID number 13 character":
      "PromptPay number can be either phone number 10 character or ID number 13 character",
    "Deposit percent cannot be below 1": "Deposit percent cannot be below 1",
    "Deposit percent cannot be above 100": "Deposit percent cannot be above 100",
    "Fix deposit value cannot exceed Minimum total price per order":
      "Fix deposit value cannot exceed Minimum total price per order",
    "Fix deposit cannot be below 1": "Fix deposit cannot be below 1",
    "Please enter number only": "Please enter number only",
    "Are you sure you want to remove this payment?": "Are you sure you want to remove this payment?",
    "Minimum total price per order cannot be below 0": "Minimum total price per order cannot be below 0",
    "Branch is required": "Branch is required",
    "Minimum total price per order is require. Please fill in 0 if you not have require minimum total price per order":
      "Minimum total price per order is require. Please fill in 0 if you not have require minimum total price per order",
    "First character can not be number": "First character can not be number",
    "carrier.option.label.all": "All",
    "paymentGateway.label.secret.payment_gateway_2c2p": "Merchant secret",
    "paymentGateway.label.secret.payment_gateway_chill_pay": "MD5 secret",
    PAYMENT_GATEWAY_CHILL_PAY: "Chill pay",
    PAYMENT_GATEWAY_2C2P: "2C2P",
    PAYMENT_GATEWAY_CUSTOM: "Custom payment gateway",
    "paymentGateway.placeholder.typeOption": "Please select payment gateway",
    "paymentGateway.error.paymentGatewayType.required": "Payment gateway type is required",
    "paymentGateway.button.add": "Add new payment gateway",
    "paymentGateway.label.description": "Payment Description",
    "paymentCOD.error.required": "Management fee is require. Please fill in 0 if you not have require management fee",
    "paymentCOD.error.minValue": "Management fee cannot be below 0",
    "Management fee": "Management fee",
    "Management fee type": "Management fee type",
    "Please select management fee type": "Please select management fee type",
    "Management fee percent cannot be below 1": "Management fee cannot less than 1 percent",
    "Management fee percent cannot be above 100": "Management fee cannot more than 100 percent",
    "Fixed management fee cannot be below 1": "Fixed management fee cannot be below 1",
    "paymentMethod.deeplePay.description":
      "LeanBiz pay is the default payment method provided by leanbiz.ai which is in charge of directing your customers’ payments to the console. You can see all the payment channels supported by LeanBiz.pay here",
    "paymentMethod.deeplePay.link": "Go to Payment channels",
    "paymentMethod.deeplePay.status.active": "Active",
    "paymentMethod.deeplePay.status.inActive": "Inactive",
    "paymentGatewayCustom.error.paymentGatewayUrl.required": "Payment gateway URL is required",
    "paymentGateway.label.url": "URL",
    "paymentGateway.label.iconUrl": "Icon URL",
    "paymentGateway.label.header": "Header",
    "paymentGateway.label.body": "Body",
    "paymentGateway.label.body.description": "(body information)",
  },
  th: {
    // Form input
    "Account name": "ชื่อบัญชี",
    "Account number": "เลขบัญชี",
    "Add new bank account": "เพิ่มบัญชีธนาคาร",
    "API key": "API key",
    "Bank account type": "ประเภทบัญชี",
    "Bank account": "บัญชีธนาคาร",
    "Bank name": "ชื่อธนาคาร",
    "Cash on Delivery (COD)": "ชำระเงินปลายทาง (COD)",
    "Deposit amount": "ราคามัดจำ",
    "Deposit percent": "เปอร์เซ็นต์มัดจำ",
    "Deposit type": "ประเภทการมัดจำ",
    "MD5 secret": "MD5 secret",
    "Merchant ID": "Merchant ID",
    "PromptPay number": "หมายเลขพร้อมเพย์",
    "PromptPay image": "รูป QR ของคุณ (ถ้ามี)",
    Branch: "สาขา",
    Current: "กระแสรายวัน",
    Fixed_price: "กำหนดราคา",
    Percentage: "เปอร์เซ็นต์",
    Saving: "ออมทรัพย์",
    "PromptPay account": "บัญชีพร้อมเพย์",
    "PromptPay name": "ชื่อพร้อมเพย์",
    // validation & error
    "Account name is required": "กรุณากรอกชื่อบัญชี",
    "Account number is required": "กรุณากรอกเลขที่บัญชี",
    "API Key is required": "กรุณากรอก API Key",
    "MD5 secret key is required": "กรุณากรอก MD5 secret key",
    "Merchant ID is required": "กรุณากรอก Merchant ID",
    "Minimum total price per order is required": "กรุณากำหนดยอดสั่งซื้อขั้นต่ำ",
    "Minimum total price per order": "ยอดสั่งซื้อขั้นต่ำ",
    "Payment gateway - ChillPay": "Payment gateway - ChillPay",
    "Please active at least 1 payment method except COD":
      "กรุณาเปิดการใช้งาน วิธีการชำระเงิน อย่างน้อย 1 วิธี นอกเหนือจากการเก็บเงินปลายทาง(COD)",
    "Please enter Deposit amount": "กรุณาใส่จำนวนที่ต้องมัดจำ",
    "Please select account type": "กรุณาเลือกประเภทบัญชี",
    "Please select bank name": "กรุณาเลือกธนาคาร",
    "Please select carrier name": "กรุณาเลือกผู้ให้บริการ",
    "Please select deposit type": "กรุณาเลือกประเภทมัดจำ",
    "PromptPay account name is required": "กรุณากรอกชื่อบัญชีพร้อมเพย์",
    "Please enter phone number 10 character or ID number 13 character":
      "กรุณากรอกหมายเลขโทรศัพท์ 10 หลัก หรือ หมายเลขประจำตัวประชาชน 13 หลัก",
    "PromptPay number can be either phone number 10 character or ID number 13 character":
      "หมายเลขพร้อมเพย์ต้องเป็นหมายเลขโทรศัพท์ 10 หลัก หรือ หมายเลขประจำตัวประชาชน 13 หลักเท่านั้น",
    "Deposit percent cannot be below 1": "เปอร์เซ็นต์มัดจำไม่สามารถน้อยกว่า 1",
    "Deposit percent cannot be above 100": "เปอร์เซ็นต์มัดจำไม่สามารถมากกว่า 100",
    "Fix deposit value cannot exceed Minimum total price per order":
      "ราคามัดจำแบบคงที่ ไม่สามารถมากกว่ายอดการสั่งซื้อขั้นต่ำ",
    "Fix deposit cannot be below 1": "ราคามัดจำไม่สามารถน้อยกว่า 1",
    "Please enter number only": "กรุณากรอกเป็นตัวเลขเท่านั้น",
    "Are you sure you want to remove this payment?": "คุณต้องการที่จะลบวิธีการชำระเงินนี้หรือไม่?",
    "Minimum total price per order cannot be below 0": "ยอดสั่งซื้อขั้นต่ำไม่สามารถน้อยกว่า 0",
    "Branch is required": "กรุณาระบุสาขา",
    "Minimum total price per order is require. Please fill in 0 if you not have require minimum total price per order":
      "กรุณากำหนดยอดการสั่งซื้อขั้นต่ำ กรุณาใส่เลข 0 ถ้าไม่ต้องการตั้งยอดการสั่งซื้อขั้นต่ำ",
    "First character can not be number": "ไม่สามารถขึ้นต้นด้วยตัวเลขได้",
    "carrier.option.label.all": "ผู้ให้บริการทั้งหมด",
    "paymentGateway.label.secret.payment_gateway_2c2p": "Merchant secret",
    "paymentGateway.label.secret.payment_gateway_chill_pay": "MD5 secret",
    PAYMENT_GATEWAY_CHILL_PAY: "Chill pay",
    PAYMENT_GATEWAY_2C2P: "2C2P",
    PAYMENT_GATEWAY_CUSTOM: "Custom payment gateway",
    "paymentGateway.placeholder.typeOption": "โปรดเลือก payment gateway",
    "paymentGateway.error.paymentGatewayType.required": "โปรดระบุประเภท Payment gateway",
    "paymentGateway.button.add": "เพิ่ม payment gateway",
    "paymentGateway.label.description": "รายละเอียดช่องทางการชำระเงิน",
    "paymentCOD.error.required": "ค่าธรรมเนียมไม่สามารถน้อยกว่า 1",
    "paymentCOD.error.minValue": "ค่าธรรมเนียมต้องไม่ต่ำกว่า 0",
    "Management fee": "ค่าธรรมเนียม",
    "Management fee type": "ประเภทค่าธรรมเนียม",
    "Please select management fee type": "โปรดเลือกประเภทค่าธรรมเนียม",
    "Management fee percent cannot be below 1": "ค่าธรรมเนียมไม่สามารถน้อยกว่า 1 เปอร์เซ็นต์",
    "Management fee percent cannot be above 100": "ค่าธรรมเนียมไม่สามารถมากกว่า 100 เปอร์เซ็นต์",
    "Fixed management fee cannot be below 1": "ราคาค่าธรรมเนียมไม่สามารถน้อยกว่า 1",
    "paymentMethod.deeplePay.description":
      "LeanBiz pay เป็นวิธีการชำระเงินแบบเริ่มต้นจาก leanbiz.ai ซึ่งรองรับการชำระเงินจากลูกค้าโดยตรงและเชื่อมต่อกับรายการสั่งซื้ออัตโนมัติ ดูช่องทางการชำระเงินทั้งหมดของ LeanBiz pay ได้ที่นี่",
    "paymentMethod.deeplePay.link": "ไปที่ช่องทางการชำระเงิน",
    "paymentMethod.deeplePay.status.active": "เปิดใช้งาน",
    "paymentMethod.deeplePay.status.inActive": "ปิดการใช้งาน",
    "paymentGatewayCustom.error.paymentGatewayUrl.required": "กรุณาระบุ payment gateway URL",
    "paymentGateway.label.url": "URL",
    "paymentGateway.label.iconUrl": "Icon URL",
    "paymentGateway.label.header": "Header",
    "paymentGateway.label.body": "Body",
    "paymentGateway.label.body.description": "(รายละเอียดเพิ่มเติม)",
  },
};

export default payment;

import React, { HTMLAttributes, FC } from "react";

type AppLogoProps = {
  height?: string;
};

const AppLogo: FC<HTMLAttributes<HTMLHeadElement> & AppLogoProps> = ({ className, height }) => {
  return <img className={className} src="/assets/logo/leanbiz-logo-white.png" alt="LeanBiz logo" height={height} />;
};

export default AppLogo;

const guideline = {
  en: {
    "BroadcastBar.title": "Getting started",
    "BroadcastBar.description": "Your set up has been completed",
    "BroadcastBar.button": "Get Started",
    "Guideline.title": "Get started",
    "Guideline.storeDetail.title": "Store settings",
    "Guideline.storeDetail.description": "Add your store information, vat setting and upload your store's logo",
    "Guideline.storeDetail.button": "Store settings",
    "Guideline.categories.title": "Add categories",
    "Guideline.categories.description": "Add categories to Bot to introduce your store catalogue to your customer",
    "Guideline.categories.button": "Categories",
    "Guideline.products.title": "Add your products & inventory",
    "Guideline.products.description":
      "Add products and details to your store and fill your inventory stock to make Bot ready to sell your products",
    "Guideline.products.button": "Products & inventory",
    "Guideline.payments.title": "Add payment method",
    "Guideline.payments.description":
      "Add your suitable payment method to store via Bank transfer, Promptpay, Cash on Delivery (COD) or Payment Gateway",
    "Guideline.payments.button": "Payment method",
    "Guideline.shippingMethod.title": "Add shipping method",
    "Guideline.shippingMethod.description":
      "Add your available shipping method e.g. Thaipost, Kerry, DHL, Lalamove, etc. to your store",
    "Guideline.shippingMethod.button": "Shipping method",
    "Guideline.faqs.title": "Train your bot",
    "Guideline.faqs.description":
      "Enhance your bot to next level by adding special knowledge and product/category symnonyms",
    "Guideline.faqs.button": "AI settings",
    "Guideline.connect.title": "Connect to your chat store account",
    "Guideline.connect.description": "Connect to your store's LINE OA or Facebook Page",
    "Guideline.connect.button": "Connect your store",
    "Guideline.progressbar.subtitle.noActive":
      "Set up your store using our LeanBiz system with only 2 easy steps: 1. Connect store 2. Add product",
    "Guideline.progressbar.subtitle.hasActive": "Your setup has been completed",
    "Guideline.pageConnect.title.completedTwoSteps": "Your store is connected to sales channel.",
    "Guideline.pageConnect.title.incompletedTwoSteps":
      "Connect your Facebook page, Instagram Business account or LINE OA",
    "Guideline.pageAddProduct.title.completedTwoSteps": "Your product is added.",
    "Guideline.pageAddProduct.title.incompletedTwoSteps": "Add product",
    "Guideline.pageConnect.subtitle.requiredTwoSteps":
      "Set up your store using our LeanBiz system with only 2 easy steps: 1. Connect store 2. Add product",
    "Guideline.pageConnect.subtitle.remainingSteps": "Your setup has been completed",
    "Guideline.startPage": "Start page",
  },
  th: {
    "BroadcastBar.title": "ตั้งค่าเบื้องต้น",
    "BroadcastBar.description": "คุณตั้งค่าสำเร็จแล้ว",
    "BroadcastBar.button": "เริ่มต้นตั้งค่า",
    "Guideline.title": "เริ่มต้นตั้งค่าการใช้งาน",
    "Guideline.storeDetail.title": "ตั้งค่าร้านค้า",
    "Guideline.storeDetail.description": "ตั้งค่าข้อมูลของร้านค้า, การคิด vat, อัปโหลดโลโก้ร้าน",
    "Guideline.storeDetail.button": "ตั้งค่าร้านค้า",
    "Guideline.categories.title": " เพิ่มเมนูสินค้า",
    "Guideline.categories.description": "เพิ่มหมวดหมู่สินค้าเพื่อให้บอทแนะนำเมนูหมวดหมู่สินค้าในร้านของคุณแก่ลูกค้า",
    "Guideline.categories.button": " หมวดหมู่สินค้า",
    "Guideline.products.title": "เพิ่มสินค้าและคลังสินค้า",
    "Guideline.products.description":
      "เพิ่มสินค้าและรายละเอียดของสินค้าในร้านของคุณเข้าสู่ระบบและกำหนดจำนวนสตอคสินค้า เพื่อให้บอทพร้อมขายสินค้าให้คุณ",
    "Guideline.products.button": "สินค้าและคลังสินค้า",
    "Guideline.payments.title": "เพิ่มวิธีการชำระเงิน",
    "Guideline.payments.description":
      "เพิ่มวิธีการชำระเงินให้เหมาะสมกับร้านของคุณ ได้ทั้งโอนผ่านบัญชีธนาคาร พร้อมเพย์ การเรียกเก็บเงินปลายทาง (COD) หรือผ่านทางตัวกลางการชำระเงิน (Payment gateway)",
    "Guideline.payments.button": "วิธีการชำระเงิน",
    "Guideline.shippingMethod.title": "เพิ่มวิธีการจัดส่ง",
    "Guideline.shippingMethod.description":
      "เพิ่มวิธีการจัดส่งที่คุณต้องการ เช่น ไปรษณีย์ไทย เคอรี่ ดีเฮชแอล ลาล่ามูฟ ฯลฯ ให้เหมาะสมกับร้านของคุณ",
    "Guideline.shippingMethod.button": "วิธีการจัดส่ง",
    "Guideline.faqs.title": "เพิ่มความสามารถให้บอทของคุณ",
    "Guideline.faqs.description":
      "สอนบอทของคุณให้ฉลาดไปอีกขั้นด้วยการเพิ่มข้อมูลความรู้เฉพาะทาง และชื่อเล่น/ชื่อย่อ/หมวดหมู่ของสินค้า",
    "Guideline.faqs.button": "ตั้งค่าบอท",
    "Guideline.connect.title": "เชื่อมต่อกับช่องทางการแชทของคุณ",
    "Guideline.connect.description": "ตั้งค่าการเชื่อมต่อไปยัง LINE OA หรือ Facebook Page ร้านค้าของคุณ",
    "Guideline.connect.button": "เชื่อมต่อร้านค้า",
    "Guideline.progressbar.subtitle.noActive":
      "ตั้งค่าการใช้งานร้านค้าผ่านระบบ LeanBiz ง่าย ๆ เพียง 2 ขั้นตอน 1. เชื่อมต่อร้านค้า 2. เพิ่มสินค้า",
    "Guideline.progressbar.subtitle.hasActive": "คุณตั้งค่าสำเร็จแล้ว",
    "Guideline.pageConnect.title.completedTwoSteps": "เชื่อมต่อกับ Facebook page หรือ LINE OA สำเร็จ",
    "Guideline.pageConnect.title.incompletedTwoSteps": "เชื่อมต่อกับ Facebook page หรือ LINE OA",
    "Guideline.pageAddProduct.title.completedTwoSteps": "เพิ่มสินค้าสำเร็จ",
    "Guideline.pageAddProduct.title.incompletedTwoSteps": "เพิ่มสินค้า",
    "Guideline.pageConnect.subtitle.requiredTwoSteps":
      "ตั้งค่าการใช้งานร้านค้าผ่านระบบ LeanBiz ง่าย ๆ เพียง 2 ขั้นตอน 1. เชื่อมต่อร้านค้า 2. เพิ่มสินค้า",
    "Guideline.pageConnect.subtitle.remainingSteps": "คุณตั้งค่าสำเร็จแล้ว",
    "Guideline.startPage": "หน้าเริ่มต้น",
  },
};

export default guideline;

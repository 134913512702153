import { useCallback } from "react";
import { useMutation, useQuery, useApolloClient } from "@apollo/client";
import { useGoogleLogout } from "react-google-login";
import { Utilities } from "@leanbizai/web-console";

import { LOGOUT } from "graphql/auth/mutation";
import { FLAG_USER_WITH_IS_ENTERPRISE } from "graphql/auth/query";
import { UserProfileType } from "types/User";
import { removeToken } from "utils/tokenUtil";
import { GOOGLE_CLIENT_ID } from "config";
import { AuthProvider } from "types/Auth";

const useLogout = () => {
  const { data } = useQuery<UserProfileType>(FLAG_USER_WITH_IS_ENTERPRISE, {
    fetchPolicy: "cache-and-network",
  });

  const client = useApolloClient();

  const [logoutService] = useMutation(LOGOUT);

  const onLogoutSuccess = useCallback(async () => {
    removeToken();
    Utilities.removeModalPhoneNumberStore();

    await logoutService();
    client.resetStore();
    // temporary use for user caching bug
    window.location.reload();
  }, [client, logoutService]);

  const { signOut } = useGoogleLogout({
    clientId: GOOGLE_CLIENT_ID,
    onLogoutSuccess,
  });

  const logout = useCallback(async () => {
    if (data?.me?.authProvider === AuthProvider.GOOGLE) {
      signOut();
      return;
    }

    onLogoutSuccess();
  }, [data, onLogoutSuccess, signOut]);

  return { logout };
};

export default useLogout;

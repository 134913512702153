import React, { FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import isEqual from "lodash/isEqual";

import Modal from "components/Modal";
import Grid from "components/Grid";
import Typography from "components/Typography";
import CircularProgress from "components/CircularProgress";

import BillingInfoWarningModal from "components/BillingInfoWarningModal";
import PlanAddonChangedBroadcast from "components/PlanAddonChangedBroadcast";
import FilterButton from "domain/Dashboard/FilterButton";

import { ADDON_FILTER } from "constants/Addon";
import { AddonFilterType } from "types/Addon";
import { ProjectIdType } from "types/Project";
import { DisableFeature } from "pages/DisableFeature";
import useIsDesktop from "utils/hooks/useIsDesktop";
import useHasBillingInfo from "utils/hooks/useHasBillingInfo";
import useGetProject from "utils/hooks/useGetProject";

import useTotalCredit from "domain/Billing/Wallet/hooks/useTotalCredit";
import { InvoiceTypes } from "types/invoice";
import { BuyAddonError } from "types/Billing";
import useCreditCard from "domain/Billing/hooks/useCreditCard";
import { IS_ALLOW_NEW_PACKAGE } from "config";
import { AddonFilterWrapper } from "./styled";
import { useCurrentAddon, useCalculatedAddons, useIsAddonChangeOnNextCycle } from "./hooks";
import PaymentSummaryModal from "./PaymentSummaryModal";
import AddonContent from "./AddonContent";

const AddonContainer: FC = () => {
  const { t } = useTranslation();
  const isDesktop = useIsDesktop();
  const { projectId } = useParams<ProjectIdType>();
  const hasBillingInfo = useHasBillingInfo(projectId);
  const { isAddonPackageEnabled } = useGetProject(projectId);
  // If user plan is Free, they cannot use any addon

  const { credits } = useTotalCredit({ projectId, type: InvoiceTypes.MAIN });
  const { creditCard } = useCreditCard(projectId);

  const defaultSelectedAddonType: AddonFilterType = AddonFilterType.IMAGE_SEARCH;

  const [selectedAddonType, setSelectedAddonType] = useState<AddonFilterType>(defaultSelectedAddonType);
  const [isOpenWarningModal, setIsOpenWarningModal] = useState(false);

  const [buyAddonError, setBuyAddonError] = useState<BuyAddonError | null>(null);
  const { isAddonChangeOnNextCycle } = useIsAddonChangeOnNextCycle(projectId);
  const {
    currentAddons,
    currentUsage,
    packageQuota,
    addons,
    currentImageSearchAddon,
    currentBroadcastAddon,
    loading: isCurrentAddonLoading,
  } = useCurrentAddon(projectId);
  const {
    addonChangesSummary,
    handleAddNewAddon,
    handleBuyAddons,
    handleToggleAddon,
    handleCalculateSummaryAddon,
    handleRemoveAddon,
    isLoadingSummary,
    isLoadingBuyAddon,
    selectedAddons,
    subTotalPrice,
    handleChangeImageSearchAddon,
    selectedImageAddon,
    handleChangeBroadcastAddon,
    selectedBroadcastAddon,
    isOpenSummaryModal,
    handleCloseSummaryModal,
  } = useCalculatedAddons({
    currentAddons: currentAddons || {},
    addons,
    projectId,
    currentImageSearchAddon,
    currentBroadcastAddon,
    onErrorCallback: setBuyAddonError,
  });

  const isAddonsChanged =
    Object.values(selectedAddons).some((value: number) => value) ||
    !isEqual(selectedImageAddon, currentImageSearchAddon) ||
    !isEqual(selectedBroadcastAddon, currentBroadcastAddon);

  const handleSelectAddonType = (type: AddonFilterType) => {
    setSelectedAddonType(type);
  };

  const handleCalculateAddon = () => {
    if (hasBillingInfo) {
      handleCalculateSummaryAddon();
    } else {
      setIsOpenWarningModal(true);
    }
  };

  const handleConfirm = () => {
    handleBuyAddons();
  };

  const focusedAddonDefaultAmount = packageQuota ? packageQuota[selectedAddonType] : 0; // Quota addon amount from package (API data)
  const focusedAddonCurrentAmount = currentAddons ? currentAddons[selectedAddonType] : 0; // Amount of addon that customer have for now (API data)
  const focusedAddonNewAmount = selectedAddons[selectedAddonType]; // Amount of addon that user add or remove from interact with UI (state value)
  const focusedPackageCurrentUsageAmount = currentUsage ? currentUsage[selectedAddonType] : 0; // Addon usage amount ex. have 2 admin from member quota 3 seat (when call handle remove addon use this value to check condition if addon is used, it cannot remove until we remove member from store)

  const renderAddonContent = () => {
    if (isCurrentAddonLoading) {
      return <CircularProgress className="m-4" />;
    }

    return (
      <AddonContent
        addons={addons}
        currentAddons={currentAddons || {}}
        currentAmount={focusedAddonCurrentAmount || 0}
        currentUsageAmount={focusedPackageCurrentUsageAmount}
        defaultAmount={focusedAddonDefaultAmount || 0}
        handleAddNewAddon={handleAddNewAddon}
        handleCalculateAddon={handleCalculateAddon}
        handleChangeImageSearchAddon={handleChangeImageSearchAddon}
        handleRemoveAddon={handleRemoveAddon}
        handleToggleAddon={handleToggleAddon}
        isAddonsChanged={isAddonsChanged}
        isDesktop={isDesktop}
        selectedAddonType={selectedAddonType}
        selectedAmount={focusedAddonNewAmount || 0}
        selectedImageAddon={selectedImageAddon}
        subTotalPrice={subTotalPrice}
        selectedAddons={selectedAddons}
        handleChangeBroadcastAddon={handleChangeBroadcastAddon}
        selectedBroadcastAddon={selectedBroadcastAddon}
      />
    );
  };

  const addonTotalPrice = addonChangesSummary?.summaryItems?.totalPrice || 0;

  const availableAddons = ADDON_FILTER.filter((type: AddonFilterType) => {
    if (IS_ALLOW_NEW_PACKAGE) {
      // LEANBIZ_MODIFY
      const usedAddon = [AddonFilterType.MEMBER, AddonFilterType.IMAGE_SEARCH]; // remove AddonFilterType.INTEGRATION tab cause we don't have any integration addon yet (just remove Zort)
      return usedAddon.includes(type);
    }

    return true;
  }).filter((type: AddonFilterType) => {
    return type !== AddonFilterType.MEMBER;
  });

  useEffect(() => {
    const amountCredit = credits[0].credit;
    const isError = amountCredit < addonTotalPrice && !creditCard;

    if (isError) {
      // LEANBIZ_TEMP_MODIFY allow no credit card to buy addon
      // setBuyAddonError(BuyAddonError["BUY_ADDONS:REQUIRE_CREDIT_CARD_TO_BUY_ADD_ON"]);
    }
  }, [addonTotalPrice, creditCard, credits]);

  return (
    <Grid container alignContent="flex-start" className={`pt-4 position-relative ${isDesktop ? "px-3" : ""}`}>
      <Grid item xs={12} className="px-3">
        <Typography variant="title1" color="dark" className={`py-2 ${isDesktop ? "" : "text-center"}`}>
          {t("planAddon.heading.title")}
        </Typography>
      </Grid>
      <Grid item xs={12} className="px-3">
        <Typography variant="title7" color="dark" className={`pt-1 ${isDesktop ? "" : "text-center"}`}>
          {t("planAddon.heading.subTitle")}
        </Typography>
      </Grid>
      {/* Hide filter button for now cause we have only 1 type of addon */}
      <Grid container className="pt-2">
        <AddonFilterWrapper item>
          {availableAddons.map((type: AddonFilterType) => {
            const isSelected = (selectedAddonType as string) === type;

            return (
              <FilterButton
                key={type}
                label={t(`planAddon.filter.label.${type.toLowerCase()}`)}
                isSelected={isSelected}
                onClick={() => handleSelectAddonType(type)}
              />
            );
          })}
        </AddonFilterWrapper>
      </Grid>
      {/* When addon has changed in next cycle should show broadcast */}
      {isAddonChangeOnNextCycle && (
        <Grid item xs={12} className="px-3">
          <PlanAddonChangedBroadcast title={t("planAddon.changedBroadcast.title")} />
        </Grid>
      )}
      {/* select addon section */}
      {!isCurrentAddonLoading && isAddonPackageEnabled ? (
        renderAddonContent() // Render contest without disable feature anyway when user's plan is not free
      ) : (
        <DisableFeature>{renderAddonContent()}</DisableFeature>
      )}
      <Modal isOpen={isOpenSummaryModal && hasBillingInfo} onClose={() => {}}>
        {isLoadingSummary ? (
          <CircularProgress className="m-4" />
        ) : (
          <PaymentSummaryModal
            errorText={buyAddonError || ""}
            addonChangesSummary={addonChangesSummary}
            onCancel={handleCloseSummaryModal}
            onConfirm={handleConfirm}
            isLoadingBuyAddon={isLoadingBuyAddon}
          />
        )}
      </Modal>
      <BillingInfoWarningModal isOpen={isOpenWarningModal} onCancel={() => setIsOpenWarningModal(false)} />
    </Grid>
  );
};

export default AddonContainer;

import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PromotionType, PromotionSettingsType } from "@leanbizai/shopping-cart-calculator";
import { useQuery, useMutation } from "@apollo/client";
import groupBy from "lodash/groupBy";
import get from "lodash/get";

import Grid from "components/Grid";
import PageTitle from "components/PageTitle";
import Switch from "components/Switch";
import Card from "components/Card";
import Typography from "components/Typography";
import { PROJECT_SETTING } from "graphql/setting/query";
import { SAVE_PROJECT_SETTING } from "graphql/setting/mutation";
import { ProjectIdType } from "types/Project";
import { ProjectConfigurationType, UpSellingPromotionConfiguration } from "types/Setting";
import { notifySuccess, notifyError } from "utils/notify";
import { removeTypenameField } from "utils/common";

const UpSellingContainer = () => {
  const { t } = useTranslation();
  const { projectId } = useParams<ProjectIdType>();

  const { data } = useQuery(PROJECT_SETTING, {
    variables: {
      projectId,
    },
  });

  const [updateUpSelling] = useMutation(SAVE_PROJECT_SETTING, {
    onError: () => {
      notifyError(t("Update failed!"));
    },
    onCompleted: () => {
      notifySuccess(t("Update successfully"));
    },
  });

  const configurationData = get(data, "getProject.configuration");
  const upSellingPromotions = get(configurationData, "promotion.upselling");
  const groupPromotionType = groupBy(upSellingPromotions, "promotionType");

  const handleToggleActive = (type: PromotionType, promotionSettingType: PromotionSettingsType, isActive: boolean) => {
    const projectConfiguration = removeTypenameField(configurationData) as ProjectConfigurationType;
    const upSellingPromotionWithoutTypename = removeTypenameField(
      upSellingPromotions,
    ) as UpSellingPromotionConfiguration[];
    const newUpSellingPromotion = upSellingPromotionWithoutTypename.map(
      (promotion: UpSellingPromotionConfiguration) => {
        const { promotionType, settingsType } = promotion;

        if (type === promotionType && settingsType === promotionSettingType) {
          return {
            ...promotion,
            isActive,
          };
        }

        return promotion;
      },
    );
    const newProjectConfiguration = {
      ...projectConfiguration,
      promotion: {
        upselling: newUpSellingPromotion,
      },
    };

    updateUpSelling({
      variables: {
        projectId,
        projectInput: {
          configuration: newProjectConfiguration,
        },
      },
    });
  };

  return (
    <Grid container>
      <Grid item xs={12} className="pb-2">
        <PageTitle title={t("upSelling.title")} />
      </Grid>
      <Grid item container spacing={2} className="mx-2">
        {Object.keys(groupPromotionType).map((key) => {
          const options = groupPromotionType[key];

          return (
            <Grid item xs={12} md={6} key={key}>
              <Card padding="24px">
                <Grid container>
                  <Grid item xs={12} className="py-2">
                    <Typography variant="title7" color="dark">
                      {t(`upSelling.promotionType.${key.toLowerCase()}`)}
                    </Typography>
                  </Grid>
                  {options.map((option: UpSellingPromotionConfiguration) => {
                    const { promotionType, settingsType, isActive } = option;

                    return (
                      <Grid item xs={12} key={`${promotionType}-${settingsType}`} className="pt-3">
                        <Card noShadow padding="24px">
                          <Grid container alignItems="center">
                            <Grid item className="pr-4">
                              <Switch
                                checked={isActive}
                                onChange={() => handleToggleActive(promotionType, settingsType, !isActive)}
                              />
                            </Grid>
                            <Grid item xs={9}>
                              <Typography variant="title7" color="dark">
                                {t(`upSelling.promotionType.${promotionType.toLowerCase()}.${settingsType}`)}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Card>
                      </Grid>
                    );
                  })}
                </Grid>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default UpSellingContainer;

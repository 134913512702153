import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, Redirect, useHistory } from "react-router-dom";
// import { useQuery } from "@apollo/client";
import { Pages, Utilities, Constants } from "@leanbizai/web-console";
import CircularProgress from "components/CircularProgress";
import Grid from "components/Grid";
import Modal from "components/Modal";
import Typography from "components/Typography";
import BillingInfoWarningModal from "components/BillingInfoWarningModal";

// import { notifyError } from "utils/notify";
import { PLAN, PROJECT, BILLING } from "constants/Router";
// import { MAIN_CREDIT } from "graphql/plan/query";
import { ProjectIdType } from "types/Project";
import {
  PackagePricingType,
  // MainCreditQueryType,
  CurrentSelectedPackage,
  PlanCardPropsType,
  SubscriptionType,
} from "types/PlanUsage";
import { BillingError } from "types/Billing";
// import useCreditCard from "domain/Billing/hooks/useCreditCard";
import useGetProject from "utils/hooks/useGetProject";
import ConfirmModalDetail from "./ConfirmModal";
import usePlanData from "./hooks/usePlanData";
import useChangePackageSummary from "./hooks/useChangePackageSummary";
import useChangePackage from "./hooks/useChangePackage";
import { PackageSummaryModalSubTitle } from "./styled";

const PlanUpgradeContainer: FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { projectId } = useParams<ProjectIdType>();
  const [isOpenWarningModal, setIsOpenWarningModal] = useState(false);
  const [isOpenModalPackageSummary, setIsOpenModalPackageSummary] = useState(false);
  const [selectedPackageData] = useState<CurrentSelectedPackage | null>(null);
  const [upgradePlanError, setUpgradePlanError] = useState<BillingError | null>(null);
  const { isLoading, isSelectedBasicPackage } = usePlanData(projectId);
  const { handleChangePackages: onChangePackages, isRedirect, isLoadingChangePackage } = useChangePackage({
    projectId,
    isSelectedBasicPackage,
    onErrorCallback: setUpgradePlanError,
  });
  const { isUsingNotSupportedPackage, availablePackageSubscriptionTypeToUpgrade, isUsingCustomPackage } = useGetProject(
    projectId,
  );
  // const { data: mainCreditData } = useQuery<MainCreditQueryType, ProjectIdType>(MAIN_CREDIT, {
  //   variables: { projectId },
  // });
  // const { creditCard } = useCreditCard(projectId);
  const {
    // handleChangePackageSummary,
    changePackageSummaryData,
    loading: isLoadingPackageSummary,
  } = useChangePackageSummary({
    projectId,
    onErrorNoBilling: () => {
      setIsOpenWarningModal(true);
    },
    onCompleted: () => {
      setIsOpenModalPackageSummary(true);
    },
  });

  const handleChangePackages = async () => {
    const packageIds = selectedPackageData?.packages.map(({ id }: PlanCardPropsType) => id) || [];
    await onChangePackages(packageIds);

    Utilities.addGoogleTagEvent(Constants.gtm.GTM_EVENT.PURCHASE, {
      ecommerce: {
        currency: "THB",
        value: selectedPackageData?.totalPrice,
        items: [
          {
            item_name: selectedPackageData?.title,
            price: selectedPackageData?.totalPrice,
          },
        ],
      },
    });
  };

  const handleClosePackageSummaryModal = () => {
    setIsOpenModalPackageSummary(false);
    setUpgradePlanError(null);
  };

  const handleSelectPackage = (selectedPackage: CurrentSelectedPackage) => {
    Utilities.addGoogleTagEvent(Constants.gtm.GTM_EVENT.ADD_TO_CART, {
      ecommerce: {
        currency: "THB",
        value: selectedPackage.totalPrice,
        items: [
          {
            item_id: selectedPackage.id,
            item_name: selectedPackage.title,
            price: selectedPackage.totalPrice,
            item_category: selectedPackage.subscriptionType,
            index: selectedPackage.index,
          },
        ],
      },
    });

    if (selectedPackage.totalPrice < 0) {
      window.open("mailto:contact@leanbiz.ai?subject=Change packages");
      return;
    }
    // const hasPayment = (mainCreditData?.credit || 0) > selectedPackage.totalPrice || creditCard;

    // if (!hasPayment) {
    //   notifyError(BillingError["BILLING:REQUIRE_CREDIT_CARD_TO_UPGRADE_PLAN"]);

    //   return;
    // }

    history.push(
      `/project/${projectId}/plan-upgrade?package=${selectedPackage.title}&subscriptionType=${selectedPackage.subscriptionType}`,
    );
  };

  const isPermissionToMonthlySubscription = availablePackageSubscriptionTypeToUpgrade.includes(
    SubscriptionType.MONTHLY,
  );

  if (isLoading) {
    return (
      <Modal isOpen onClose={() => {}}>
        <CircularProgress className="m-4" />
      </Modal>
    );
  }

  if (isRedirect) {
    return <Redirect to={`/${PROJECT}/${projectId}/${PLAN}/${BILLING}`} />;
  }

  const RenderSubTitle = () => {
    const isDuringFreeTrialPeriod = changePackageSummaryData?.isDuringFreeTrialPeriod;
    const currentPackageSubscriptionType = changePackageSummaryData?.currentPackages[0].subscriptionType;
    const newPackageSubscriptionType = changePackageSummaryData?.newPackages[0].subscriptionType;
    if (isDuringFreeTrialPeriod) {
      return (
        <>
          <Typography variant="body3" color="darkLight" className="mt-2">
            {t("planUpgrade.freeTrial.on14DayFreeTrial")}
          </Typography>
          <Typography variant="body3" color="darkLight" className="mt-2">
            <br />
            {t("planUpgrade.packageSummary.Subtitle.upgradeOnFreeTrial")}
          </Typography>
        </>
      );
    }
    if (currentPackageSubscriptionType !== newPackageSubscriptionType) {
      return (
        <Typography variant="body3" color="darkLight" className="mt-2">
          {t("planUpgrade.packageSummary.Subtitle.changeSubscriptionType")}
        </Typography>
      );
    }
    return (
      <Typography variant="body3" color="darkLight" className="mt-2">
        {t("planUpgrade.packageSummary.Subtitle.sameSubscriptionType")}
      </Typography>
    );
  };

  return (
    <>
      <div className="w-100">
        <Pages.UpgradePlan
          projectId={projectId}
          onSelectPackage={(packages: any) => handleSelectPackage(packages)}
          isUsingNotSupportedPackage={isUsingNotSupportedPackage}
          isPermissionToMonthlySubscription={isPermissionToMonthlySubscription}
          isUsingCustomPackage={isUsingCustomPackage}
          isAllowAnnuallyPackage
        />
      </div>

      <BillingInfoWarningModal isOpen={isOpenWarningModal} onCancel={() => setIsOpenWarningModal(false)} />
      {isLoadingPackageSummary ? (
        <Modal isOpen onClose={() => {}}>
          <CircularProgress className="m-4" />
        </Modal>
      ) : (
        <Modal isOpen={isOpenModalPackageSummary} onClose={() => setIsOpenModalPackageSummary(false)}>
          <div className="p-3">
            <Grid item xs={12} className="text-center py-3">
              <Typography variant="title4" color="dark" className="font-weight-bold">
                {t("planUpgrade.header")}
              </Typography>
              <PackageSummaryModalSubTitle>
                <RenderSubTitle />
              </PackageSummaryModalSubTitle>
            </Grid>
            <ConfirmModalDetail
              changePackageSummary={changePackageSummaryData}
              type={selectedPackageData?.title || PackagePricingType.PROFESSIONAL}
              selectedPackage={selectedPackageData ? selectedPackageData.packages : []}
              totalPrice={selectedPackageData?.totalPrice || 0}
              onCancel={handleClosePackageSummaryModal}
              onConfirm={handleChangePackages}
              error={upgradePlanError}
              isLoadingChangePackage={isLoadingChangePackage}
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default PlanUpgradeContainer;

import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useLocation } from "react-router-dom";
import { useQuery } from "@apollo/client";
import get from "lodash/get";
import { Components } from "@leanbizai/web-console";

import { BILLING } from "graphql/billing/query";
import { DEEPLE_PAY_RATE, DEEPLE_PAY_WALLET, KYC_VERIFICATION } from "graphql/deeplePay/query";

import { BillingType } from "types/Billing";
import { ProjectIdType } from "types/Project";
import { DeeplePayFeeRate, KYCStatus, KYCVerification } from "types/DeeplePay";
import { Device } from "types/Device";

import Typography from "components/Typography";
import LoadingModal from "components/Modal/Loading";

import useDevice from "utils/hooks/useDevice";
import { getParameterByName } from "utils/common";

import useGetProject from "utils/hooks/useGetProject";
import TransactionTable from "../TransactionTable";
import Grid from "../../../components/Grid/index";
import KYCPopup from "./KYCPopup";
import TotalBalance from "./TotalBalance";
import BankAccountInfo from "./BankAccountInfo";
import { TransactionWrapper } from "./styled";

import WithdrawalModal from "./WithdrawalModal";

const DeeplePay: FC = () => {
  const { t } = useTranslation();
  const { search = "" } = useLocation();
  const isShowKYCFromQueryString: string = getParameterByName("kyc", search);
  const device = useDevice();
  const isMobile = device === Device.MOBILE;
  const { projectId } = useParams<ProjectIdType>();
  const [isShowKYCPopup, setIsShowKYCPopup] = useState(isShowKYCFromQueryString === "true");
  const [isOpenWithdrawalModal, setIsOpenWithdrawalModal] = useState(false);
  const { isUsingDeeplePay } = useGetProject(projectId);
  const isShowDeeplePayContactSupport = !isUsingDeeplePay;

  const { loading, data, refetch } = useQuery(BILLING, {
    skip: !projectId,
    variables: {
      projectId,
    },
  });
  const { loading: kycLoading, data: kycData, refetch: refetchKYCData } = useQuery(KYC_VERIFICATION, {
    skip: !projectId,
    variables: {
      projectId,
    },
  });
  const { data: walletData, loading: deeplePayWalletLoading, refetch: refetchDeeplePayWallet } = useQuery(
    DEEPLE_PAY_WALLET,
    {
      skip: !projectId,
      variables: { projectId },
    },
  );
  const { data: feeData, loading: feeLoading } = useQuery(DEEPLE_PAY_RATE, {
    skip: !projectId,
    variables: { projectId },
  });

  const billing: BillingType = data?.billing;
  const kyc: KYCVerification = kycData?.kycVerification;
  const wallet = walletData?.deeplePayWallet;
  const fee: DeeplePayFeeRate = feeData?.deeplePayFeeRate;
  const isVerifiedKYC = data ? get(kycData, "kycVerification.status") === KYCStatus.VERIFIED : false;
  const totalBalance = wallet?.balance || 0;

  const requestWithdraw = () => {
    setIsOpenWithdrawalModal(true);
  };

  const handleCloseWithdrawalModal = () => {
    setIsOpenWithdrawalModal(false);
  };

  const handleOpenKYCPopup = () => {
    setIsShowKYCPopup(true);
  };

  const handleCloseKYCPopup = () => {
    setIsShowKYCPopup(false);
    refetchKYCData();
  };

  if (loading || kycLoading || deeplePayWalletLoading || feeLoading) {
    return <LoadingModal isOpen />;
  }

  return (
    <div className="position-relative w-100">
      <TransactionWrapper container justify="flex-start" className={isMobile ? "" : "p-5"}>
        <Grid container justify="flex-start">
          <Grid item xs={12} className={isMobile ? "pl-3 py-4" : "mb-4"}>
            <Typography variant="title2">{t("deeplePay.header.transaction")}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <TotalBalance
            disabled={!isVerifiedKYC}
            isMobile={isMobile}
            total={totalBalance}
            handleWithdraw={requestWithdraw}
          />
        </Grid>
        <Grid item xs={12} md={8} className="mt-3 mt-lg-0 pl-lg-4">
          <BankAccountInfo isMobile={isMobile} handleVerify={handleOpenKYCPopup} kycData={kyc} />
        </Grid>
        <Grid container justify="center" className="p-0 m-0 pt-3">
          <Grid item xs={12}>
            <TransactionTable />
          </Grid>
        </Grid>
        {isShowDeeplePayContactSupport && <Components.CardDeeplePayContactSupport isNewShop={false} />}
      </TransactionWrapper>

      {/* KYC popup */}
      <KYCPopup
        billing={billing}
        refetchBilling={refetch}
        isOpen={isShowKYCPopup}
        onClose={handleCloseKYCPopup}
        projectId={projectId}
      />

      {/* Withdrawal Modal */}
      <WithdrawalModal
        isOpen={isOpenWithdrawalModal}
        onClose={handleCloseWithdrawalModal}
        balance={totalBalance}
        feeRate={fee?.withdrawal?.fix || 0}
        refetchDeeplePayWallet={refetchDeeplePayWallet}
        bankInfo={{
          bank: kyc?.bank,
          bankAccountName: kyc?.bankAccountName,
          bankAccountNo: kyc?.bankAccountNo,
        }}
      />
    </div>
  );
};

export default DeeplePay;

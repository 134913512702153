import { useQuery } from "@apollo/client";
import Box from "@material-ui/core/Box";
import React, { FC, useState, useMemo, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import debounce from "lodash/debounce";
import get from "lodash/get";
import { Components } from "@leanbizai/web-console";

import Button, { FloatingButton } from "components/Button";
import Card from "components/Card";
import CircularProgress from "components/CircularProgress";
import Grid from "components/Grid";
import Search from "components/Search";
import Typography from "components/Typography";
import ConfirmationModal from "components/ConfirmationModal";
import StickyPanel, { StickyButtonContainer } from "components/StickyPanel";
import ScrollToTop from "components/ScrollToTop";
import { SvgIcon } from "components/Icon";
import { IcArrowToTop } from "components/SvgIcons";

import COLORS from "constants/Colors";
import { DEFAULT_SCROLL } from "constants/Scroll";
import useScrollToTopElement from "utils/hooks/useScrollToTopElement";
import { NOTIFICATION_CREATE, NOTIFICATION_UPDATE, NOTIFICATION_DELETE } from "constants/Notification";
import { FAQS } from "graphql/ai-setting/query";
import { GUIDELINE } from "graphql/guideline/query";
import { ProjectIdType } from "types/Project";
import { FaqQueryType, FaqType } from "types/AISetting";
import { Device } from "types/Device";
import { MessageType } from "types/Chat";
import { GuidelineQueryType } from "types/Guideline";

import useToggle from "utils/hooks/useToggle";
import i18n from "utils/i18n";
import nanoid from "utils/nanoid";
import { searchFaq } from "utils/faq";
import { notifyError, notifySuccess } from "utils/notify";
import scrollToElementId from "utils/common/scrollToElementId";
import useDevice from "utils/hooks/useDevice";
import { GuidelineContext } from "utils/context";

import PageTitle from "components/PageTitle";
import { addGoogleTagEvent } from "services/GoogleTagEvent";
import { GTM_EVENT } from "constants/GTM";
import useRemoveFaq from "./hooks/useRemoveFaq";
import useUpdateFaq from "./hooks/useUpdateFaq";
import useCreateFaq from "./hooks/useCreateFaq";
import useQueryFaqJobOperation from "./hooks/useQueryFaqJobOperation";
import useCheckDuplicatedQuestionFromAll from "./hooks/useCheckDuplicatedQuestionFromAll";
import useTrainFaq from "./hooks/useTrainFaq";
import FaqCard from "./FaqCard";
import { updateQuickReplyMessageToLastItem } from "./util";

type NewFaqContainerPropsType = {
  projectId: string;
  isEnableEN: boolean;
};

const NewFaqContainer: FC<NewFaqContainerPropsType> = ({ projectId, isEnableEN }) => {
  const { t } = useTranslation();
  const device = useDevice();
  const isDesktop = device === Device.DESKTOP;
  const [newFaq, setNewFaq] = useState<FaqType>();
  const [wordSearch, setWordSearch] = useState<string>("");
  const [selectedFaq, setSelectedFaq] = useState<string>("");
  const [isShowGuideline, setIsShowGuideline] = useState(false);
  const { isOpen: isOpenConfirmModal, handleToggle, handleClose: closeConfirmModal } = useToggle();
  const { data, loading } = useQuery<FaqQueryType, ProjectIdType>(FAQS, { variables: { projectId }, skip: !projectId });
  const faqs = get(data, "faqs") || [];
  const { handleFetchGuideline } = useContext(GuidelineContext);
  const { data: guidelineData } = useQuery<GuidelineQueryType>(GUIDELINE, {
    variables: {
      projectId,
    },
  });
  const isCompleteAISetting = guidelineData?.guideline.hasFaqs;
  const removeFaq = useRemoveFaq(projectId);
  const { updateFaq, isLoadingUpdateFaq } = useUpdateFaq(projectId);
  const { createFaq, isLoadingCreateFaq } = useCreateFaq(projectId);

  const trainFaq = useTrainFaq(projectId);
  const checkDuplicatedQuestionFromAll = useCheckDuplicatedQuestionFromAll(faqs);
  const { isJobProcessing, timestamp } = useQueryFaqJobOperation(projectId);
  const { scrollTopId, handleScrollToTop, isScroll } = useScrollToTopElement(DEFAULT_SCROLL.FAQ_SCROLL_TOP, isDesktop);

  useEffect(() => {
    if (isCompleteAISetting === false) {
      setIsShowGuideline(true);
    }
  }, [isCompleteAISetting]);

  const handleSubmitCreateFaq = async (_faqId: string, faqData: FaqType, callback?: () => void) => {
    try {
      const createdFaq = await createFaq(faqData);
      const createdFaqId = get(createdFaq, "data.createFaq.id");

      setTimeout(() => {
        scrollToElementId(createdFaqId);
      }, 200);
      setNewFaq(undefined);
      notifySuccess(t(NOTIFICATION_CREATE.SUCCESS));
      trainFaq();
      addGoogleTagEvent(GTM_EVENT.ADD_KNOWLEDGE);
      if (callback) {
        callback();
      }
    } catch (error) {
      notifyError(t(NOTIFICATION_CREATE.FAIL));
    }
  };

  const handleSubmitUpdateFaq = async (faqId: string, faqData: FaqType, callback?: () => void) => {
    try {
      const newFaqData = {
        id: faqData.id,
        isActive: faqData.isActive,
        questions: faqData.questions,
        enQuestions: faqData.enQuestions,
        responses: updateQuickReplyMessageToLastItem(faqData.responses),
        enResponses: updateQuickReplyMessageToLastItem(faqData.enResponses),
      };

      await updateFaq(faqId, newFaqData);
      notifySuccess(t(NOTIFICATION_UPDATE.SUCCESS));
      trainFaq();

      if (callback) {
        callback();
      }
    } catch (error) {
      notifyError(t(NOTIFICATION_UPDATE.FAIL));
    }
  };

  const handleToggleFaq = async (faqId: string, isActive: boolean) => {
    try {
      await updateFaq(faqId, {
        id: faqId,
        isActive,
      });
      notifySuccess(t(NOTIFICATION_UPDATE.SUCCESS));
    } catch (error) {
      notifyError(t(NOTIFICATION_UPDATE.FAIL));
    }
  };

  const handleToggleNewFaq = (_faqId: string, isActive: boolean) => {
    if (newFaq) {
      setNewFaq({
        ...newFaq,
        isActive,
      });
    }
  };

  const handleToggleFaqNotification = async (faqId: string, isEnabledNotification: boolean) => {
    try {
      await updateFaq(faqId, {
        id: faqId,
        isEnabledNotification,
      });
      notifySuccess(t(NOTIFICATION_UPDATE.SUCCESS));
    } catch (error) {
      notifyError(t(NOTIFICATION_UPDATE.FAIL));
    }
  };

  const handleToggleNewFaqNotification = (_faqId: string, isEnabledNotification: boolean) => {
    if (newFaq) {
      setNewFaq({
        ...newFaq,
        isEnabledNotification,
      });
    }
  };

  const handleClickAddFAQ = () => {
    if (newFaq) {
      return;
    }

    const newId = nanoid();

    const addedFaq = {
      id: newId,
      questions: [""],
      responses: [
        {
          type: MessageType.QUICK_REPLIES,
          value: [{ text: "ติดต่อแอดมิน", label: "ติดต่อแอดมิน" }],
        },
      ],
      enQuestions: [""],
      enResponses: [
        {
          type: MessageType.QUICK_REPLIES,
          value: [{ text: "Contact admin", label: "Contact admin" }],
        },
      ],
      isActive: true,
      isEnabledNotification: true,
    };

    setNewFaq(addedFaq);

    setTimeout(() => {
      scrollToElementId(newId);
    }, 200);
  };

  const removeNewFaq = () => {
    setNewFaq(undefined);
    closeConfirmModal();
  };

  const handleChangeSearch = debounce((searchInput: string) => {
    setWordSearch(searchInput);
  }, 500);

  const handleClickDeleteFaq = (faqId: string) => {
    setSelectedFaq(faqId);
    handleToggle();
  };

  const handleClickDeleteNewFaq = (_faqId: string) => {
    handleToggle();
  };

  const handleSubmitDelete = async () => {
    try {
      await removeFaq(selectedFaq);
      notifySuccess(t(NOTIFICATION_DELETE.SUCCESS));
      handleFetchGuideline();
    } catch (error) {
      notifyError(t(NOTIFICATION_DELETE.FAIL));
    }

    setSelectedFaq("");
    closeConfirmModal();
  };

  const searchedFaqs = useMemo(() => searchFaq(faqs, wordSearch), [faqs, wordSearch]);
  const hasNoFaq = Boolean(!faqs.length && !newFaq);
  const hasNoSearchedFaq = Boolean(!searchedFaqs.length && wordSearch.length && !newFaq);

  return (
    <>
      <Grid container className="py-4 px-3" justify={isDesktop ? undefined : "flex-end"}>
        <Grid item container xs={12} id={scrollTopId}>
          <Grid item xs={6}>
            <PageTitle className="pl-0" title={t("FAQ")} />
          </Grid>
          <Grid item xs={6} container justify="flex-end" alignItems="center" alignContent="center" className="px-3">
            <Button color="primary" size="medium" onClick={handleClickAddFAQ} data-cy="addButton" className="px-3">
              + {isDesktop ? t("faq.button.addNewFaq") : t("Add")}
            </Button>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body2" className="mb-4">
            {t(
              "Add specialized knowledges to train your bot to have store-customized and smarter responses when chatting with your customers",
            )}
          </Typography>
        </Grid>

        {isJobProcessing && (
          <Grid item xs={12}>
            <Card
              noShadow
              bgColor={COLORS.bgTrainingCard}
              borderColor={COLORS.borderTrainingCard}
              className="d-flex mb-3"
            >
              <CircularProgress size={16} className="mr-2" />
              <Box display="flex" flexDirection={{ xs: "column", sm: "row" }}>
                <Typography variant="title8" className="mr-2">
                  {t("Training")}.
                </Typography>
                <Typography variant="body3" color="darkMed">
                  {t("Training bots to update bots with all the latest changes")}
                </Typography>
              </Box>
            </Card>
          </Grid>
        )}

        <Grid item xs={12} className={`pt-1 ${timestamp ? "visible" : "invisible"}`}>
          <Typography variant="title8" color="primary">
            {t("Recently trained on")} {timestamp}
          </Typography>
        </Grid>
        <Grid item xs={12} className="py-4">
          <Search
            defaultValue=""
            onChange={handleChangeSearch}
            className="mr-3"
            placeholder={t("searchPlaceholder.aiSpecialedKnowledge")}
          />
        </Grid>
        <Grid item xs={12}>
          {newFaq && (
            <FaqCard
              checkDuplicatedQuestionFromAll={checkDuplicatedQuestionFromAll}
              faq={newFaq}
              isEnableEN={isEnableEN}
              isLoadingSubmit={isLoadingCreateFaq || isLoadingUpdateFaq}
              isNewFaq
              key={newFaq.id}
              onClickDelete={handleClickDeleteNewFaq}
              onSubmit={handleSubmitCreateFaq}
              onToggleSwitch={handleToggleNewFaq}
              onToggleNotification={handleToggleNewFaqNotification}
              projectId={projectId}
            />
          )}
        </Grid>
        {loading && (
          <div className="d-flex align-items-center flex-column mx-auto" style={{ width: 150, marginTop: 100 }}>
            <CircularProgress size={50} />
            <Typography variant="title4" color="gray" className="text-center mt-3">
              {t("LOADING")}. . .
            </Typography>
          </div>
        )}
        <Grid item xs={12}>
          {!loading && hasNoFaq && !hasNoSearchedFaq && (
            <Typography variant="title4" color="gray" className="text-center">
              {t("No FAQ")}
            </Typography>
          )}
          {!loading && hasNoSearchedFaq && (
            <Typography variant="title4" color="gray" className="text-center">
              {t("Search no found")}
            </Typography>
          )}
          {!loading &&
            searchedFaqs.map((faq) => (
              <FaqCard
                checkDuplicatedQuestionFromAll={checkDuplicatedQuestionFromAll}
                faq={faq}
                isEnableEN={isEnableEN}
                isLoadingSubmit={isLoadingCreateFaq || isLoadingUpdateFaq}
                key={faq.id}
                onClickDelete={handleClickDeleteFaq}
                onSubmit={handleSubmitUpdateFaq}
                onToggleSwitch={handleToggleFaq}
                onToggleNotification={handleToggleFaqNotification}
                projectId={projectId}
              />
            ))}
        </Grid>
      </Grid>
      <ConfirmationModal
        title={t("Are you sure you want to remove ?")}
        isOpen={isOpenConfirmModal}
        onClose={closeConfirmModal}
        onSubmit={selectedFaq ? handleSubmitDelete : removeNewFaq}
      />

      <StickyPanel>
        <StickyButtonContainer className="px-2 py-3">
          {!isDesktop && isScroll && (
            <Grid item>
              <FloatingButton onClick={handleScrollToTop} data-cy="addButton">
                <SvgIcon
                  className="mb-1 mt-3 ml-3"
                  component={IcArrowToTop}
                  fontSize="default"
                  htmlColor={COLORS.DarkMed}
                />
              </FloatingButton>
            </Grid>
          )}
          <Grid item>
            <ScrollToTop buttonColor="darkGray" />
          </Grid>
        </StickyButtonContainer>
      </StickyPanel>

      <div className="py-5" />
      <Components.AIGuidelineModal
        isOpen={isShowGuideline}
        lang={i18n.language}
        onClose={() => setIsShowGuideline(false)}
      />
    </>
  );
};

export default NewFaqContainer;

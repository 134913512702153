import React, { FC, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import get from "lodash/get";

import Button from "components/Button";
import CircularProgress from "components/CircularProgress";
import ColorPicker from "components/ColorPicker";
import Grid from "components/Grid";
import PageTitle from "components/PageTitle";
import Typography from "components/Typography";
import RichMenuContainer from "domain/RichMenu/index";

import COLORS from "constants/Colors";
import { RICH_MENU_LIMIT } from "constants/Richmenu";
import { PROJECT_SETTING } from "graphql/setting/query";
import { ProjectIdType } from "types/Project";
import { ProjectSettingQueryType } from "types/Setting";
import { PLATFORM } from "types/RichMenu";

import useUpdateSetting from "./hooks/useUpdateSetting";
import { ConfigWrapper } from "./styled";

const ThemeShopConfigContainer: FC = () => {
  const { t } = useTranslation();

  const { projectId } = useParams<ProjectIdType>();

  const [primaryColor, setPrimaryColor] = useState(COLORS.Primary);
  const [secondaryColor, setSecondaryColor] = useState(COLORS.White);
  const [isShowSaveButton, setIsShowSaveButton] = useState(false);

  const { loading, data } = useQuery<ProjectSettingQueryType>(PROJECT_SETTING, {
    variables: {
      projectId,
    },
  });

  const configurationData = get(data, "getProject.configuration");
  const themeColorConfig = get(configurationData, "theme.color");

  const { handleSave } = useUpdateSetting({
    projectId,
    configurationData,
    primaryColor,
    secondaryColor,
    onUpdateComplete: () => setIsShowSaveButton(false),
  });

  const handleSetShowSaveButton = () => {
    if (!isShowSaveButton) setIsShowSaveButton(true);
  };

  const handleChangePrimaryColor = (selectedColor: string) => {
    setPrimaryColor(selectedColor);
    handleSetShowSaveButton();
  };

  const handleChangeSecondaryColor = (selectedColor: string) => {
    setSecondaryColor(selectedColor);
    handleSetShowSaveButton();
  };

  useEffect(() => {
    if (themeColorConfig) {
      setPrimaryColor(themeColorConfig.primary);
      setSecondaryColor(themeColorConfig.secondary);
    }
  }, [themeColorConfig]);

  return loading ? (
    <Grid container item justify="center">
      <CircularProgress />
    </Grid>
  ) : (
    <Grid container className={isShowSaveButton ? "pb-5" : ""}>
      <Grid container item>
        <PageTitle title={t("ThemeShopConfig.title")} />
      </Grid>

      <ConfigWrapper container item className="flex-nowrap">
        <Grid container item alignItems="center" className="flex-grow-1">
          <Grid item xs={4} sm={2} lg={1} className="mr-3">
            <Typography variant="body3">{t("ThemeShopConfig.Primary.label")}</Typography>
          </Grid>
          <Grid item>
            <ColorPicker color={primaryColor} onChange={handleChangePrimaryColor} />
          </Grid>
        </Grid>
        {/* ----- We don't use secondary color for now ----- */}
        <Grid container item alignItems="center" className="mt-3 d-none">
          <Grid item xs={4} sm={2} lg={1} className="mr-3">
            <Typography variant="body3">{t("ThemeShopConfig.Secondary.label")}</Typography>
          </Grid>
          <Grid item>
            <ColorPicker color={secondaryColor} onChange={handleChangeSecondaryColor} />
          </Grid>
        </Grid>
        <Grid item xs={1} className="flex-grow-0">
          {isShowSaveButton && (
            <Button fullWidth onClick={handleSave}>
              {t("Save")}
            </Button>
          )}
        </Grid>
      </ConfigWrapper>

      <ConfigWrapper container item>
        <RichMenuContainer title="RichMenu.title.line" limit={RICH_MENU_LIMIT} type={PLATFORM.LINE} />
      </ConfigWrapper>

      <ConfigWrapper container item>
        <RichMenuContainer title="RichMenu.title.facebook" limit={RICH_MENU_LIMIT} type={PLATFORM.FACEBOOK} />

        <Typography variant="body3" className="mt-4">
          <b>{t("NOTE")}</b> : {t("FACEBOOK_REMARK")}
        </Typography>
      </ConfigWrapper>

      {/* LEANBIZ_TEMP_DISABLE hide ig richmenu */}
      {/* <ConfigWrapper container item>
        <RichMenuContainer title="RichMenu.title.instagram" limit={RICH_MENU_LIMIT} type={PLATFORM.INSTAGRAM} />
      </ConfigWrapper> */}
    </Grid>
  );
};

export default ThemeShopConfigContainer;

import React, { FC, useState, useRef, useEffect } from "react";
import { ApolloError, useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { useController, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import get from "lodash/get";
import { OptionType, OptionsType, ValueType } from "react-select";

import Button from "components/Button";
import CircularProgress from "components/CircularProgress";
import ConfirmationModal from "components/ConfirmationModal";
import ErrorText from "components/ErrorText";
import Grid from "components/Grid";
import TextField from "components/TextField";
import Typography from "components/Typography";

import ImageUpload from "components/ImageUpload";
import { ADD_BILLING, UPDATE_BILLING } from "graphql/billing/mutation";
import { BILLING } from "graphql/billing/query";
import { BillingInformationType, BillingType, TitleName } from "types/Billing";
import { UploadDirectoryType, ImageUrl } from "types/Image";
import { ProjectIdType } from "types/Project";
import useToggle from "utils/hooks/useToggle";

import { NewControllerSelect } from "components/Select/index";
import AutoCompleteAddressForm from "components/AutoCompleteAddress";
import Modal from "components/Modal";
import { SvgIcon } from "components/Icon";
import { IcWarning } from "components/SvgIcons";
import COLORS from "constants/Colors";
import { DUPLICATE_KEY_ERROR_REGEX } from "constants/RegExp";
import { Utilities, Constants } from "@leanbizai/web-console";
import { CompanyBillingInfoSchema, PersonalBillingInfoSchema } from "./validateSchema";
import { FieldWrapper, InformationTypeButton } from "./styled";
import { TitleOptions, ProvinceOptions } from "../../../../../constants/information";
import { ModalBody, ModalContainer } from "../styled";

type BillingFormPropsType = {
  billing: BillingType;
  setIsEdit: Function;
  showCertificate?: boolean;
};

type BillingValidation = BillingType & {
  image?: string;
  titleName?: string;
};

type BillingFormDataType = Omit<BillingValidation, "id">;

const BillingForm: FC<BillingFormPropsType> = (props) => {
  const { billing, setIsEdit, showCertificate = true } = props;
  const { projectId } = useParams<ProjectIdType>();
  const imagePath = useRef<string>();
  const { t } = useTranslation();

  const initialImage = get(billing, "documentVerificationCertificatePath") || "";
  const isVerified = Boolean(get(billing, "isVerified"));

  const titleOptions = TitleOptions.map(({ label, ...props }) => ({ label: t(label), ...props }));
  const titleDefaultValue = titleOptions.find(({ value }) => value === TitleName.MR);

  const [type, setType] = useState(billing?.type || BillingInformationType.PERSONAL);
  const [title, setTitle] = useState(get(billing, "titleName") || titleDefaultValue);
  const [image, setImage] = useState<string>(initialImage);
  const [isOpenErrorModal, setIsOpenErrorModal] = useState<boolean>(false);
  const [isOpenLoadingModal, setIsOpenLoadingModal] = useState<boolean>(false);
  const [isDuplicateError, setIsDuplicateError] = useState<boolean>(false);
  const {
    isOpen: isOpenRemoveCertModal,
    handleToggle: handleToggleRemoveCertModal,
    handleClose: handleCloseRemoveCertModal,
  } = useToggle();
  const isCompanyType = type === BillingInformationType.COMPANY;
  const isPersonalType = type === BillingInformationType.PERSONAL;
  const { register, control, errors, handleSubmit, setValue, watch } = useForm<BillingFormDataType>({
    resolver: yupResolver(isCompanyType ? CompanyBillingInfoSchema : PersonalBillingInfoSchema),
  });
  const titleValue = titleOptions.find(({ value }) => value === title);
  const watchProvince = watch("province");
  const getProvinceSelectOption = (value: string) => {
    const option = ProvinceOptions.find((option) => option.value === value) as ValueType<OptionType>;
    return option;
  };
  const handleChangeProvince = (option: any) => {
    setValue("province", option?.value);
  };
  // tracking validation to image field
  const { field } = useController({
    name: "image",
    control,
  });

  const handleCloseForm = () => {
    setIsEdit(false);
  };

  const handleMutationError = (error: ApolloError) => {
    const message = get(error, "message");
    const isDuplicateError = DUPLICATE_KEY_ERROR_REGEX.test(message);

    setIsOpenLoadingModal(false);
    setIsDuplicateError(isDuplicateError);
    setIsOpenErrorModal(true);
  };

  const mutation = billing && billing.id ? UPDATE_BILLING : ADD_BILLING;

  const [updateBilling, { loading }] = useMutation(mutation, {
    onCompleted: handleCloseForm,
    onError: handleMutationError,
    refetchQueries: [
      {
        query: BILLING,
        variables: {
          projectId,
        },
      },
    ],
  });

  const onClickSubmit = (formData: BillingFormDataType) => {
    let imageUrl: { documentVerificationCertificatePath?: string | null } = {
      documentVerificationCertificatePath: imagePath?.current || undefined,
    };

    if (!image) {
      imageUrl = {
        documentVerificationCertificatePath: billing?.documentVerificationCertificatePath ? null : undefined,
      };
    }

    const {
      province,
      district,
      subDistrict,
      name,
      address,
      email,
      taxId,
      phoneNumber,
      contactPerson,
      postalCode,
    } = formData;
    const fullName = name.trim();

    const input = {
      type,
      name: fullName,
      address,
      email,
      taxId,
      phoneNumber,
      contactPerson,
      postalCode,
      province,
      district,
      subDistrict,
      ...imageUrl,
    };

    const billingInput = {
      titleName: isPersonalType ? get(title, "value") : undefined,
      ...input,
    };

    updateBilling({
      variables: {
        projectId,
        billingId: billing ? billing.id : undefined,
        billingInput,
      },
    }).then(() => {
      Utilities.addGoogleTagEvent(Constants.gtm.GTM_EVENT.ADD_BILLING_INFO);
    });
  };

  const handleChangeImage = (imageUrls: ImageUrl[]) => {
    if (imageUrls.length) {
      const [{ publicUrl, filePath }] = imageUrls;
      setImage(publicUrl);
      field.onChange(publicUrl);
      imagePath.current = filePath;
    }
  };

  const handleSubmitRemoveCertificate = () => {
    setImage("");
    field.onChange(undefined);
    handleCloseRemoveCertModal();
  };

  const handleRemoveImage = () => {
    if (isVerified) {
      handleToggleRemoveCertModal();
      return;
    }

    setImage("");
    field.onChange(undefined);
  };

  // update hook from image field when image change
  useEffect(() => {
    if (image) {
      field.onChange(image);
    }
  }, [field, image]);

  useEffect(() => {
    if (loading) {
      setIsOpenLoadingModal(true);
    }
  }, [loading]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <InformationTypeButton
            onClick={() => setType(BillingInformationType.PERSONAL)}
            className={isPersonalType ? "selected" : ""}
          >
            {t("billing.info.form.personal.button.label")}
          </InformationTypeButton>
          <InformationTypeButton
            onClick={() => setType(BillingInformationType.COMPANY)}
            className={isCompanyType ? "selected" : ""}
          >
            {t("billing.info.form.company.button.label")}
          </InformationTypeButton>
        </Grid>
        {showCertificate && (
          <Grid item xs={12}>
            <label htmlFor="certificate">
              <Typography variant="body3" color="darkGray">
                {isCompanyType ? t("planBilling.label.upload") : t("billing.info.form.personal.IDCard")}
              </Typography>
            </label>
            <ImageUpload
              height={140}
              onChange={handleChangeImage}
              onRemove={handleRemoveImage}
              name="image"
              validate={register}
              image={image}
              directoryType={UploadDirectoryType.PROJECT_CERTIFICATION}
            />
            <Typography variant="body4" className="pt-1">
              <ErrorText className="pt-1">{errors.image && t(errors.image.message as string)}</ErrorText>
            </Typography>
          </Grid>
        )}

        <Grid item xs={12} className="mb-1">
          <Typography variant="body4" color="darkMed" className="pb-1">
            {isCompanyType ? t("planBilling.info.form.label.companyName") : t("billing.info.form.personal.name")}
          </Typography>
          <Grid container justify="space-between">
            {isPersonalType && (
              <>
                <Grid item xs={3}>
                  <NewControllerSelect
                    control={control}
                    defaultValue={(titleValue || titleDefaultValue) as OptionType}
                    value={titleValue as OptionType}
                    error={Boolean(errors.titleName)}
                    name="titleName"
                    validate={register}
                    options={titleOptions}
                    fullWidth
                    onChange={setTitle}
                    placeholder=""
                  />
                  <Typography variant="body4" className="pt-1">
                    <ErrorText className="pt-1">{errors.titleName && t(errors.titleName.message as string)}</ErrorText>
                  </Typography>
                </Grid>
                <Grid item xs={1} />
              </>
            )}
            <Grid item xs={isPersonalType ? 8 : 12}>
              <TextField
                defaultValue={get(billing, "name") || ""}
                error={Boolean(errors.name)}
                fullWidth
                name="name"
                validate={register}
                variant="outlined"
              />
              <Typography variant="body4" className="pt-1">
                <ErrorText className="pt-1">
                  {errors.name &&
                    (isCompanyType
                      ? t("planBilling.info.error.companyName.required")
                      : t(errors.name.message as string))}
                </ErrorText>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className="mb-1">
          <Typography variant="body4" color="darkMed" className="pb-1">
            {isCompanyType ? t("planBilling.info.form.label.address") : t("billing.info.form.personal.address")}
          </Typography>
          <TextField
            defaultValue={get(billing, "address") || ""}
            error={Boolean(errors.address)}
            fullWidth
            name="address"
            validate={register}
            variant="outlined"
          />
          <Typography variant="body4" className="pt-1">
            <ErrorText className="pt-1">
              {errors.address &&
                (isCompanyType
                  ? t("planBilling.info.error.companyAddress.required")
                  : t(errors.address.message as string))}
            </ErrorText>
          </Typography>
        </Grid>
        <Grid item xs={12} className="mb-1">
          <Typography variant="body4" color="darkMed" className="pb-1">
            {t("billing.info.form.postalCode")}
          </Typography>
          <AutoCompleteAddressForm
            isDefaultInput
            type="number"
            name="postalCode"
            defaultValue={get(billing, "postalCode") || ""}
            error={Boolean(errors.postalCode)}
            validate={register}
            setAddressData={setValue}
          />
          <Typography variant="body4" className="pt-1">
            <ErrorText className="pt-1">{errors.postalCode && t(errors.postalCode.message as string)}</ErrorText>
          </Typography>
        </Grid>
        <Grid item xs={12} className="mb-1">
          <FieldWrapper container>
            <Grid item className="first" xs={6}>
              <Typography variant="body4" color="darkMed" className="pb-1">
                {t("billing.info.form.subDistrict")}
              </Typography>
              <TextField
                defaultValue={get(billing, "subDistrict") || ""}
                error={Boolean(errors.subDistrict)}
                fullWidth
                name="subDistrict"
                validate={register}
                multiline
                variant="outlined"
                rows={1}
              />
              <Typography variant="body4" className="pt-1">
                <ErrorText className="pt-1">{errors.subDistrict && t(errors.subDistrict.message as string)}</ErrorText>
              </Typography>
            </Grid>
            <Grid item className="second" xs={6}>
              <Typography variant="body4" color="darkMed" className="pb-1">
                {t("billing.info.form.district")}
              </Typography>
              <TextField
                defaultValue={get(billing, "district") || ""}
                error={Boolean(errors.district)}
                fullWidth
                name="district"
                validate={register}
                multiline
                variant="outlined"
                rows={1}
              />
              <Typography variant="body4" className="pt-1">
                <ErrorText className="pt-1">{errors.district && t(errors.district.message as string)}</ErrorText>
              </Typography>
            </Grid>
          </FieldWrapper>
        </Grid>
        <Grid item xs={12} className="mb-1">
          <Typography variant="body4" color="darkMed" className="pb-1">
            {t("billing.info.form.province")}
          </Typography>
          <NewControllerSelect
            control={control}
            defaultValue={getProvinceSelectOption(billing?.province)}
            value={getProvinceSelectOption(watchProvince)}
            error={Boolean(errors.province)}
            name="province"
            validate={register}
            options={ProvinceOptions as OptionsType<OptionType>}
            fullWidth
            onChange={handleChangeProvince}
            placeholder=""
          />
          <Typography variant="body4" className="pt-1">
            <ErrorText className="pt-1">{errors.province && t(errors.province.message as string)}</ErrorText>
          </Typography>
        </Grid>
        <Grid item xs={12} className="mb-1">
          <Typography variant="body4" color="darkMed" className="pb-1">
            {t("planBilling.info.form.label.taxId")}
          </Typography>
          <TextField
            defaultValue={get(billing, "taxId") || ""}
            error={Boolean(errors.taxId)}
            fullWidth
            name="taxId"
            validate={register}
            variant="outlined"
          />
          <Typography variant="body4" className="pt-1">
            <ErrorText className="pt-1">{errors.taxId && t(errors.taxId.message as string)}</ErrorText>
          </Typography>
        </Grid>
        <Grid item xs={12} className="mb-1">
          <Typography variant="body4" color="darkMed" className="pb-1">
            {t("planBilling.info.form.label.contactPerson")} - {t("optional")}
          </Typography>
          <TextField
            defaultValue={get(billing, "contactPerson") || ""}
            error={Boolean(errors.contactPerson)}
            fullWidth
            name="contactPerson"
            validate={register}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} className="mb-1">
          <Typography variant="body4" color="darkMed" className="pb-1">
            {t("planBilling.info.form.label.phoneNumber")}
          </Typography>
          <TextField
            defaultValue={get(billing, "phoneNumber") || ""}
            error={Boolean(errors.phoneNumber)}
            fullWidth
            name="phoneNumber"
            validate={register}
            variant="outlined"
          />
          <Typography variant="body4" className="pt-1">
            <ErrorText className="pt-1">{errors.phoneNumber && t(errors.phoneNumber.message as string)}</ErrorText>
          </Typography>
        </Grid>
        <Grid item xs={12} className="mb-1">
          <Typography variant="body4" color="darkMed" className="pb-1">
            {t("planBilling.info.form.label.email")}
          </Typography>
          <TextField
            defaultValue={get(billing, "email") || ""}
            error={Boolean(errors.email)}
            fullWidth
            name="email"
            validate={register}
            variant="outlined"
          />
          <Typography variant="body4" className="pt-1">
            <ErrorText className="pt-1">{errors.email && t(errors.email.message as string)}</ErrorText>
          </Typography>
        </Grid>
        <Grid item container xs={12} spacing={1}>
          <Grid item xs={6}>
            <Button color="ghost" size="small" fullWidth onClick={handleCloseForm}>
              {t("Cancel")}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button size="small" fullWidth onClick={handleSubmit(onClickSubmit)}>
              {t("planBilling.info.form.button.submit")}
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <ConfirmationModal
        title={t("Are you sure you want to remove ?")}
        subTitle={t("planBilling.confirmation.subTitle")}
        isOpen={isOpenRemoveCertModal}
        onClose={handleCloseRemoveCertModal}
        onSubmit={handleSubmitRemoveCertificate}
      />
      <Modal isOpen={isOpenLoadingModal} maxWidth="xs" onClose={() => setIsOpenErrorModal(false)}>
        <ModalContainer container className="p-4" alignItems="center" justify="center">
          <ModalBody item xs={12}>
            <CircularProgress size={20} />
          </ModalBody>
          <ModalBody item xs={12}>
            <Typography variant="body3" color={COLORS.Primary}>
              {t("planBilling.loading.title")}
            </Typography>
          </ModalBody>
        </ModalContainer>
      </Modal>
      <Modal
        closeIconColor={COLORS.Primary}
        isOpen={isOpenErrorModal}
        maxWidth="xs"
        onClose={() => setIsOpenErrorModal(false)}
      >
        <ModalContainer container className="p-4" alignItems="center" justify="center">
          <ModalBody item xs={12}>
            <SvgIcon component={IcWarning} />
          </ModalBody>
          <ModalBody item xs={12}>
            <Typography variant="body3" color={COLORS.Primary}>
              {t("planBilling.error.title")}
            </Typography>
          </ModalBody>
          {isDuplicateError && (
            <ModalBody item xs={12}>
              <Typography variant="body3" color={COLORS.DarkLight}>
                {t("planBilling.error.description.duplicateKey")}
              </Typography>
            </ModalBody>
          )}
        </ModalContainer>
      </Modal>
    </>
  );
};

export default BillingForm;

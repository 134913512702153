const information = {
  en: {
    "Address is a required field": "Address is a required field",
    "Address or detail": "Address or detail",
    "Closing time should be less than or equal midnight": "Closing time should be less than or equal midnight",
    "Image is a required field": "Image is a required field",
    "Location (Google maps link)": "Location link (Google map shorten url)",
    "Map location is a required field": "Map location is a required field",
    "Map location must be shorten link": "Map location must be shorten link",
    "Name is a required field": "Name is a required field",
    "Offline stores": "Branches",
    "Opening hours": "Opening hours",
    "Opening should be less than closing": "Opening should be less than closing",
    "Phone number should be 4, 9, 10 digits": "Phone number should be 4, 9, 10 digits",
    "Phone number": "Phone number",
    "Postcode should be number 5 digits": "Postcode should be number 5 digits",
    "Remove photo": "Remove photo",
    "Sub-district": "Sub-district",
    Address: "Address",
    City: "City",
    District: "District",
    Email: "Email",
    Facebook: "Facebook",
    Friday: "Friday",
    Information: "Information",
    Instagram: "Instagram",
    Monday: "Monday",
    optional: "optional",
    Postcode: "Postcode",
    Province: "Province",
    Saturday: "Saturday",
    Sunday: "Sunday",
    Thursday: "Thursday",
    Tuesday: "Tuesday",
    Twitter: "X",
    Upload: "Upload",
    Website: "Website",
    Wednesday: "Wednesday",
    "offlineStore.title": "Branches",
    "offlineStore.addBranch": "Add a new branch",
    "offlineStore.duplicated.branchName": "Duplicated branch name",
    "offlineStore.workingHours.close": "Close",
    "offlineStore.availableForPickUp.label": "Available for pick up",
    "offlineStore.unavailableForPickUp.label": "Unavailable for pick up",
    "offlineStore.availableForLalamove.label": "Available for Lalamove",
    "offlineStore.unavailableForLalamove.label": "Unavailable for Lalamove",
    "offlineStore.remove.confirm": "Are you sure you want to remove this branch?",
    "offlineStore.offlineStoreForm.delete": "Delete branch",
    "offlineStore.deleteBranch.warning": "The last branch can not be deleted",
    "offlineStore.latitude.isRequired": "Please fill latitude",
    "offlineStore.longitude.isRequired": "Please fill longitude",
    "offlineStore.newBranch": "New branch!",
    Latitude: "Latitude",
    Longitude: "Longitude",
    Image: "Image",
  },
  th: {
    "Address is a required field": "โปรดใส่ที่อยู่",
    "Address or detail": "ที่อยู่ หรือรายละเอียด",
    "Closing time should be less than or equal midnight": "เวลาปิดต้องน้อยกว่าเที่ยงคืนหรือเท่ากับ",
    "Image is a required field": "โปรดใส่รูปภาพ",
    "Location (Google maps link)": "ลิงค์ที่ตั้ง (Google map shorten url)",
    "Map location is a required field": "โปรดใส่ที่ตั้งแผนที่",
    "Map location must be shorten link": "ที่ตั้งแผนที่ต้องเป็น shorten link",
    "Name is a required field": "โปรดใส่ชื่อ",
    "Offline stores": "สาขา",
    "Opening hours": "เวลาเปิดทำการ",
    "Opening should be less than closing": "เวลาเปิดต้องน้อยกว่าเวลาปิด",
    "Phone number should be 4, 9, 10 digits": "หมายเลขโทรศัพท์ควรเป็นตัวเลข 4 หรือ 9 หรือ 10 หลัก",
    "Phone number": "หมายเลขโทรศัพท์",
    "Postcode should be number 5 digits": "รหัสไปรษณีย์ควรเป็นตัวเลข 5 หลัก",
    "Remove photo": "ลบรูปภาพ",
    "Sub-district": "แขวง/ตำบล",
    Address: "ที่อยู่",
    City: "จังหวัด",
    District: "เขต/อำเภอ",
    Email: "อีเมล",
    Facebook: "เฟซบุ๊ก",
    Friday: "ศุกร์",
    Information: "ข้อมูลร้านค้า",
    Instagram: "อินสตาแกรม",
    Monday: "จันทร์",
    optional: "ถ้ามี",
    Postcode: "รหัสไปรษณีย์",
    Province: "จังหวัด",
    Saturday: "เสาร์",
    Sunday: "อาทิตย์",
    Thursday: "พฤหัสบดี",
    Tuesday: "อังคาร",
    Twitter: "เอกซ์",
    Upload: "อัปโหลด",
    Website: "เว็บไซต์",
    Wednesday: "พุธ",
    "offlineStore.title": "สาขา",
    "offlineStore.addBranch": "เพิ่มสาขาใหม่",
    "offlineStore.duplicated.branchName": "ชื่อสาขาซ้ำ",
    "offlineStore.workingHours.close": "ปิดทำการ",
    "offlineStore.availableForPickUp.label": "สามารถรับสินค้าหน้าร้านได้",
    "offlineStore.unavailableForPickUp.label": "ไม่สามารถรับสินค้าหน้าร้านได้",
    "offlineStore.availableForLalamove.label": "สามารถใช้ Lalamove ได้",
    "offlineStore.unavailableForLalamove.label": "ไม่สามารถใช้ Lalamove ได้",
    "offlineStore.remove.confirm": "คุณยืนยันที่จะลบข้อมูลสาขานี้หรือไม่?",
    "offlineStore.offlineStoreForm.delete": "ลบสาขา",
    "offlineStore.deleteBranch.warning": "สาขาสุดท้ายไม่สามารถลบได้",
    "offlineStore.latitude.isRequired": "กรุณาใส่ละติจูด",
    "offlineStore.longitude.isRequired": "กรุณาใส่ลองจิจูด",
    "offlineStore.newBranch": "สาขาใหม่!",
    Latitude: "ละติจูด",
    Longitude: "ลองจิจูด",
    Image: "รูปภาพ",
  },
};

export default information;
